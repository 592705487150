import React, { useState } from 'react'
import './ListContentNav.css'
import SelectComponent from "../../Utility/SelectComponent/SelectComponent"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faGrip, faList } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import FilterHook from "../../../CustomHooks/FiltrationHooks/FilterHook"
import { useDispatch } from 'react-redux';
import { changeFilterSidebarActive } from '../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice';
const ListContentNav = ({changeActive,active}) => {
    const [t] = useTranslation()
    const[
      minSpace,
      maxSpace,
      rooms,
      bathrooms,
      type,
      searchWord,
      property_id,
      category_id,
      minPrice,
      maxPrice,
      onChangeMinSpace,
      onChangeMaxSpace,
      onChangeBathrooms,
      onChangeType,
      onChangeSearchWord,
      onChangeRooms,
      onChangePropertyId,
      onChangeCategoryId,
      onChangeMaxPrice,
      onChangeMinPrice,
      onSearch,
      onSearchSidebar,
      Reset,
      sort,
      onChangeSort,
      onChangeCode,
      code,
      onChangeMainCategory,
      gateId,
      onChangeGateId,
      onChangeFinishing,
      onChangeFurnished,
      onChangeDistrictId,
    ]= FilterHook()
    const sort_options = [
        { value: "low-to-heigh", label:t("listingPage.listContent.lowTo")},
        { value: "heigh-to-low", label:t("listingPage.listContent.highTo") },
      ];
      const [activeBtn,setActiveBtn] = useState("residential")
      const handleMainCategory = (e)=>{
        onChangeMainCategory(e)
        setActiveBtn(e.target.id)
      }
      const dispatch = useDispatch()
      const handleChangeFilterSidebarActive=()=>{
        dispatch(changeFilterSidebarActive())
      }
  return (
    <nav className="d-flex align-items-center justify-content-between flex-wrap">
    <ul className="nav nav-pills nav-pills-1 myTab d-flex align-items-center" role="tablist">
      <li className="list-inline-item mr-auto d-flex flex-nowrap align-items-center w-100">
        {/* <span className="title-text mx-2">{t("listingPage.listContent.sortBy")}</span> */}
        <div className="select-wrapper">
            <SelectComponent options={sort_options} placeholder={t("listingPage.listContent.basedProperties")} onChange={onChangeSort}/>
        </div>
      </li>
    </ul>
    <ul className="main-category-list d-flex align-items-center justify-content-between" role="tablist">
    <li className={activeBtn==="residential"?"transition text-capitalize active":"transition text-capitalize"} id='residential' onClick={(e)=>handleMainCategory(e)}>
      سكنى
      </li>
      <li className={activeBtn==="Administrative"?"transition text-capitalize active":"transition text-capitalize"} id='Administrative' onClick={(e)=>handleMainCategory(e)}>
        ادارى
      </li>
      <li className={activeBtn==="commercial"?"transition text-capitalize active":"transition text-capitalize"} id='commercial' onClick={(e)=>handleMainCategory(e)}>
      تجارى
      </li>
    </ul>
    <div className='show-filter d-block d-xl-none position-relative transition'>
    <ul className="nav-pills myTab m-0" role="tablist">
      <li
        className="nav-item transition"
        onClick={() => handleChangeFilterSidebarActive()}
      >
       <FontAwesomeIcon icon={faFilter}/>
      </li>
    </ul>
    </div>
    <div className='d-none d-sm-block'>
    <ul className="nav-pills myTab m-0" role="tablist">
      <li
        className={
          active === "vertical-cards"
            ? "nav-item transition active"
            : "nav-item transition"
        }
        onClick={() => changeActive("vertical-cards")}
      >
       <FontAwesomeIcon icon={faGrip}/>
      </li>
      <li
        className={
          active === "horizontal-cards"
            ? "nav-item transition active"
            : "nav-item transition"
        }
        onClick={() => changeActive("horizontal-cards")}
      >
        <FontAwesomeIcon icon={faList}/>
      </li>
    </ul>
    </div>
    </nav>
  )
}

export default ListContentNav
