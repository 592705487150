import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { GetAllCompounds } from "../../Redux/Redux-Toolkit/Slices/Compounds/CompoundsSlice";
import GetAllCategoriesHook from "../Categories/GetAllCategoriesHook";
import FormatNumberHook from "../FormatNumber/FormatNumberHook";
const CompoundsFilterHook = () => {
  // translation
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();

  // start for range slider
  const [value, setValue] = useState([0, 1000]);

  // filter hook
  const dispatch = useDispatch();

  // end filter hook

  // hook to get the various data
  const [categories_loading, categories] = GetAllCategoriesHook();
  const [formatNumber] = FormatNumberHook();

  // End for range slider
  // advanced filter
  const [open, setOpen] = useState(false);
  const [showState, setShowState] = useState("+");
  // handle change price

  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };

  // handle collapse for advanced filter
  const handleCollaps = () => {
    setOpen(!open);
    showState === "+" ? setShowState("-") : setShowState("+");
  };
  // End advanced filter

  const handleTypeLang = () => {
    if (lang === "en") {
      return localStorage.getItem("type") === "sale" ? "sale" : "rent";
    } else {
      return localStorage.getItem("type") === "sale" ? "بيع" : "ايجار";
    }
  };
  const construction_status_list = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    {
      value: "construction_status",
      label: t("listingPage.listingSidebar.construction_status"),
    },
    {
      value: "sent_delivered",
      label: t("listingPage.listingSidebar.sent_delivered"),
    },
  ];
  const finish_type_list = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "red_brick", label: t("listingPage.listingSidebar.red_brick") },
    {
      value: "semi_finished",
      label: t("listingPage.listingSidebar.semi_finished"),
    },
    { value: "super_lux", label: t("listingPage.listingSidebar.super_lux") },
    {
      value: "ultra_super_lux",
      label: t("listingPage.listingSidebar.ultra_super_lux"),
    },
  ];
  const paying_list = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "cash_money", label: t("listingPage.listingSidebar.cash") },
    {
      value: "Installment",
      label: t("listingPage.listingSidebar.installment"),
    },
    {
      value: "cash_and_insatllment",
      label: t("listingPage.listingSidebar.cashAndInstallment"),
    },
  ];
  const categoryList = [
    categories_loading === false
      ? categories
        ? categories?.map((item) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : null
              }`,
              label: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : null
              }`,
            };
          })
        : null
      : null,
  ];
  categoryList[0]?.unshift({
    id: "0",
    value: "0",
    label: t("listingPage.listingSidebar.all"),
  });

  // get all cities and all categories
  let categoryValueResult = "";
  const getCategoryValue = () => {
    let result1 = categoryList
      ? categoryList[0]?.filter((item) => {
          return item?.id === localStorage.getItem("category_id");
        })
      : null;
    categoryValueResult = result1 ? result1[0]?.value : "";
  };
  getCategoryValue();

  // react share component show
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };

  const [search, set_search] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [category_id, set_category_id] = useState("");
  const [finishing, setFinishing] = useState("");
  const [furnished, setFurnished] = useState("");
  const [paying, set_paying] = useState("");
  const [sale_status, set_sale_status] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);
  // search params
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryUrl = searchParams.get("category_id") || "";
  const finishingUrl = searchParams.get("finishing") || "";
  const furnishedUrl = searchParams.get("furnished") || "";
  const min_price_url = searchParams.get("min_price") || "";
  const max_price_url = searchParams.get("max_price") || "";
  const paying_url = searchParams.get("paying") || "";
  const sale_status_url = searchParams.get("sale_status") || "";
  const searchWord_url = searchParams.get("search") || "";

  // set search params function
  const add_search_params = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  // get the data from the server
  const getFilterData = async (queryString) => {
    await dispatch(GetAllCompounds(queryString));
  };

  // function to reset the filter values
  const Reset = () => {
    setMinPrice("");
    setMaxPrice("");
    set_category_id("");
    setFurnished("");
    setFinishing("");
    set_paying("");
    set_sale_status("");
    getFilterData();
    setSearchParams({});
  };

  // get the data depends on the url params
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    getFilterData(`price_min=${min_price_url}&price_max=${max_price_url}&category_id=${categoryUrl}
      &paying=${paying_url}&sale_status=${sale_status_url}&Furnished=${furnishedUrl}&Finishing_type=${finishingUrl}
      &title=${searchWord_url}`);
  }, [
    categoryUrl,
    paying_url,
    sale_status_url,
    finishingUrl,
    furnishedUrl,
    min_price_url,
    max_price_url,
    searchWord_url,
  ]);

  // functions that handle various user interactions and updates the corresponding values in the browser's local storage.
  const onChangeSearch = (e) => {
    set_search(e.target.value);
    add_search_params("search", e.target.value);
  };
  const onChangeMinPrice = (e) => {
    setMinPrice(e);
  };
  const onChangeMaxPrice = (e) => {
    setMaxPrice(e);
  };
  const onSearchPrice = () => {
    add_search_params("max_price", maxPrice);
    add_search_params("min_price", minPrice);
  };
  const onChangeFinishing = (e) => {
    if (e.target.checked === true) {
      setFinishing(e.target.value);
      add_search_params("finishing", e.target.value);
    } else {
      setFinishing("");
      add_search_params("finishing", "");
    }
  };
  const onChangeFurnished = (e) => {
    if (e.target.checked === true) {
      setFurnished(e.target.value);
      add_search_params("furnished", e.target.value);
    } else {
      setFurnished("");
      add_search_params("furnished", e.target.value);
    }
  };
  const onChangeCategoryId = (e) => {
    if (e.target) {
      set_category_id(e.target.id);
      add_search_params("category", e.target.id);
    } else {
      set_category_id(e.id);
      add_search_params("category", e.id);
    }
  };

  const onChangePaying = (e) => {
    if (e.target) {
      set_paying(e.target.value);
      add_search_params("paying", e.target.value);
    } else {
      set_paying(e.value);
      add_search_params("paying", e.value);
    }
  };

  return [
    t,
    Reset,
    handleTypeLang,
    categoryList,
    onChangeCategoryId,
    categoryValueResult,
    formatNumber,
    minPrice,
    maxPrice,
    value,
    handleChange,
    open,
    handleCollaps,
    showState,
    lang,
    handleChangeActive,
    active,
    categories_loading,
    categories,
    onChangeFinishing,
    onChangeFurnished,
    onChangePaying,
    paying_list,
    onSearchPrice,
    onChangeSearch,
    search,
  ];
};

export default CompoundsFilterHook;
