import React from 'react'
import "./WantToSell.css"
import BtnComponent from '../../Utility/BtnComponent/BtnComponent'
import SectionTitle from '../../Utility/SectionTitle/SectionTitle'
import SelectComponent from '../../Utility/SelectComponent/SelectComponent'
import RequestAServiceHook from '../../../CustomHooks/OurSettings/RequestAServiceHook/RequestAServiceHook'

const WantToSell = () => {
    const [,name,phone,,message,onChangeName,,onChangePhone
      ,onChangeService,onChangeMessage,onSubmit,loading,isPress,t
    ]= RequestAServiceHook()
      const typeList = [
        { value: "Property Sale", label:t("homePage.WantToSell.PropertySale") },
        { value: "Property Rental", label: t("homePage.WantToSell.PropertyRental") },
        { value: "Property Management", label: t("homePage.WantToSell.PropertyManagement") },
        { value: "Property Valuation", label: t("homePage.WantToSell.PropertyValuation") },
        { value: "Property Marketing", label: t("homePage.WantToSell.PropertyMarketing") },
        { value: "Real Estate Consulting", label: t("homePage.WantToSell.RealEstateConsulting") },
      ];
  return (
    <div className='want-to-sell position-relative'>
      <div className='overlay'></div>
       <div className="wrapper p-3">
          <div className="row my-5">
          <div className="col-md-6 text-center position-relative">
             <form onSubmit={onSubmit} className="p-3 p-md-5 want-to-sell-form">
             <SectionTitle
              title={t("homePage.WantToSell.title")}
              // des={t("homePage.WantToSell.des")}
            />
             <div className="row txt-start">
                <div className="col-md-12">
                  <div className="form-group form-group-name">
                    <input
                    value={name}
                    onChange={onChangeName}
                      type="text"
                      className="custom-input"
                      name="name"
                      placeholder={t("contactUsPage.name")}
                      required=""
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group form-group-name">
                    <input
                    value={phone}
                    onChange={onChangePhone}
                    placeholder={t("contactUsPage.phone")}
                      type="text"
                      className="custom-input"
                      name="phone"
                      minLength={11}
                      maxLength={11}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group form-group-name">
                  <SelectComponent
                  options={typeList}
                  placeholder={t("homePage.WantToSell.serviceType")}
                  onChange={onChangeService}
                  />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                    value={message}
                    onChange={onChangeMessage}
                      className="custom-input"
                      rows="5"
                      name="message"
                      placeholder={t("contactUsPage.message")}
                      required
                    ></textarea>
                  </div>
                  <div className="form-group float-right mb-0">
                    <div className="btn-box btn-contact">
                    <BtnComponent
                    loading={isPress===true && loading===true ? true:false}
                     type={"submit"} value={t("contactUsPage.submit")}/>
                    </div>
                  </div>
                </div>
              </div>
             </form>
            </div>
            <div className='col-md-6 position-relative'>
              <h2 className='fs-55 text-capitalize my-3 text-white text-center fw-bold'>
              {t("homePage.WantToSell.youManage")}
              </h2>
              <p className='fs-22 text-center'>
              {t("homePage.WantToSell.des")}
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default WantToSell
