import React from "react";
import BtnComponent from "../../../../components/Utility/BtnComponent/BtnComponent";
import { Collapse } from "react-bootstrap";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import SelectComponent from "../../../Utility/SelectComponent/SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import ListingSidebarHook from "../../../../CustomHooks/Listing/ListingSidebarHook/ListingSidebarHook";
const FilterGroup = ({ installment }) => {
  const [
    t,
    onSearch,
    Reset,
    searchWord,
    onSearchSidebar,
    onChangeSearchWord,
    code,
    onChangeCode,
    type_list,
    onChangeType,
    handleTypeLang,
    categoryList,
    onChangeCategoryId,
    categoryValueResult,
    options_3,
    onChangeMinSpace,
    onChangeMaxSpace,
    minSpace,
    maxSpace,
    options_5,
    onChangeRooms,
    options_6,
    onChangeBathrooms,
    formatNumber,
    minPrice,
    maxPrice,
    value,
    handleChange,
    open,
    handleCollaps,
    showState,
    properties_loading,
    properties,
    lang,
    onChangePropertyId,
    handleChangeActive,
    active,
    categories_loading,
    categories,
    onChangeFinishing,
    onChangeFurnished,
    handleChangeFilterSidebarActive,
    onChangePaying,
    onChangeSaleStatus,
    paying_list,
    sale_status_list,
  ] = ListingSidebarHook(installment);
  return (
    <div className="filter-group txt-start">
      <div className="products__filter__group">
        <div className="products__filter__header py-1 py-xl-0 my-lg-0 position-relative">
          <div
            className="close d-block d-xl-none my-2"
            onClick={() => handleChangeFilterSidebarActive()}
          >
            <span>
              <FontAwesomeIcon icon={faX} />
            </span>
          </div>
          <h5 className="text-capitalize fw-bold">
            {t("listingPage.listingSidebar.title")}
          </h5>
        </div>
        <div className="products__filter__body my-3">
          <div className="form-group">
            <form onSubmit={onSearch}>
              <div className="row no-gutters my-2">
                <div className="col-8">
                  <input
                    className="custom-input"
                    value={searchWord}
                    onChange={onChangeSearchWord}
                    type="search"
                    placeholder={t("listingPage.listingSidebar.search")}
                  />
                </div>
                <div className="col-4">
                  <BtnComponent
                    icon={<i className="fa fa-search"></i>}
                    type={"submit"}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="form-group">
            <SelectComponent
              options={type_list}
              placeholder={t("listingPage.listingSidebar.type")}
              onChange={onChangeType}
            />
          </div>
          {!installment && (
            <div className="form-group">
              <SelectComponent
                options={paying_list}
                placeholder={t("listingPage.listingSidebar.paying")}
                onChange={onChangePaying}
              />
            </div>
          )}
          <div className="form-group">
            <SelectComponent
              options={sale_status_list}
              placeholder={t("listingPage.listingSidebar.saleStatus")}
              onChange={onChangeSaleStatus}
            />
          </div>
          <div className="form-group">
            <SelectComponent
              options={categoryList[0]}
              placeholder={t("listingPage.listingSidebar.category")}
              onChange={onChangeCategoryId}
            />
          </div>
          <div className="form-group">
            <input
              className="custom-input"
              value={minSpace}
              onChange={onChangeMinSpace}
              type="number"
              placeholder={t("listingPage.listingSidebar.minSpace")}
            />
          </div>
          <div className="form-group">
            <input
              className="custom-input"
              value={maxSpace}
              onChange={onChangeMaxSpace}
              type="number"
              placeholder={t("listingPage.listingSidebar.maxSpace")}
            />
          </div>
          <div className="form-group">
            <SelectComponent
              options={options_5}
              placeholder={t("listingPage.listingSidebar.rooms")}
              onChange={onChangeRooms}
              // value={{
              //   value: localStorage.getItem("rooms"),
              //   label:
              //     localStorage.getItem("rooms") ||
              //     t("listingPage.listingSidebar.rooms"),
              // }}
            />
          </div>
          <div className="form-group">
            <SelectComponent
              options={options_6}
              placeholder={t("listingPage.listingSidebar.bathrooms")}
              onChange={onChangeBathrooms}
            />
          </div>
          <div className="form-group price-range-slider">
            <Box sx={{ width: "100%" }}>
              <Typography id="track-inverted-slider" gutterBottom>
                {t("listingPage.listingSidebar.priceRange")}
                <span className="parent-span">
                  <span className="value-0 d-block">{`${t(
                    "listingPage.listingSidebar.EGP"
                  )} ${formatNumber(minPrice)}`}</span>
                  <span>--</span>
                  <span className="value-1">{`${t(
                    "listingPage.listingSidebar.EGP"
                  )} ${formatNumber(maxPrice)}`}</span>
                </span>
              </Typography>
              <Slider
                value={value}
                onChange={handleChange}
                min={0}
                max={20000000}
                step={100000}
              />
            </Box>
          </div>
          <div className="form-group mb-0 mt-2">
            <div
              className="btn-box fw-bold my-3 show-more"
              aria-controls="example-collapse-text"
              aria-expanded={open}
              onClick={handleCollaps}
            >
              <BtnComponent
                value={`${t(
                  "listingPage.listingSidebar.advancedFilter"
                )} ${showState}`}
                st={"w-100"}
              />
            </div>
            <Collapse in={open}>
              <div id="example-collapse-text container-fluid">
                <div className="row overflow-hidden border-bottom py-2">
                  <h4 className="fs-20 fw-bold color-primary">
                    {t("listingPage.listingSidebar.facilities")}
                  </h4>
                  {properties_loading === false
                    ? properties?.map((item) => {
                        return (
                          <div className="col-12 my-2" key={item?.id}>
                            <label className="custom-checkbox text-capitalize text-nowrap">
                              {lang === "en"
                                ? item?.data_trans
                                  ? item?.data_trans[0]?.title_en
                                  : null
                                : item?.data_trans
                                ? item?.data_trans[1]?.title_ar
                                : null}
                              <input
                                type="checkbox"
                                value={
                                  item?.data_trans
                                    ? item?.data_trans[0]?.title_en
                                    : null
                                }
                                id={item?.id}
                                onChange={onChangePropertyId}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div className="row overflow-hidden border-bottom py-2">
                  <h4 className="fs-20 fw-bold text-capitalize color-primary">
                    {t("listingPage.listingSidebar.finishing")}
                  </h4>
                  <div className="col-12 my-2">
                    <label className="custom-checkbox text-capitalize text-nowrap">
                      طوب احمر
                      <input
                        type="checkbox"
                        value="Red Bricks"
                        onChange={onChangeFinishing}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-12 my-2">
                    <label className="custom-checkbox text-capitalize text-nowrap">
                      نص تشطيب
                      <input
                        type="checkbox"
                        value="Finishing text"
                        onChange={onChangeFinishing}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-12 my-2">
                    <label className="custom-checkbox text-capitalize text-nowrap">
                      سوبر لوكس
                      <input
                        type="checkbox"
                        value="Super deluxe"
                        onChange={onChangeFinishing}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-12  my-2">
                    <label className="custom-checkbox text-capitalize text-nowrap">
                      تشطيب شخصى
                      <input
                        type="checkbox"
                        value="Lux"
                        onChange={onChangeFinishing}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="row overflow-hidden py-2">
                  <h4 className="fs-20 fw-bold text-capitalize color-primary">
                    {t("listingPage.listingSidebar.furnished")}
                  </h4>
                  <div className="col col-xl-6 my-2">
                    <label className="custom-checkbox text-capitalize text-nowrap">
                      مفروش
                      <input
                        type="checkbox"
                        value="Furnished"
                        onChange={onChangeFurnished}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col col-xl-6 my-2">
                    <label className="custom-checkbox text-capitalize text-nowrap">
                      غير مفروش
                      <input
                        type="checkbox"
                        value="Unfurnished"
                        onChange={onChangeFurnished}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="products__filter__footer">
          <div className="form-group mb-0" onClick={onSearchSidebar}>
            <BtnComponent
              value={t("listingPage.listingSidebar.search")}
              icon={<FontAwesomeIcon icon={faSearch} />}
            />
          </div>
          <div className="form-group mb-0" onClick={Reset}>
            <BtnComponent value={t("listingPage.listingSidebar.reset")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterGroup;
