import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Notify from "../UseNotification/UseNotification";
import Cookies from "js-cookie";
import { publisherSignUp } from "../../Redux/Redux-Toolkit/Slices/Auth/PublisherSignUpSlice";

const PublisherSignUpHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.rootReducer.publisherSignUpReducer);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [role, setRole] = useState("");
  const [company_name, set_company_name] = useState("");
  const [commercial_registration, set_commercial_registration] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [conditions_agree, set_conditions_agree] = useState(false);
  const [loading, setloading] = useState(true);
  const [isPress, setIsPress] = useState(false);
  // function for states
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onChangeWhatsapp = (e) => {
    setWhatsapp(e.target.value);
  };
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };
  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangeRole = (e) => {
    setRole(e.target.value);
  };
  const onChangeCompanyName = (e) => {
    set_company_name(e.target.value);
  };
  const onChange_commercial_registration = (e) => {
    set_commercial_registration(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPass = (e) => {
    setConfirmPass(e.target.value);
  };
  const onChangeConditionAgree = (e) => {
    set_conditions_agree(!conditions_agree);
  };
  // on submit fn
  const onSubmit = async(e) => {
    e.preventDefault();
    if(role === ""){
        Notify("please choose the role type","warn")
        return
    }
    if(role !=="unit_onwer"){
            if(company_name===""){
                Notify("please enter the company name","warn")
                return
            }
            // else if(commercial_registration===""){
            //     Notify("please enter the commercial registration","warn")
            //     return
            // }
    }
    if(conditions_agree === false){
        Notify("please agree on the conditions","warn")
        return
    }
    setIsPress(true);
    setloading(true);
    await dispatch(
        publisherSignUp( {
            name,
            email,
            phone,
            whatsapp,
            address,
            city,
            role,
            company_name,
            commercial_registration_no:commercial_registration,
            password,
            password_confirmation: confirmPass,
        })
    );
    setloading(false);
    setIsPress(false);  
    
  };
  useEffect(() => {
    if (loading === false) {
      if (response) {
        console.log(response)
        if (response?.publisherSignUp?.status === 200) {
          if (response?.publisherSignUp?.data?.token && response?.publisherSignUp?.data?.user) {
            Cookies.set("user", JSON.stringify(response?.publisherSignUp?.data?.user), {
              expires: 29,
              path: " ",
              secure: true,
            });
            Cookies.set("user_token", response?.publisherSignUp?.data?.token, {
              expires: 29,
              path: " ",
              secure: true,
            });
          } else {
            Cookies.remove("user");
            Cookies.remove("user_token");
          }
          Notify(response?.publisherSignUp?.data?.message, "success");
          setTimeout(() => {
            window.location.href="/"
          },1000);
        } else if (response?.publisherSignUp?.response?.status === 422) {
            if(response?.publisherSignUp?.response?.data?.name){
              Notify(response?.publisherSignUp?.response?.data?.name[0],"error");
            }
            else if(response?.publisherSignUp?.response?.data?.email){
                Notify(response?.publisherSignUp?.response?.data?.email[0],"error");
            }else if(response?.publisherSignUp?.response?.data?.password){
                Notify(response?.publisherSignUp?.response?.data?.password[0],"error");
            }else if(response?.publisherSignUp?.response?.data?.phone){
                Notify(response?.publisherSignUp?.response?.data?.phone[0],"error");
              }else if(response?.publisherSignUp?.response?.data?.whatsapp){
              Notify(response?.publisherSignUp?.response?.data?.whatsapp[0],"error");
            }
            else if(response?.publisherSignUp?.response?.data?.commercial_registration_no){
                Notify(response?.publisherSignUp?.response?.data?.commercial_registration_no[0],"error");
            }
        }
      }
    }
  },[loading]);

  return [name,email,phone,whatsapp,city,address,role,company_name,commercial_registration,password,confirmPass,
    onChangeName,onChangeEmail,onChangePhone,onChangeWhatsapp,onChangeCity,onChangeAddress
    ,onChangeRole,onChangeCompanyName,onChange_commercial_registration,onChangePassword,onChangeConfirmPass,
    onSubmit,loading,isPress,conditions_agree,onChangeConditionAgree
  ];
};

export default PublisherSignUpHook;
