import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../../components/GeneralSections/Footer/Footer'
import ScrollToTopBtn from '../../components/Utility/ScrollToTopBtn/ScrollToTopBtn'

const GeneralLayout = () => {
  return (
    <div className="general-layout">
    <main>
        <Outlet/>
    </main>
    <ScrollToTopBtn />
    <Footer />
</div>
  )
}

export default GeneralLayout
