import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllSimilarUnits } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";

const GetSimilarUnitsHook = (id,price) => {
  const [loading,setLoading] = useState(false);
  const [units,setUnits] = useState([]);
  const [unitsCount,setUnitsCount] = useState(1);
 
  const dispatch = useDispatch();
  const res = useSelector((state) => state.rootReducer.unitsReducer.similarUnits);
  const minPrice = price - 200000 
  const maxPrice = price + 200000 
 
  const getData =async (queryString) => {
    setLoading(true);
      await dispatch(GetAllSimilarUnits(queryString));
    setLoading(false);
  }
  useEffect(() => {
    getData(`category_id${id}&price_min=${minPrice}&price_max=${maxPrice}`);
  },[id,price]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res) {
          setUnits(res?.data?.data);
          setUnitsCount(res?.data?.data?.length || 0)
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,units,unitsCount];
};

export default GetSimilarUnitsHook;
