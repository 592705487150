import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import profile_img from "../../images/anonymous.png";
import Notify from "../UseNotification/UseNotification";
import { profileUpdate } from "../../Redux/Redux-Toolkit/Slices/User/UserSettingSlice";
import UserProfileHook from "./UserProfileHook";
import Cookies from 'js-cookie'
const UserSettingHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.rootReducer.settingReducer);
  const [,info_loading,data] = UserProfileHook()
  // hook states
  const [img, setImg] = useState(profile_img);
  const [selectedImage,setSelectedImage] = useState("")
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [bio, setBio] = useState("");
  const [company_name, set_company_name] = useState("");
  const [website_link, set_website_link] = useState("");
  const [whatsapp,set_whatsapp] = useState("");
  const [facebook, set_facebook] = useState("");
  const [twitter, set_twitter] = useState("");
  const [linkedin, set_linkedin] = useState("");
  const [instagram, set_instagram] = useState("");
  const [loading, setloading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  // function for states
   // when user choose photo this method will store this photo
   const onChangeImg =(event)=>{
    if(event.target.files && event.target.files[0]){
      setImg(URL.createObjectURL(event.target.files[0]))
      setSelectedImage(event.target.files[0])
    }
  }
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };
  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangeBio = (e) => {
    setBio(e.target.value);
  };
  const onChange_companyName = (e) => {
    set_company_name(e.target.value);
  };
  const onChange_websiteLink = (e) => {
    set_website_link(e.target.value);
  };
  const onChange_whatsapp = (e) => {
    set_whatsapp(e.target.value);
  };
  const onChange_facebook = (e) => {
    set_facebook(e.target.value);
  };
  const onChange_twitter = (e) => {
    set_twitter(e.target.value);
  };
  const onChange_instagram = (e) => {
    set_instagram(e.target.value);
  };
  const onChange_linkedin = (e) => {
    set_linkedin(e.target.value);
  };
  // on submit fn
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsPress(true);
    setloading(true);
    const formData = new FormData()
    selectedImage !== "" && formData.append("image",selectedImage);
    formData.append("name",name)
    formData.append("email",email)
    formData.append("phone",phone)
    formData.append("city",city)
    formData.append("address",address)
    formData.append("bio",bio)
    formData.append("company_name",company_name)
    formData.append("website_link",website_link)
    formData.append("facebook",facebook)
    formData.append("whatsapp",whatsapp)
    formData.append("instagram",instagram)
    formData.append("linkedin",linkedin)
    formData.append("twitter",twitter)
    await dispatch(profileUpdate(formData));
    setloading(false);
    setIsPress(false);
  };

  // set the last values 
  useEffect(()=>{
        if(info_loading===false){
        if(data){ 
            setImg(data?.image || "")
            setName(data?.name || "")
            setEmail(data?.email || "")
            setPhone(data?.phone || "")
            setCity(data?.city || "")
            setAddress(data?.address || "")
            setBio(data?.bio || "")
            set_company_name(data?.company_name || "")
            set_website_link(data?.website_link || "")
            set_whatsapp(data?.whatsapp || "")
            set_facebook(data?.facebook || "")
            set_instagram(data?.instagram || "")
            set_twitter(data?.twitter || "")
            set_linkedin(data?.linkedin || "")
        }
    }
},[info_loading,data])
// 
useEffect(() => {
    if (loading === false) {
      if (response) {
       if(response?.profileUpdatedInfo?.data?.status === 200){
        if (response?.profileUpdatedInfo?.data?.token&&response?.profileUpdatedInfo?.data?.user) {
            Cookies.set("user", JSON.stringify(response?.profileUpdatedInfo?.data?.user), {
              expires:365,
              path:"/",
              secure: true,
              domain:"localhost"
            });
            Cookies.set("user_token", response?.profileUpdatedInfo?.data?.token, {
              expires:365,
              path:"/",
              secure: true,
              domain:"localhost"
            });
          } else {
            Cookies.remove("user");
            Cookies.remove("user_token");
          }
        Notify(response?.profileUpdatedInfo?.data?.message,"success")
        // setTimeout(()=>{
        //   window.location.reload()
        // },1000)
       }else{
        Notify("there is a problem","error")
       }
      }
    }
  },[loading,response]);
  
  return [img,name,email,phone,company_name,website_link,city,address,bio,facebook,whatsapp,twitter
    ,instagram,linkedin,onChangeImg,onChangeName,onChangeEmail,onChangePhone,onChange_companyName,
    onChange_websiteLink,onChangeBio,onChange_facebook,onChange_twitter,onChange_whatsapp,
    onChange_linkedin,onChange_instagram,onChangeCity,onChangeAddress,onSubmit,loading,isPress
    ,info_loading
  ];

};

export default UserSettingHook;
