import React from "react";
import "./SearchBox.css";
import SelectComponent from "../../../Utility/SelectComponent/SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import SearchBoxHook from "../../../../CustomHooks/SearchBox/SearchBoxHook";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const SearchBox = () => {
  const lang = Cookies.get("i18next");
  const [
    categoryList,
    active,
    roomsList,
    bathRoomsList,
    categoryValueResult,
    onChangeBathrooms,
    onChangeType,
    onChangeRooms,
    onChangeCategoryId,
    onSearch,
    Reset,
    code,
    handleChangeActive,
    t,
    minPrice,
    maxPrice,
    value,
    handleChange,
    formatNumber,
    onChangeGateId,
    onChangeDistrictId,
    searchWord,
    onChangeSearchWord,
  ] = SearchBoxHook();
  const Navigate = useNavigate();
  return (
    <div className="search-box">
      <div className="wrap__intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="d-flex align-content-center justify-content-center">
                <li
                  onClick={() => Navigate("/listing?main_category=residential")}
                  className="fs-18 fw-bold border bg-transparent text-white wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span className="mx-2">
                    {" "}
                    {t("generalSections.landing.searchBox.Residential")}
                  </span>
                </li>
                <li
                  o
                  onClick={() =>
                    Navigate("/listing?main_category=Administrative")
                  }
                  className="fs-18 fw-bold border bg-transparent text-white wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span className="mx-2">
                    {t("generalSections.landing.searchBox.Administrative")}
                  </span>
                </li>
                <li
                  onClick={() => Navigate("/listing?main_category=commercial")}
                  className="fs-18 fw-bold border bg-transparent text-white wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span className="mx-2">
                    {t("generalSections.landing.searchBox.Commercial")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="search-content-wrapper align-items-center justify-content-start flex-wrap">
            <div className="mx-auto">
              <div className="wrap__intro-heading text-center">
                <div className="intro-title">
                  <h1 className="des text-capitalize second-title">
                    {t("generalSections.landing.searchBox.findYourDreamHouse")}
                  </h1>
                </div>
                <p className="fs-20 mb-3">
                  {t("generalSections.landing.searchBox.description")}
                </p>
                <div className="search__container bg-white radius-20x d-flex flex-nowrap">
                  <div
                    className={`bg-sec text-center d-none d-md-block position-relative ${
                      lang === "en" ? "property-search" : "property-search-ar"
                    } px-4`}
                  >
                    <p className="fw-bold h-100 d-flex align-items-center m-0 text-white fs-20">
                      property search
                    </p>
                  </div>
                  <div className="row input-group p-4 no-gutters select-lists text-center">
                    <div className="col-lg-2 p-0 select-list-item txt-start">
                      <SelectComponent
                        options={categoryList[0]}
                        placeholder={t(
                          "generalSections.landing.searchBox.category"
                        )}
                        onChange={onChangeCategoryId}
                      />
                    </div>
                    <div className="col-lg-2 p-0 select-list-item txt-start">
                      <SelectComponent
                        options={roomsList}
                        placeholder={t(
                          "generalSections.landing.searchBox.rooms"
                        )}
                        onChange={onChangeRooms}
                      />
                    </div>
                    <div className="col-lg-2 p-0 select-list-item txt-start">
                      <SelectComponent
                        options={bathRoomsList}
                        placeholder={t(
                          "generalSections.landing.searchBox.bathRooms"
                        )}
                        onChange={onChangeBathrooms}
                      />
                    </div>
                    <div className="col-lg-4 p-0 select-list-item txt-start">
                      <input
                        value={searchWord}
                        onChange={onChangeSearchWord}
                        className="custom-input"
                        type="search"
                        placeholder={"اسم الكمبوند او المول"}
                      />
                    </div>
                    <div className="col-lg-2 p-0 input-group-append txt-start">
                      <div className="btn-box search-btn " onClick={onSearch}>
                        <button className="border-0 hover-1 py-3 px-5 fw-bold transition radius-30x text-nowrap">
                          <FontAwesomeIcon icon={faSearch} />
                          <span className="mx-1">
                            {t("generalSections.landing.searchBox.search")}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
