import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetDataToken } from "../../../../Hooks/GetDataHook";

const initialState = {
    userNotifications:[],
    markAsRead:[],
    notificationsCount:0,
    error:null,
    loading:true
}
export const GetUserNotifications = createAsyncThunk("userNotifications",async(_,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetDataToken('/user/notifications')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const MarkNotificationsAsRead = createAsyncThunk("markNotificationsAsRead",async(_,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetDataToken('/user/notifications/mark_all_asread')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const notificationsCount = createAsyncThunk("notificationsCount",async(_,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetDataToken('/user/notifications/count')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

const userNotificationSlice = createSlice({
    name:"userNotificationSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetUserNotifications.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetUserNotifications.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.userNotifications =action?.payload
        })
        .addCase(GetUserNotifications.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.userNotifications =action?.payload
        })
        // mark as read
        .addCase(MarkNotificationsAsRead.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(MarkNotificationsAsRead.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.markAsRead =action?.payload
        })
        .addCase(MarkNotificationsAsRead.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.markAsRead =action?.payload
        })
        // un read notifications count
        .addCase(notificationsCount.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(notificationsCount.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.notificationsCount =action?.payload
        })
        .addCase(notificationsCount.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.notificationsCount =action?.payload
        })
    }
})
export const userNotificationReducer = userNotificationSlice.reducer
export const userNotificationActions = userNotificationSlice.actions