import { useState,useEffect, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllServiceList } from "../../Redux/Redux-Toolkit/Slices/Services/ServicesSlice";
const GetAllServiceListHook = (id) => {
  const [loading,setLoading] = useState(false);
  const [serviceList,setServiceList] = useState([]);
  const firstRender = useRef(true)
  const dispatch = useDispatch();
  const res = useSelector((state) => state.rootReducer.servicesReducer.allServiceList);

  const getData =async () => {
    setLoading(true);
    await dispatch(GetAllServiceList(id));
    setLoading(false);
  }
  const onPressPaginate = (page)=>{
    getData(page)
  }
  useEffect(() => {
    if(firstRender.current){
      getData();
      firstRender.current = false
    }
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res) {
          setServiceList(res?.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,serviceList,onPressPaginate];
};

export default GetAllServiceListHook;
