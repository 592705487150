import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import CategoriesAnalysisSideBar from "../../../components/Utility/CategoriesAnalysisSideBar/CategoriesAnalysisSideBar";
import DeveloperUnits from "../../../components/Developers/DeveloperUnits/DeveloperUnits";
import GetDeveloperDetailsHook from "../../../CustomHooks/Developers/GetDeveloperDetailsHook";
import { useParams } from "react-router-dom";
import ContactButtons from "../../../components/UI/contact-btns/ContactButtons";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
const DeveloperDetailsPage = () => {
  const { id } = useParams();
  const [t] = useTranslation();
  const [loading, developerDetails] = GetDeveloperDetailsHook(
    id?.slice(1, id.length)
  );
  return (
    <div className="developers-page page">
      <CustomHelmet
        title={t("developerDetailsPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader developersActive={"developersActive"} />
      <BreadcrumbSection title={t("developerDetailsPage.title")} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-none d-xl-block">
              <CategoriesAnalysisSideBar />
            </div>
            <div className="col-xl-8">
              {loading === false ? (
                <div className="content-wrapper">
                  <div className="developer-img" style={{ height: "500px" }}>
                    <img
                      src={developerDetails?.cover}
                      alt="developer-img"
                      loading="lazy"
                      className="w-100 h-100"
                    />
                  </div>
                  <div className="developer-info my-3 txt-start">
                    <div className="row">
                      <div className="info-head mt-0 mb-2 col-md-6">
                        <h3 className="fw-bold mt-0 mb-2">
                          {developerDetails?.name}
                        </h3>
                        <div>
                          <h3 className="fw-bold m-0">Contact info</h3>
                          <p className="m-0">
                            <span className="mx-1 color-primary ">
                              <FontAwesomeIcon icon={faPhone} />
                            </span>
                            <span>{developerDetails?.phone}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <ContactButtons
                          whatsapp={developerDetails?.phone}
                          phone={developerDetails?.phone}
                        />
                      </div>
                    </div>
                    <div className="description">
                      {developerDetails?.description}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="my-4">
                    <HomeSectionSkeleton />
                  </div>
                  <div className="my-4">
                    <HomeSectionSkeleton />
                  </div>
                </>
              )}
            </div>
            <div className="col-12">
              <div className="developer-units my-5">
                <h3 className="fw-bold text-center my-5">الكمبوندات</h3>
                <DeveloperUnits compounds={developerDetails?.projects} />
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default DeveloperDetailsPage;
