import React from "react";
import ContactUsSidebar from "../../components/ContactUs/ContactUsSidebar/ContactUsSidebar";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import WantToSell from "../../components/Home/WantToSell/WantToSell"
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";

const ContactUsPage = () => {
  const [t] = useTranslation()
  return (
    <div className="contact-us-page page">
     <CustomHelmet
      title={t("contactUsPage.title")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader />
      <BreadcrumbSection title={t("contactUsPage.title")} />
      <section className="wrap__contact-form">
        <div className="wrap-contact">
            <div className="col-12 position-relative">
            <iframe title="our-location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.8962993746195!2d31.10115257619888!3d29.953661322865194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14584f1335d9e6ad%3A0x9bce5fde6174108d!2sMenkaure%20Gate%20(Gate%203)%20Hadayek%20Al%20Ahram!5e0!3m2!1sen!2seg!4v1722499280000!5m2!1sen!2seg"
            width="100%" height="550" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
             <div className="col-8 col-lg-3 contact-items">
             <ContactUsSidebar/>
            </div>
            </div>
          </div>
          <div className="row my-3">
            <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default ContactUsPage;
