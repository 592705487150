import React from 'react'
import { useTranslation } from 'react-i18next'

const SingleDetailVideo = ({unitDetails}) => {
  const [t] = useTranslation()
  return (
    <div className="single__detail-features single-detail-video txt-start">
    <h5 className="text-capitalize detail-heading fw-bold color-primary fs-22">
    {t("singleDetailsPage.singleDetailsVideo.title")}
    </h5>
    <div className="single__detail-features-video">
      <figure className=" mb-0">
        <div className="home__video-area text-center">
          <video  >
          <source src={unitDetails?.video} type="video/mp4" />
          </video>
        </div>
      </figure>
    </div>
  </div>
  )
}

export default SingleDetailVideo
