import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllWishlist } from "../../Redux/Redux-Toolkit/Slices/Wishlist/WishlistSlice";
const GetAllWishlistHook = () => {
  const [loading,setLoading] = useState(false);
  const [wishlist,setWishlist] = useState([]);
  const [wishlistIds,setWishlistIds] = useState([]);
  const res = useSelector((state) => state.rootReducer.wishlistReducer.allWishlist);
  const deletion = useSelector((state) => state.rootReducer.wishlistReducer.deleteFromWishlist);
  const dispatch = useDispatch();

  const getData =async () => {
    setLoading(true);
    await dispatch(getAllWishlist());
    setLoading(false);
  }
  useEffect(() => {
    getData();
  },[]);
  useEffect(() => {
    getData();
  },[deletion]);

  useEffect(() => {
    try{
      if (loading === false) {
        if(res?.status === 200){
            setWishlist(res?.data)
            setWishlistIds(res?.data?.map((item)=> item?.unit?.id))
        }else{
            setWishlist([])
            setWishlistIds([])
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,wishlist,wishlistIds];
};

export default GetAllWishlistHook;
