import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllMalls } from "../../Redux/Redux-Toolkit/Slices/Malls/MallsSlice";

const GetAllMallsHook = () => {
  const [loading, setLoading] = useState(false);
  const [malls, setMalls] = useState([]);
  const [mallsCount, setMallsCount] = useState(1);
  const [meta, setMeta] = useState();
  const [error, setError] = useState(null); // Add state to store error
  const res = useSelector((state) => state.rootReducer.MallsReducer.allMalls);
  const dispatch = useDispatch();

  const getData = async (page = 1) => {
    setLoading(true);
    setError(null); // Clear any existing error
    try {
      await dispatch(GetAllMalls(page));
    } catch (error) {
      setError(error); // Store error in state
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onPressPaginate = (page) => {
    getData(page);
  };

  useEffect(() => {
    if (loading === false && res?.data?.data) {
      setMalls(res.data.data);
      setMallsCount(res?.data?.data?.length || 0);
      setMeta(res?.data?.meta);
    }
  }, [loading, res]);

  return [loading, malls, mallsCount, error, onPressPaginate, meta];
};

export default GetAllMallsHook;
