import React from "react";
import "./MainNavbar.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const MainNavbar = () => {
  const { t } = useTranslation();
  return (
    <div className="navbar navbar-hover navbar-expand-lg navbar-soft navbar-transparent">
      <nav className="main-navbar collapse navbar-collapse " id="main_nav99">
        <ul className="navbar-nav mx-auto ">
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="/listing"
              data-toggle="dropdown"
            >
              {t("generalSections.navBar.list")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link active" to="/installment">
              {t("generalSections.navBar.installment")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link icon-arrow" to="/compounds">
              {t("generalSections.navBar.compounds")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link icon-arrow" to="/malls">
              {t("generalSections.navBar.malls")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="/developers"
              data-toggle="dropdown"
            >
              {t("generalSections.navBar.developers")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="/services-category"
              data-toggle="dropdown"
            >
              {t("generalSections.navBar.services")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link icon-arrow" to="/about-us">
              {t("generalSections.navBar.aboutUs")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link icon-arrow" to="/contact-us">
              {t("generalSections.navBar.contactUs")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MainNavbar;
