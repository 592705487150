import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faMapMarker } from "@fortawesome/free-solid-svg-icons";
import person from "../../../images/anonymous.png";
import { Link, useNavigate } from "react-router-dom";

const MallCard = ({ item }) => {
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };
  const Navigate = useNavigate();
  return (
    <div className="mall-card">
      <div
        style={{ height: "280px" }}
        className="card-head pointer radius-20x overflow-hidden position-relative"
      >
        <Link to={`/mall-details/:${item?.id}`}>
          <img
            style={{ height: "280px" }}
            src={item?.cover_image}
            className="h-100 w-100 position-relative"
            alt="mall-img"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="card-body bg-white px-2 py-2">
        <div className="unit-info my-1 txt-start d-flex  justify-content-between flex-nowrap">
          <div className="info-wrapper">
            <h5 className="text-capitalize fw-bold unit-title m-0">
              {item?.data_trans ? item?.data_trans[0].title_en : "..."}
            </h5>
            <p className="location ellipses text-capitalize my-2 fs-16 fw-bold text-secondary">
              <span>
                <FontAwesomeIcon icon={faMapMarker} />
              </span>
              <span className="mx-2">{item?.address || "...."}</span>
            </p>
            <div className="developer-content d-flex  flex-nowrap">
              <img
                src={person}
                className="w-50px h-50px radius-50 flex-shrink-0"
                loading="lazy"
                alt="dev-img"
              />
              <div className="dev-info mx-2">
                <h5 className="m-0">Mohamed khaled</h5>
                <p className="m-0">5 projects</p>
              </div>
            </div>
          </div>
          <ul className="list-row my-1 flex-column justify-content-around align-items-center contacts">
            <li className="list-row-item my-1">
              <a
                href={`https://wa.me/+20${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social whatsapp"
              >
                <i className="fa fa-whatsapp"></i>
              </a>
            </li>
            <li className="list-row-item my-1">
              <a
                href={`tel:+20${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social twitter"
              >
                <i className="fa fa-phone"></i>
              </a>
            </li>
            <li className="list-row-item my-1">
              <button
                className="btn-social share"
                onClick={() => handleChangeActive()}
              >
                <i className="fa fa-share"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="card-footer pb-3">
        <div className="details-btn">
          <li
            onClick={() => Navigate("/mall-details/:id")}
            className="fs-18 fw-bold border bg-transparent text-black wd-fit py-1 py-md-2 px-1 px-md-3 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap"
          >
            <FontAwesomeIcon icon={faArrowRight} />
            <span className="mx-2">Details</span>
          </li>
        </div>
      </div>
    </div>
  );
};

export default MallCard;
