import React from "react";
import './AgentCard.css'
import agent_img from '../../../images/anonymous-image.png'
import { Link} from "react-router-dom";
const AgentCard = () => {
  return (
    <div className="agent-card transition row text-start rounded overflow-hidden">
      <div className="col-md-6 p-0">
        <Link to="/agent-details-page:id" className="profile__agents-avatar">
          <img
            src={agent_img}
            alt=""
            className="img-fluid "
            loading="lazy"
          />
          <div className="total__property-agent">20 listing</div>
        </Link>
      </div>
      <div className="col-md-6 my-auto">
        <div className="profile__agents-info">
          <h5 className="text-capitalize mb-0">
            <Link to="/profile-page">
              jhon doe
            </Link>
          </h5>
          <p className="text-capitalize mb-1">property agent</p>

          <ul className="list-unstyled mt-2">
            <li>
              <a href="/" className="text-capitalize">
                <span>
                  <i className="fa fa-building"></i>
                  office :
                </span>
                123 456 789
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                <span>
                  <i className="fa fa-phone"></i>
                  mobile :
                </span>
                123 456 789
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                <span>
                  <i className="fa fa-fax"></i> fax :
                </span>
                342 655
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                <span>
                  <i className="fa fa-envelope"></i>
                  email :
                </span>
                agents@property.com
              </a>
            </li>
          </ul>
          <ul className="list-row">
                    <li className="list-row-item">
                    <a href="/" className="btn-social facebook">
                    <i className="fa fa-facebook-f"></i>
                  </a>
                    </li>
                    <li className="list-row-item">
                    <a href="/" className="btn-social twitter">
                    <i className="fa fa-twitter"></i>
                  </a>
                    </li>
                    <li className="list-row-item">
                    <a href="/" className="btn-social linkedin">
                    <i className="fa fa-linkedin"></i>
                  </a>
                    </li>
                    <li className="list-row-item">
                    <a href="/" className="btn-social instagram ">
                    <i className="fa fa-instagram"></i>
                  </a>
                    </li>
                    <li className="list-row-item">
                    <a href="/" className="btn-social youtube">
                    <i className="fa fa-youtube"></i>
                  </a>
                    </li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default AgentCard;
