import React from "react";
// import ListingSidebar from "../../components/Listing/ListingSidebar/ListingSidebar";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import FilterGroup from "../../components/Listing/ListingSidebar/FilterGroup/FilterGroup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PremiumListContent from "../../components/premium/PremiumListContent/PremiumListContent";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";

const PremiumPage = () => {
  const {t} = useTranslation()
  const sidebarActive = useSelector((state)=>state.rootReducer.sidebarReducer.filterSidebarActive)
  return (
    <div className="listing-page page">
      <CustomHelmet
      title={t("premiumPage.title")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader listsActive={"listsActive"}/>
      <BreadcrumbSection title={t("premiumPage.title")} />
      <section>
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 d-none d-xl-block">
              <ListingSidebar />
            </div> */}
            <div className="col">
             <PremiumListContent />
            </div>
          </div>
          <div className={`responsive-sidebar d-xl-none ${sidebarActive?"active":""}`}>
          <FilterGroup/>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PremiumPage;
