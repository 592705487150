import React from "react";
import "./UserProfileSidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBell, faGear, faHeart, faList, faOutdent, faSquarePlus, faTableColumns, faUser } from "@fortawesome/free-solid-svg-icons";
import anonymous from '../../../images/anonymous.png'
import { Skeleton } from "@mui/material";
import UserProfileHook from "../../../CustomHooks/User/UserProfileHook";
import LogoutHook from "../../../CustomHooks/Auth/LogoutHook";
import { useTranslation } from "react-i18next";
const UserProfileSidebar = ({
  profilePage,listPage,wishlistPage,
  settingPage,logout,notificationsPage,addUnitPage
}) => {
  const  [user,loading,data] = UserProfileHook();
  const [,onLogout] = LogoutHook()
  const [t] = useTranslation()
  return (
    <div className="user-profile-sidebar">
      <div className="sidebar-nav">
        <div className="sidebar-nav-body">
          <div className="author-content">
            <div className="d-flex align-items-center">
              <div className="author-img avatar-sm">
                <img src={data?.image || anonymous} alt={"profile_img"} loading="lazy" />
              </div>
              <div className="author-bio">
                <div>
                  {
                  loading ===false?
                   <h4 className="author__title text-capitalize">{data?.name || "...."}</h4>
                   :
                   <>
                   <Skeleton variant="rounded" width={120} height={20} sx={{ margin:'5px 0'}}/>
                   </>
                    }
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar-menu-wrap">
            <ul className="sidebar-menu">
              <Link
                to="/user"
                className={`sidebar-menu-item-1 sidebar-menu-item  ${profilePage}`}
              >
                <span>
                <FontAwesomeIcon icon={faUser}/>
                </span>
                <span>{t("user.userProfileSidebar.profile")}</span>
              </Link>
              <Link
                to="/user/notifications"
                className={`sidebar-menu-item-1 sidebar-menu-item  ${notificationsPage}`}
              >
                <span>
                <FontAwesomeIcon icon={faBell}/>
                </span>
                <span>{t("user.userProfileSidebar.notifications")}</span>
              </Link>
              <Link
                to="/user/list"
                className={`sidebar-menu-item-2 sidebar-menu-item ${listPage}`}
              >
                <span>
                <FontAwesomeIcon icon={faList}/>
                </span>
                <span>{t("user.userProfileSidebar.list")}</span>
              </Link>
              <Link
                to="/user/wishlist"
                className={`sidebar-menu-item-4 sidebar-menu-item ${wishlistPage}`}
              >
                 <span>
                 <FontAwesomeIcon icon={faHeart}/>
                </span>
                <span>{t("user.userProfileSidebar.wishlist")}</span>
              </Link>
              <Link
                to="/user/setting"
                className={`sidebar-menu-item-5 sidebar-menu-item ${settingPage}`}
              >
                 <span>
                 <FontAwesomeIcon icon={faGear}/>
                </span>
                <span>{t("user.userProfileSidebar.setting")}</span>
              </Link>
              {
                user?.role !== "client"?
                user?.role ==="unit_onwer"?
                <Link
                to="/user/add-unit"
                className={`sidebar-menu-item-5 sidebar-menu-item ${addUnitPage}`}
              >
                 <span>
                 <FontAwesomeIcon icon={faSquarePlus}/>
                </span>
                <span>{t("user.userProfileSidebar.addUnit")}</span>
                </Link>
                :
                <a
                href={process.env.REACT_APP_DATA_BASE}
                rel="noreferrer"
                target="_blank"
                className={`sidebar-menu-item-5 sidebar-menu-item`}
              >
                 <span>
                 <FontAwesomeIcon icon={faTableColumns}/>
                </span>
                <span>{t("user.userProfileSidebar.dashboard")}</span>
                </a>
                :
                null
              }
              <li
                className={`sidebar-menu-item-6 sidebar-menu-item ${logout}`}
                onClick={onLogout}
              >
               <span>
               <span>
               <FontAwesomeIcon icon={faOutdent} />
                </span>
                </span>
                <span>{t("user.userProfileSidebar.logout")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileSidebar;
