import React from "react";
import "../UserSettingPage/UserSettingPage.css";
import UserProfileSidebar from "../../../components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../components/User/UserDashboardBread/UserDashboardBread";
import UserAddUnitSection from "../../../components/User/UserAddUnit/UserAddUnitSection";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const UserAddUnitPage = () => {
  const lang = Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="user-add-unit-page page user-setting-page">
      <CustomHelmet
      title={t("user.userAddUnitPage.title")}
      />
    <div className={`d-none d-lg-block ${lang==="en"?"user-sidebar-wrap":"user-sidebar-wrap-ar"}`}>
      <UserProfileSidebar  addUnitPage={"addUnitPage"} />
    </div>
    <div className="user-dashboard">
      <UserDashboardNav settingsPageActive={"page-active"} />
      <UserDashboardBread section_title={"Add unit"} />
      <div className="dashboard-main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
             <UserAddUnitSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default UserAddUnitPage
