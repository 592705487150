import React, { useState } from "react";
import "./PublisherSignUpPage.css";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import PublisherSignUpHook from "../../../CustomHooks/Auth/PuplisherSignUpHook";
import AuthTitle from "../../../components/Auth/AuthTitle/AuthTitle"
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const PublisherSignUpPage = () => {
  // handle passwords
  const [showPassWordIcon2, setShowPasswordIcon2] = useState(faEyeSlash);
  const [passwordState2, setPasswordState2] = useState("password");
  const [showPassWordIcon3, setShowPasswordIcon3] = useState(faEyeSlash);
  const [passwordState3, setPasswordState3] = useState("password");
  // handle user type tab
  const [userTabActive, setUserTabActive] = useState("");
  const handlePasswordState2 = () => {
    passwordState2 === "password"
      ? setPasswordState2("text")
      : setPasswordState2("password");
    showPassWordIcon2 === faEyeSlash
      ? setShowPasswordIcon2(faEye)
      : setShowPasswordIcon2(faEyeSlash);
  };
  const handlePasswordState3 = () => {
    passwordState3 === "password"
      ? setPasswordState3("text")
      : setPasswordState3("password");
    showPassWordIcon3 === faEyeSlash
      ? setShowPasswordIcon3(faEye)
      : setShowPasswordIcon3(faEyeSlash);
  };
  const lang = Cookies.get("i18next")
  const handleShowPassword =()=>{
    return lang==="en"?"show-password":"show-password-ar"
  } 
  const [t] = useTranslation()
  const[name,email,phone,whatsapp,city,address,role,company_name,commercial_registration,password,confirmPass,
    onChangeName,onChangeEmail,onChangePhone,onChangeWhatsapp,onChangeCity,onChangeAddress
    ,onChangeRole,onChangeCompanyName,onChange_commercial_registration,onChangePassword,onChangeConfirmPass,
    onSubmit,loading,isPress,conditions_agree,onChangeConditionAgree
  ]= PublisherSignUpHook();
  return (
    <div className="sing-up-page auth-page page">
      <CustomHelmet
      title={t("authPages.signUpPage.publisherSignUp")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <section className="section">
        <div className="auth-content">
          <div className="auth-page-title">
          <AuthTitle />
          </div>
          <div className="sign-up-card auth-card mx-auto">
            <div className="card-body">
              <h4 className="card-title my-3 fw-bold">{t("authPages.signUpPage.publisherSignUp")}</h4>
              <form onSubmit={onSubmit}>
                <div className="form-row">
                  <div className="form-group">
                    <label>{t("authPages.signUpPage.name")}</label>
                    <input
                      type="text"
                      value={name}
                      onChange={onChangeName}
                      className="custom-input"
                      placeholder={t("authPages.signUpPage.name")}
                      minLength={4}
                      maxLength={20}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>{t("authPages.signUpPage.email")}</label>
                  <input
                    type="email"
                    value={email}
                    onChange={onChangeEmail}
                    className="custom-input"
                    placeholder={t("authPages.signUpPage.email")}
                    required
                  />
                  <small className="form-text text-muted">
                  {t("authPages.signUpPage.never")}
                  </small>
                </div>
                <div className="form-row row">
                  <div className="form-group col col-12 col-md-6">
                    <label>{t("authPages.signUpPage.phone")}</label>
                    <input
                      className="custom-input"
                      value={phone}
                      onChange={onChangePhone}
                      placeholder={t("authPages.signUpPage.phone")}
                      type="tel"
                      pattern="01[0-2]\d{8,9}"
                      minLength={11}
                      maxLength={11}
                      required
                    />
                  </div>
                  <div className="form-group col col-12 col-md-6">
                    <label>{t("authPages.signUpPage.whatsapp")}</label>
                    <input
                      className="custom-input"
                      value={whatsapp}
                      placeholder={t("authPages.signUpPage.whatsapp")}
                      onChange={onChangeWhatsapp}
                      type="tel"
                      pattern="01[0-2]\d{8,9}"
                      minLength={11}
                      maxLength={11}
                      required
                    />
                  </div>
                </div>
                <div className="form-row row">
                  <div className="form-group col col-12 col-md-6">
                    <label>{t("authPages.signUpPage.address")}</label>
                    <input
                      type="text"
                      value={address}
                      onChange={onChangeAddress}
                      className="custom-input"
                      placeholder={t("authPages.signUpPage.address")}
                      minLength={4}
                      maxLength={50}
                      required
                    />
                  </div>
                  <div className="form-group col col-12 col-md-6">
                    <label>{t("authPages.signUpPage.city")}</label>
                    <input
                      type="text"
                      value={city}
                      onChange={onChangeCity}
                      className="custom-input"
                      placeholder={t("authPages.signUpPage.city")}
                      minLength={4}
                      maxLength={50}
                      required
                    />
                  </div>
                </div>
                <div className="user-type">
                  {/* <div className="form-group user-type-item">
                    <label
                      className="custom-checkbox"
                      onClick={() => setUserTabActive("unitOwner")}
                    >
                     {t("authPages.signUpPage.unitOwner")}
                      <input
                        onChange={onChangeRole}
                        value="unit_onwer"
                        name="user-type"
                        type="radio"
                        id="checkbox-unit-owner"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div> */}
                  <div className="form-group user-type-item">
                    <label
                      className="custom-checkbox"
                      onClick={() => setUserTabActive("broker")}
                    >
                       {t("authPages.signUpPage.broker")}
                      <input
                        onChange={onChangeRole}
                        value="broker"
                        name="user-type"
                        type="radio"
                        id="checkbox-broker"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-group user-type-item">
                    <label
                      className="custom-checkbox"
                      onClick={() => setUserTabActive("agency")}
                    >
                       {t("authPages.signUpPage.agency")}
                      <input
                        onChange={onChangeRole}
                        type="radio"
                        value="agency"
                        name="user-type"
                        id="checkbox-agency"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="user-type-tab-content">
                  <div
                    className={
                      userTabActive === "broker"
                        ? "form-row fade row active"
                        : "form-row row"
                    }
                  >
                    <div className="form-group col col-12">
                      <label> {t("authPages.signUpPage.companyName")}</label>
                      <input
                        type="text"
                        value={company_name}
                        onChange={onChangeCompanyName}
                        className="custom-input"
                        placeholder={t("authPages.signUpPage.companyName")}
                        minLength={3}
                      />
                    </div>
                    <div className="form-group col col-12">
                      <label>{t("authPages.signUpPage.commercial")}</label>
                      <input
                        type="text"
                        value={commercial_registration}
                        onChange={onChange_commercial_registration}
                        className="custom-input"
                        placeholder="CRN"
                        minLength={3}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      userTabActive === "agency"
                        ? "form-row fade row active"
                        : "form-row row"
                    }
                  >
                     <div className="form-group col col-12">
                      <label>{t("authPages.signUpPage.companyName")}</label>
                      <input
                        type="text"
                        value={company_name}
                        onChange={onChangeCompanyName}
                        className="custom-input"
                        placeholder={t("authPages.signUpPage.companyName")}
                        minLength={3}
                      />
                    </div>
                    <div className="form-group col col-12">
                      <label>{t("authPages.signUpPage.commercial")}</label>
                      <input
                        type="text"
                        value={commercial_registration}
                        onChange={onChange_commercial_registration}
                        className="custom-input"
                        placeholder="CRN"
                        minLength={3}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="form-group form-password col-12">
                    <label>{t("authPages.signUpPage.createPassword")}</label>
                    <span
                       className={handleShowPassword()}
                      onClick={handlePasswordState2}
                    >
                      <FontAwesomeIcon icon={showPassWordIcon2} />
                    </span>
                    <input
                      className="custom-input"
                      type={passwordState2}
                      value={password}
                      onChange={onChangePassword}
                      required
                    />
                  </div>
                  <div className="form-group form-password col-12">
                    <label>{t("authPages.signUpPage.confirmPassword")}</label>
                    <span
                       className={handleShowPassword()}
                      onClick={handlePasswordState3}
                    >
                      <FontAwesomeIcon icon={showPassWordIcon3} />
                    </span>
                    <input
                      className="custom-input"
                      type={passwordState3}
                      value={confirmPass}
                      onChange={onChangeConfirmPass}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="custom-checkbox" aria-required={true}>
                  {t("authPages.signUpPage.agree")} <a href="/">{t("authPages.signUpPage.terms")}</a>
                    <input
                      value={conditions_agree}
                      onChange={onChangeConditionAgree}
                      type="checkbox"
                      id="checkbox1"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="form-group">
                  <BtnComponent
                    loading={
                      loading === true && isPress === true ? true : false
                    }
                    value={t("authPages.signUpPage.title")}
                    type={"submit"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PublisherSignUpPage;
