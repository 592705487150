import React from "react";
import AuthTitle from "../../../components/Auth/AuthTitle/AuthTitle"
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";

const VerifyPassCodePage = () => {
  return (
    <div className="forget-pass-code-page auth-page page">
       <CustomHelmet
      title={"Verify Code"}
      />
      <section className="section">
        <div className="auth-content">
          <div className="auth-page-title">
          <AuthTitle value={"Hadayek Alahram"}/>
          </div>
          <div className="forget-pass-card auth-card ">
            <div className="card-body">
              <h4 className="card-title mb-4">Verify Code</h4>
              <form action="#">
                <div className="form-group">
                  <input
                    className="custom-input"
                    placeholder="Code"
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VerifyPassCodePage;
