import React from "react";
const UserDashboardFooter = () => {
  return (
    <div className="row row-footer user-dashboard-footer">
      <div className="col-12 col-sm-6 col-lg-7">
        <div className="copy-right h-100 d-flex align-items-center justify-content-center">
          <p className="copy__desc m-0">
            &copy; Copyright Nozoluna 2023. Made with
            <span className="la la-heart"></span> by
            <a href="/" className="company-name">
              Ostoul
            </a>
          </p>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-lg-5">
        <div className="copy-right-content d-flex align-items-center justify-content-center">
          <ul className="list-row">
            <li className="list-row-item">
              <a href="/" className="btn-social facebook">
                <i className="fa fa-facebook-f"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a href="/" className="btn-social twitter">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a href="/" className="btn-social linkedin">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a href="/" className="btn-social instagram ">
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a href="/" className="btn-social youtube">
                <i className="fa fa-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardFooter;
