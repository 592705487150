import React from "react";
import news_img from "../../../images/anonymous-image.png";
import person_img from "../../../images/anonymous.png";
import { Link, useNavigate } from "react-router-dom";
const NewsCard = () => {
    const Navigate = useNavigate()
  return (
    <div className="news-card blog__grid my-4" onClick={()=>Navigate("/news-details-page:id")}>
      <div className="card__image">
        <div className="card__image-header h-250">
          <img
            src={news_img}
            alt=""
            className="img-fluid w100 img-transition"
            loading="lazy"
          />
          <div className="info"> event</div>
        </div>
        <div className="card__image-body">
          <span className="badge badge-secondary p-1 text-capitalize mb-3">
            May 08, 2019
          </span>
          <h6 className="text-capitalize">
            <a href="/blog-single.html">Happy family with new house </a>
          </h6>
          <p className=" mb-0">
            Real estate festival is one of the famous feval for explain to you
            how all this mistaolt deand praising pain wasnad I will give
            complete
          </p>
        </div>
        <div className="card__image-footer d-flex align-items-center justify-content-between">
          <div className="d-flex ">
          <figure>
            <img
              src={person_img}
              alt=""
              className="img-fluid rounded-circle"
              loading="lazy"
            />
          </figure>
          <ul className="list-inline my-auto">
            <li className="list-inline-item">
              <a href="/">tom wilson</a>
            </li>
          </ul>
          </div>
          <ul className="list-inline my-auto ml-auto">
            <li className="list-inline-item">
              <Link to="/new-details-page:id" className="btn wd-fit d-flex flex-nowrap align-items-center">
                  <span>More</span>
                  <i className="fa fa-angle-right ml-1"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
