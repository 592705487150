import React from "react";
import "./ContactUsSidebar.css";
import { useTranslation } from "react-i18next";
import OurInfoHook from "../../../CustomHooks/OurSettings/OurInfo/OurInfoHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
const ContactUsSidebar = () => {
  const [data] = OurInfoHook();
  const [t] = useTranslation();
  return (
    //  <div className="contact-us-sidebar p-3 txt-start">
    //   <h5 className="my-3">{t("contactUsPage.contactUsSidebar.infoLocation")}</h5>
    //   <div className="wrap__contact-form-office">
    //     <ul className="list-unstyled">
    //       <li>
    //         <span className="mx-2">
    //           <i className="fa fa-home"></i>
    //         </span>
    //          <span> {data?.address || "not available"}</span>
    //       </li>
    //       <li>
    //         <span className="mx-2">
    //           <i className="fa fa-phone"></i>
    //         </span>
    //         <span><a href="tel:+20"> {data?.phone || "not available"}</a></span>
    //       </li>
    //       <li>
    //         <span className="mx-2">
    //           <i className="fa fa-envelope"></i>
    //         </span>
    //         <span> <a href="mailto:"> {data?.email || "not available"}</a></span>
    //       </li>
    //     </ul>
    //     <div className="social__media">
    //       <h5>{t("contactUsPage.contactUsSidebar.findUs")}</h5>
    //       <ul className="list-row">
    //         <li className="list-row-item">
    //           <a href= {data?.facebook || "facebook.com"} className="btn-social facebook" rel="noreferrer" target="_blank">
    //             <i className="fa fa-facebook-f"></i>
    //           </a>
    //         </li>
    //         <li className="list-row-item">
    //           <a href={data?.twitter || "twitter.com"} className="btn-social twitter" rel="noreferrer" target="_blank" >
    //             <i className="fa fa-twitter"></i>
    //           </a>
    //         </li>
    //         <li className="list-row-item">
    //           <a href={data?.linkedin || "linkedin.com"} className="btn-social linkedin" rel="noreferrer" target="_blank">
    //             <i className="fa fa-linkedin"></i>
    //           </a>
    //         </li>
    //         <li className="list-row-item">
    //           <a href={data?.instagram || "instagram.com"} className="btn-social instagram " rel="noreferrer" target="_blank">
    //             <i className="fa fa-instagram"></i>
    //           </a>
    //         </li>
    //         <li className="list-row-item">
    //           <a href={data?.youtube || "youtube.com"} className="btn-social youtube" rel="noreferrer" target="_blank">
    //             <i className="fa fa-youtube"></i>
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </div>
    <div className="contact-wrapper rounded">
      <ul>
        <li className="contact-item bg-sec-2 py-3 py-md-3 my-3 rounded text-white d-flex align-items-center">
          <span className="mx-3 hover-2 pointer flex-shrink-0 transition bg-white color-primary w-50px h-50px rounded-circle d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={faLocationDot} />
          </span>
          <div className="txt-start fs-18">
            <h4 className="m-0">
              {t("contactUsPage.contactUsSidebar.address")}
            </h4>
            <p className="m-0">{data?.address}</p>
          </div>
        </li>
        <li className="contact-item bg-sec-2 py-3 py-md-3 my-3 rounded text-white d-flex align-items-center">
          <a
            href={`mailto:${data?.email}`}
            target="_blank"
            rel="noreferrer"
            className="mx-3 hover-2 pointer transition bg-white flex-shrink-0 color-primary w-50px h-50px rounded-circle d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <div className="txt-start fs-18">
            <h4 className="m-0">{t("contactUsPage.contactUsSidebar.email")}</h4>
            <p className="m-0">{data?.email}</p>
          </div>
        </li>
        <li className="contact-item bg-sec-2 py-3 py-md-3 my-3 rounded text-white d-flex align-items-center">
          <a
            href={`tel:+20${data?.phone}`}
            target="_blank"
            rel="noreferrer"
            className="mx-3 hover-2 pointer transition flex-shrink-0 bg-white color-primary w-50px h-50px rounded-circle d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faPhone} />
          </a>
          <div className="txt-start fs-18">
            <h4 className="m-0">{t("contactUsPage.contactUsSidebar.phone")}</h4>
            <p className="m-0">{data?.phone}</p>
          </div>
        </li>
        <li className="contact-item bg-sec-2 py-3 py-md-3 my-3 rounded text-white d-flex align-items-center">
          <a
            href={`https://wa.me/+20${data?.phone}`}
            target="_blank"
            rel="noreferrer"
            className="mx-3 hover-2 pointer transition flex-shrink-0 bg-white color-primary w-50px h-50px rounded-circle d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          <div className="txt-start fs-18">
            <h4 className="m-0">
              {t("contactUsPage.contactUsSidebar.whatsapp")}
            </h4>
            <p className="m-0">{data?.phone}</p>
          </div>
        </li>
        <li className="contact-item bg-sec-2 py-3 py-md-3 my-3 rounded text-white d-flex align-items-center">
          <ul className="list-row mx-3">
            <li className="list-row-item">
              <a
                href={data?.facebook || "facebook.com"}
                className="btn-social facebook"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
            <li className="list-row-item">
              <a
                href={data?.twitter || "twitter.com"}
                className="btn-social twitter"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li className="list-row-item">
              <a
                href={data?.linkedin || "linkedin.com"}
                className="btn-social linkedin"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li className="list-row-item">
              <a
                href={data?.instagram || "instagram.com"}
                className="btn-social instagram "
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li className="list-row-item">
              <a
                href={data?.tiktok || "tiktok.com"}
                className="btn-social tiktok"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ContactUsSidebar;
