import React, { useRef, useState } from 'react';
import './MultiSelectImages.css'
import { faUpload, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MultiSelectImages = () => {
  const [selectedImages, setSelectedImages] = useState([]);
    const [ maxFiles,setMaxFiles] = useState(10)
    const fileInputRef = useRef(null);
    // when select an image
  const handleImageSelect = (event) => {
    const files = Array.from(event.target.files);
    if (files.length + selectedImages.length > maxFiles) {
      alert(`You can only upload up to ${maxFiles} files.`);
    } else {
      setSelectedImages([...selectedImages, ...files]);
    }
    fileInputRef.current.value = ''; // Reset the file input value
  };
  // when remove an image
  const handleImageRemove = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  return (
    <div>
      <h4 className='color-primary fw-bold'>Select Images</h4>
      <p className='fs-18'>
        You can upload up to
        <span className='mx-2 color-primary fw-bold'>{maxFiles}</span>
        files. Currently, you have
        <span className='mx-2 color-primary fw-bold'>{selectedImages.length}</span>
         file(s) selected.
      </p>
        <label htmlFor='fileUpload' className='pointer my-3 bg-pri p-3 rounded text-capitalize text-white text-nowrap'>
            <span className='mx-2'>choose your images</span><FontAwesomeIcon icon={faUpload}/>
        </label>
      <input
        type="file"
        ref={fileInputRef}
        id='fileUpload'
        multiple
        accept="image/*"
        onChange={handleImageSelect}
        className='mb-3 opacity-0'
        />
      <div className='selected-images border d-flex flex-wrap'>
        {selectedImages.map((image, index) => (
          <div className='selected-images-wrapper position-relative pointer m-3'
            key={index}
          >
            <img src={URL.createObjectURL(image)} alt={`img ${index}`} />
            <div className='remove-btn pointer text-white position-absolute bg-pri p-2 user-select-none'
              onClick={() => handleImageRemove(index)}
            >
              <FontAwesomeIcon icon={faX}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiSelectImages;