import React,{useState} from 'react'
import './ReadMore.css'
import DisplayWordpadContent from "../DisplayWordpadContent/DisplayWordpadContent"
const ReadMore = ({des}) => {
    const [show,setShow] = useState(false)
    return (
    <div className='read-more-less'>
        <div className={show===false?"ph transition":"show ph transition"}>
          <DisplayWordpadContent content={des} />
        <span className='shadow-span'></span>
        </div>
        {
          des?.length>500?
          <button className='show_btn' onClick={()=>setShow(!show)}>Read {show===false?"More":"Less"}</button>
          :null
        }
    </div>
  )
}

export default ReadMore
