import React from "react";
import { Link } from "react-router-dom";
const DeveloperCard = ({item}) => {
  return (
    <div className="text-white text-capitalize" >
      <Link to={`/developer-details/:${item?.id}`}>
      <img className="w-150px h-150px rounded-circle overflow-hidden" src={item?.cover} alt="dev-img" loading="lazy" />
      </Link>
      <p className="m-0 fw-bold fs-20">{item?.name}</p>
      <p className="m-0">{item?.project?.length || 0}</p>
    </div>
  );
};

export default DeveloperCard;
