import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetInstallmentUnits } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";
import { useSearchParams } from "react-router-dom";

const GetInstallmentUnitsHook = () => {
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [unitsCount, setUnitsCount] = useState(1);
  const [meta, setMeta] = useState(null);
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const res = useSelector(
    (state) => state.rootReducer.unitsReducer.installmentUnits
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const add_search_params = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const queryParams = useRef({
    category_id: searchParams.get("category_id") || "",
    filter_search: searchParams.get("filter_search") || "",
    size_min: searchParams.get("size_min") || "",
    size_max: searchParams.get("size_max") || "",
    price_min: searchParams.get("price_min") || "",
    price_max: searchParams.get("price_max") || "",
    type: searchParams.get("type") || "",
    rooms: searchParams.get("rooms") || "",
    bathrooms: searchParams.get("bathrooms") || "",
    property_id: searchParams.get("property_id") || "",
    sort: searchParams.get("sort") || "",
    district_id: searchParams.get("district_id") || "",
    gates_id: searchParams.get("gates_id") || "",
    main_category: searchParams.get("main_category") || "",
    page: searchParams.get("page") || 1,
  });

  const getQueryString = () => {
    const {
      category_id,
      filter_search,
      size_min,
      size_max,
      price_min,
      price_max,
      type,
      rooms,
      bathrooms,
      property_id,
      sort,
      main_category,
      page,
    } = queryParams.current;
    return `filter_search=${filter_search}&price_min=${price_min}&price_max=${price_max}&type=${type}
    &rooms=${rooms}&bathroom=${bathrooms}&property_id=${property_id}&category_id=${category_id}
    &sort=${sort}&main_category=${main_category}&page=${page}`;
  };
  const getData = async () => {
    setLoading(true);
    await dispatch(GetInstallmentUnits(getQueryString()));
    setLoading(false);
  };

  useEffect(() => {
    if (firstRender.current) {
      getData();
      firstRender.current = false;
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (res?.status === 200) {
        setUnits(res.data.data);
        setUnitsCount(res.data.data.length || 0);
        setMeta(res.data.meta);
      } else {
        setUnits([]);
        setUnitsCount(0);
        setMeta(null);
      }
    }
  }, [loading, res]);

  const onPressPaginate = (page) => {
    queryParams.current.page = page;
    add_search_params("page", page);
    getData();
  };

  return [loading, units, unitsCount, meta, getData, onPressPaginate];
};

export default GetInstallmentUnitsHook;
