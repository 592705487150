import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Notify from "../UseNotification/UseNotification";
import Cookies from "js-cookie";
import {logout } from "../../Redux/Redux-Toolkit/Slices/Auth/LoginSlice";

const LogoutHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.rootReducer.loginReducer.logout);
  const [loading, setLoading] = useState(true);
  // function for states
  
  // on submit fn
  const onLogout= async (e) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(logout());
    setLoading(false);
  };

  useEffect(() => {
    if (loading === false) {
      if (response) {
        console.log(response)
        if (response?.status === 200) {
            Cookies.remove("user");
            Cookies.remove("user_token");
          Notify(response?.data?.message, "success");
          setTimeout(() => {
            window.location.href = "/"
          },500);
        } else if (response?.response?.status === 400) {
          Notify(response?.response?.data?.error, "error");
        } else {
          Notify("there is a problem", "error");
        }
      }
    }
  }, [loading]);

  return [loading,onLogout];
};

export default LogoutHook;
