import React from "react";
import CompoundCard from "../CompoundCard/CompoundCard";
import NotFound from "../../Utility/NotFound/NotFound";
import CardSkeleton from "../../Utility/Cards/CardSkeleton/CardSkeleton";
import GetAllCompoundsHook from "../../../CustomHooks/Compounds/GetAllCompoundsHook";
import CustomPagination from "../../Utility/CustomPagination/CustomPagination";

const CompoundsListContent = () => {
  const unitsSkeleton = Array.from({ length: 12 }, (_, index) => index + 1);
  const [loading, compounds, compoundsCount, , onPressPaginate, meta] =
    GetAllCompoundsHook();
  return (
    <div className="compounds-list">
      <div className="row units-wrapper">
        {loading === false ? (
          compoundsCount > 0 ? (
            compounds?.map((item) => (
              <div className="col-sm-6" key={item?.id}>
                <CompoundCard item={item} />
              </div>
            ))
          ) : (
            <NotFound />
          )
        ) : (
          unitsSkeleton.map((_, i) => (
            <div className="col-sm-6" key={i}>
              <CardSkeleton />
            </div>
          ))
        )}
      </div>
      <div className="row">
        {compoundsCount > 1 && (
          <CustomPagination
            totalPages={meta?.last_page}
            currentPage={meta?.current_page}
            onChange={onPressPaginate}
          />
        )}
      </div>
    </div>
  );
};

export default CompoundsListContent;
