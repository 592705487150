import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {useGetData} from '../../../../Hooks/GetDataHook'
const initialState = {
    aboutUsData:[],
    ourMission:[],
    ourMessage:[],
    error:null,
    loading:true
}
export const getAboutUsInfo = createAsyncThunk("setting/about",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/setting_page/about',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
export const getOurMission = createAsyncThunk("setting/ourMission",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/setting_page/mission',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
export const getOurMessage = createAsyncThunk("setting/ourMessage",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/setting_page/our_message',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})

const AboutUsSlice = createSlice({
    name:"AboutUsSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(getAboutUsInfo.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(getAboutUsInfo.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.aboutUsData = action?.payload
        })
        .addCase(getAboutUsInfo.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.aboutUsData = action?.payload
        })
        // our mission
        .addCase(getOurMission.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(getOurMission.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.ourMission = action?.payload
        })
        .addCase(getOurMission.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.ourMission = action?.payload
        })
        // our mission
        .addCase(getOurMessage.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(getOurMessage.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.ourMessage = action?.payload
        })
        .addCase(getOurMessage.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.ourMessage = action?.payload
        })
    }
})
export const aboutUsReducer = AboutUsSlice.reducer
export const aboutUsActions = AboutUsSlice.actions