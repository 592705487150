import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import CompoundUnits from "./CompoundUnits/CompoundUnits";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import SlideShow from "../../../components/SingleDetails/SlideShow/SlideShow";
import CategoriesAnalysisSideBar from "../../../components/Utility/CategoriesAnalysisSideBar/CategoriesAnalysisSideBar";
import GetCompoundDetailsHook from "../../../CustomHooks/Compounds/GetCompoundDetailsHook";
import { useParams } from "react-router-dom";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import DisplayWordpadContent from "../../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import ContactButtons from "../../../components/UI/contact-btns/ContactButtons";
const CompoundsDetailsPage = () => {
  const { id } = useParams();
  const [t] = useTranslation();
  const [loading, compoundDetails] = GetCompoundDetailsHook(
    id?.slice(1, id.length)
  );
  return (
    <div className="compounds-page page">
      <CustomHelmet
        title={t("compoundDetailsPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader compoundActive={"compoundActive"} />
      <BreadcrumbSection title={t("compoundDetailsPage.title")} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 my-2">
              <div className="compound-imgs">
                {loading === false ? (
                  compoundDetails?.images?.length > 4 ? (
                    <SlideShow unitDetails={compoundDetails} />
                  ) : (
                    <img
                      style={{ maxHeight: "600px" }}
                      className="w-100 h-100"
                      src={compoundDetails?.cover_image}
                      alt="mall-img"
                      loading="lazy"
                    />
                  )
                ) : (
                  <HomeSectionSkeleton />
                )}
              </div>
            </div>
            <div className="col-lg-4 d-none d-xl-block">
              <CategoriesAnalysisSideBar />
            </div>
            <div className="col-xl-8">
              <div className="content-wrapper">
                {loading === false ? (
                  <>
                    <div className="compound-info my-3 txt-start">
                      <div className="info-head d-flex justify-content-between flex-wrap my-4">
                        <div>
                          <h3 className="fw-bold m-0">
                            {compoundDetails?.data_trans
                              ? compoundDetails?.data_trans[1].title_en
                              : "..."}
                          </h3>
                          <p>{compoundDetails?.address}</p>
                        </div>
                        <div>
                          <h3 className="fw-bold m-0">Contact info</h3>
                          <p className="m-0">
                            <span className="mx-1 color-primary ">
                              <FontAwesomeIcon icon={faPhone} />
                            </span>
                            <span>{compoundDetails?.user?.phone}</span>
                          </p>
                          <p className="m-0">
                            <span className="mx-1 color-primary">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                            <span>{compoundDetails?.user?.email}</span>
                          </p>
                        </div>
                      </div>
                      <div className="info-elements my-3 row">
                        <div className="col-md-6">
                          <ul>
                            <li className="my-2">
                              <h3 className="fw-bold color-primary text-capitalize d-inline">
                                project Name
                              </h3>
                              <p className="fs-22 d-inline mx-2">
                                <span>/</span>
                                <span>
                                  {compoundDetails?.data_trans
                                    ? compoundDetails?.data_trans[1].title_en
                                    : "..."}
                                </span>
                              </p>
                            </li>
                            <li className="my-2">
                              <h3 className="fw-bold color-primary text-capitalize d-inline">
                                developer name
                              </h3>
                              <p className="fs-22 d-inline mx-2">
                                <span>/</span>
                                <span>Elshahed company</span>
                              </p>
                            </li>
                            <li className="my-2">
                              <h3 className="fw-bold color-primary text-capitalize d-inline">
                                The district
                              </h3>
                              <p className="fs-22 d-inline mx-2">
                                <span>/</span>
                                <span>{compoundDetails?.address}</span>
                              </p>
                            </li>
                            <li className="my-2">
                              <h3 className="fw-bold color-primary text-capitalize d-inline">
                                the finishing type
                              </h3>
                              <p className="fs-22 d-inline mx-2">
                                <span>/</span>
                                <span>{compoundDetails?.finish_type}</span>
                              </p>
                            </li>
                            <li className="my-2">
                              <h3 className="fw-bold color-primary text-capitalize d-inline">
                                the areas begin from
                              </h3>
                              <p className="fs-22 d-inline mx-2">
                                <span>/</span>
                                <span>270 meter</span>
                              </p>
                            </li>
                            <li className="my-2">
                              <h3 className="fw-bold color-primary text-capitalize d-inline">
                                the price begin from
                              </h3>
                              <p className="fs-22 d-inline mx-2">
                                <span>/</span>
                                <span>{compoundDetails?.price} EGP</span>
                              </p>
                            </li>
                            <li className="my-2">
                              <h3 className="fw-bold color-primary text-capitalize d-inline">
                                the pay system
                              </h3>
                              <p className="fs-22 d-inline mx-2">
                                <span>/</span>
                                <span>{compoundDetails?.method_payment}</span>
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 contacts text-center">
                          <ContactButtons
                            whatsapp={compoundDetails?.user?.phone}
                            phone={compoundDetails?.user?.phone}
                            email={compoundDetails?.user?.email}
                          />
                        </div>
                      </div>
                      <div className="description">
                        <DisplayWordpadContent
                          content={
                            compoundDetails?.data_trans
                              ? compoundDetails?.data_trans[1].description_en
                              : "..."
                          }
                        />
                      </div>
                    </div>
                    {compoundDetails?.type_units?.length > 0 && (
                      <div className="compound-categories my-3">
                        <h3 className="fw-bold txt-start mb-3 mt-5 text-capitalize">
                          compound categories
                        </h3>
                        <div className="properties-content rounded text-white bg-sec p-3">
                          <ul className="row txt-start">
                            {compoundDetails?.type_units?.map((item, i) => {
                              return (
                                <li
                                  className="col-6 col-md-3 d-flex align-items-center fs-16 my-4"
                                  key={i}
                                >
                                  <span
                                    className="border rounded px-2 py-2 mx-2 d-flex align-items-center justify-content-center"
                                    style={{ height: "40px", width: "40px" }}
                                  >
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </span>
                                  <span>
                                    <span className="d-block fw-bold">
                                      {item?.data_trans
                                        ? item.data_trans[0]?.title_en
                                        : null}
                                    </span>
                                    {/* <span>{item?.count_unit}</span> */}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                    {compoundDetails?.facilities?.length > 0 && (
                      <div className="compound-facilities my-3">
                        <h3 className="fw-bold txt-start mb-3 mt-5 text-capitalize">
                          Features & amenities
                        </h3>
                        <div className="features-content p-3">
                          <ul className="list-unstyled icon-checkbox txt-start">
                            {compoundDetails?.facilities?.map((item, i) => {
                              return (
                                <li key={i}>
                                  <span className="mx-1 fs-16">
                                    {item?.data_trans
                                      ? item?.data_trans[0]?.title_en
                                      : null}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                    <div className="compound-pay-plans my-5">
                      <h3 className="fw-bold txt-start mb-3 mt-5 text-capitalize">
                        pay plans
                      </h3>
                      {compoundDetails?.installments?.length > 0 && (
                        <ul className="d-flex align-content-center justify-content-center">
                          {compoundDetails?.installments?.map((item, i) => {
                            return (
                              <li
                                key={i}
                                style={{ height: "250px" }}
                                className="fs-18 fw-bold bg-twitter-light wd-fit py-1 py-md-3 px-1 px-md-5 mx-1 mx-md-3 transition pointer rounded"
                              >
                                <h4 className="fs-55 fw-bold my-2 color-sec">
                                  10%
                                </h4>
                                <h5 className="fw-bold my-2 color-sec">
                                  {item?.down_payment} EG
                                </h5>
                                <p className="text-secondary">
                                  <span className="d-block my-1">مقدم</span>
                                  <span className="d-block my-1">
                                    {item?.years} سنوات
                                  </span>
                                  <span className="d-block my-1">
                                    خطة الدفع الاصليه
                                  </span>
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                    <div className="compound-units my-5">
                      <ul className="d-flex align-content-center my-5 justify-content-center">
                        <li className="fs-18 fw-bold border bg-transparent text-secondary wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap">
                          <FontAwesomeIcon icon={faArrowRight} />
                          <span className="mx-2">Commercial</span>
                        </li>
                        <li className="fs-18 fw-bold border bg-transparent  text-secondary wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap">
                          <FontAwesomeIcon icon={faArrowRight} />
                          <span className="mx-2">Rentals</span>
                        </li>
                        <li className="fs-18 fw-bold border bg-transparent  text-secondary wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap">
                          <FontAwesomeIcon icon={faArrowRight} />
                          <span className="mx-2">Sales</span>
                        </li>
                      </ul>
                      <CompoundUnits units={compoundDetails?.units} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="my-4">
                      <HomeSectionSkeleton />
                    </div>
                    <div className="my-4">
                      <HomeSectionSkeleton />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default CompoundsDetailsPage;
