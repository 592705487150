import React, { useState } from "react";
import "../Navbar.css";
import BtnComponent from "../../../Utility/BtnComponent/BtnComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../Utility/Sidebar/Sidebar";
import profile_img from "../../../../images/anonymous.png";
import img from "../../../../images/anonymous-image.png";
import { Link } from "react-router-dom";
import InternalNavbarHook from "../../../../CustomHooks/Navbar/InternalNavbarHook";
const InternalNavbar = ({
  homeActive,
  listsActive,
  aboutUsActive,
  contactUsActive,
  agencyActive,
  servicesActive,
  compoundActive,
  mallsActive,
  developersActive,
  installmentActive,
}) => {
  const [
    dataI,
    data,
    categories,
    categories_loading,
    handleCategoryClick,
    lang,
    t,
    handleClick,
    user,
  ] = InternalNavbarHook();
  const [sticky, setSticky] = useState("");
  window.onscroll = () => {
    if (window.scrollY > 50) {
      setSticky("sticky");
    } else {
      setSticky("");
    }
  };
  return (
    <nav className="internal-navbar navbar navbar-hover navbar-expand-lg navbar-soft">
      <div className={`container-fluid ${sticky}`}>
        <Link to={"/"} className="navbar-brand">
          <img
            src={dataI?.logo_web || img}
            loading="lazy"
            alt="logo"
            className="img-fluid"
          />
        </Link>
        <div className="control-btns d-flex align-items-center ">
          <div className="auth-content d-block d-sm-none">
            {typeof user === "undefined" ? (
              <div className="login-btn mx-2">
                <Link to={"/login-page"}>
                  <BtnComponent value={"login"} />
                </Link>
              </div>
            ) : (
              <div className="profile-btn profile-btn-wrapper mx-2">
                <Link to="/user">
                  <div className="img-box">
                    <img
                      className="h-100 w-100"
                      src={data?.image || profile_img}
                      alt="profile-img"
                      loading="lazy"
                    />
                  </div>
                </Link>
              </div>
            )}
          </div>
          <div
            className="toggle-btn-sidebar d-block d-lg-none"
            onClick={handleClick}
          >
            <div className="btn-box h-100 wd-fit d-flex align-items-center justify-content-center">
              <BtnComponent
                type={"button"}
                icon={<FontAwesomeIcon icon={faBarsStaggered} />}
              />
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="main_nav99">
          <ul className="navbar-nav mx-auto ">
            <li className={`nav-item ${homeActive}`}>
              <Link className="nav-link" to="/" data-toggle="dropdown">
                {t("generalSections.navBar.home")}
              </Link>
            </li>
            <li className={`nav-item ${installmentActive}`}>
              <Link className="nav-link" to="/installment">
                {t("generalSections.navBar.installment")}
              </Link>
            </li>
            {/* <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle" data-toggle="dropdown">
                {t("generalSections.navBar.sale")}
              </span>
              <ul className="dropdown-menu animate fade-up">
                {categories_loading === false
                  ? categories?.map((item, i) => {
                      return (
                        <li
                          className="dropdown-item icon-arrow"
                          key={item?.id}
                          id={item?.id}
                          onClick={(e) => handleCategoryClick(e, "sale")}
                        >
                          {lang === "ar"
                            ? item?.data_trans
                              ? item?.data_trans[0]?.title_ar
                              : null
                            : item?.data_trans
                            ? item?.data_trans[1]?.title_en
                            : null}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle" data-toggle="dropdown">
                {t("generalSections.navBar.rent")}
              </span>
              <ul className="dropdown-menu animate fade-up">
                {categories_loading === false
                  ? categories?.map((item, i) => {
                      return (
                        <li
                          className="dropdown-item icon-arrow"
                          key={item?.id}
                          id={item?.id}
                          onClick={(e) => handleCategoryClick(e, "rent")}
                        >
                          {lang === "ar"
                            ? item?.data_trans
                              ? item?.data_trans[0]?.title_ar
                              : null
                            : item?.data_trans
                            ? item?.data_trans[1]?.title_en
                            : null}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </li> */}
            <li className={`nav-item ${listsActive}`}>
              <Link className="nav-link" to="/listing" data-toggle="dropdown">
                {t("generalSections.navBar.list")}
              </Link>
            </li>
            <li className={`nav-item ${compoundActive}`}>
              <Link className="nav-link" to="/compounds">
                {t("generalSections.navBar.compounds")}
              </Link>
            </li>
            <li className={`nav-item ${mallsActive}`}>
              <Link className="nav-link" to="/malls">
                {t("generalSections.navBar.malls")}
              </Link>
            </li>
            <li className={`nav-item ${developersActive}`}>
              <Link className="nav-link" to="/developers">
                {t("generalSections.navBar.developers")}
              </Link>
            </li>
            <li className={`nav-item ${servicesActive}`}>
              <Link
                className="nav-link active"
                to="/services-category"
                data-toggle="dropdown"
              >
                {t("generalSections.navBar.services")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Sidebar />
    </nav>
  );
};

export default InternalNavbar;
