import React from "react";
import Select from "react-select";

const AgentsSidebar = () => {
  const options = [
    { value: "Property", label: "Property" },
    { value: "House", label: "House" },
    { value: "Apartment", label: "Apartment" },
    { value: "Hotel", label: "Hotel" },
    { value: "Residential", label: "Residential" },
    { value: "Land", label: "Land" },
    { value: "Luxury", label: "Luxury" },
  ];
  const cities_options = [
    { value: "Locations", label: "Locations" },
    { value: "Egypt", label: "Egypt" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "American Samoa", label: "American Samoa" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#6c757da8",
      borderRadius: "0",
      minHeight: "40px",
      height: "40px",
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };
  return (
    <div className="agents-sidebar my-3">
      <div className="products__filter mb-30">
        <div className="products__filter__group">
          <div className="products__filter__header">
            <h5 className="text-start fw-bold text-capitalize">find agents</h5>
          </div>
          <div className="products__filter__body text-start">
            <div className="form-group">
              <label className="my-2">Enter Agent name</label>
              <input
                type="text"
                className="custom-input my-2 p-2"
                placeholder="Enter agent name"
              />
            </div>
            <div className="form-group">
              <label className="my-2">All Categories</label>
              <Select
                placeholder="Property"
                defaultInputValue=""
                styles={customStyles}
                options={options}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "#fc6f2e",
                  },
                })}
              />
            </div>
            <div className="form-group">
              <label className="my-2">All Cities</label>
              <Select
                placeholder="Location"
                defaultInputValue=""
                options={cities_options}
                styles={customStyles}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "#fc6f2e",
                  },
                })}
              />
            </div>
          </div>
          <div className="products__filter__footer">
            <div className="form-group mb-0">
              <button className="btn text-capitalize btn-block">
                search agents <i className="fa fa-search ml-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <aside className=" wrapper__list__category">
        <div className="widget widget__archive">
          <div className="widget__title">
            <h5 className="text-dark mb-0 text-start">Categories Property</h5>
          </div>
          <ul className="list-unstyled">
            <li>
              <a href="/" className="text-capitalize">
                apartement
                <span className="badge badge-primary">14</span>
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                villa
                <span className="badge badge-primary">4</span>
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                family house
                <span className="badge badge-primary">2</span>
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                modern villa
                <span className="badge badge-primary">8</span>
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                town house
                <span className="badge badge-primary">10</span>
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                office
                <span className="badge badge-primary">12</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
      <div className="download mb-0">
        <h5 className="text-start text-capitalize">Property Attachments</h5>
        <div className="download__item">
          <a href="/" target="_blank">
            <i className="fa fa-file-pdf-o mr-3" aria-hidden="true"></i>
            Download Document.Pdf
          </a>
        </div>
        <div className="download__item">
          <a href="/" target="_blank">
            <i className="fa fa-file-word-o mr-3" aria-hidden="true"></i>
            Presentation 2016-17.Doc
          </a>
        </div>
      </div>
    </div>
  );
};

export default AgentsSidebar;
