import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Notify from "../../UseNotification/UseNotification";
import { contactUs } from "../../../Redux/Redux-Toolkit/Slices/OurSettings/ContactUsSlice";
import { useTranslation } from "react-i18next";

const RequestAServiceHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.rootReducer.ContactUsReducer.contactUs);
  const [name, set_name] = useState("");
  const [email, set_email] = useState("");
  const [phone, set_phone] = useState("");
  const [service, setService] = useState("");
  const [message, set_message] = useState("");
  const [loading, set_loading] = useState(true);
  const [isPress, setIsPress] = useState(false);
  const [t] = useTranslation()
  // function for states
  const onChangeName = (e) => {
    set_name(e.target.value);
  };
  const onChangeEmail = (e) => {
    set_email(e.target.value);
  };
  const onChangePhone = (e) => {
    set_phone(e.target.value);
  };
  const onChangeService = (e) => {
    setService(e.value);
  };
  
  const onChangeMessage = (e) => {
    set_message(e.target.value);
  };
  // on submit fn
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsPress(true);
    set_loading(true);
    await dispatch(
      contactUs({
                email:"none@gmail.com",
                name,
                phone,
                service,
                message
      })
    );
    set_loading(false);
    setIsPress(false);
  };
  useEffect(() => {
    if (loading === false) {
      if (response) {
        if (response?.status === 200) {
          Notify(response?.data?.message,"success");
          set_name("");
          set_phone("");
          set_email("");
          setService("");
          set_message("");
          set_email("");
        } else if (response?.status === 400) {
          Notify("there is a problem", "error");
        } else {
          Notify("there is a problem", "error");
        }
      }
    }
  }, [loading]);
  return [email,name,phone,service,message,onChangeName,onChangeEmail,onChangePhone
    ,onChangeService,onChangeMessage,onSubmit,loading,isPress,t
  ];
};

export default RequestAServiceHook;
