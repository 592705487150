import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCompounds } from "../../Redux/Redux-Toolkit/Slices/Compounds/CompoundsSlice";
import { useSearchParams } from "react-router-dom";

const GetAllCompoundsHook = () => {
  const [loading, setLoading] = useState(false);
  const [compounds, setCompounds] = useState([]);
  const [compoundsCount, setCompoundsCount] = useState(1);
  const [meta, setMeta] = useState();
  const [error, setError] = useState(null); // Add state to store error
  const res = useSelector(
    (state) => state.rootReducer.compoundsReducer.allCompounds
  );
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const add_search_params = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const queryParams = useRef({
    category_id: searchParams.get("category_id") || "",
    price_min: searchParams.get("price_min") || "",
    price_max: searchParams.get("price_max") || "",
    type: searchParams.get("type") || "",
    page: searchParams.get("page") || 1,
    search: searchParams.get("search") || "",
  });

  const getQueryString = () => {
    const {
      category_id,
      price_min,
      price_max,
      type,
      district_id,
      gates_id,
      main_category,
      page,
      search,
    } = queryParams.current;
    return `title=${search}&price_min=${price_min}&price_max=${price_max}&type=${type}&category_id=${category_id}&page=${page}`;
  };

  const getData = async () => {
    setLoading(true);
    setError(null); // Clear any existing error
    try {
      await dispatch(GetAllCompounds(getQueryString()));
    } catch (error) {
      setError(error); // Store error in state
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onPressPaginate = (page) => {
    queryParams.current.page = page;
    add_search_params("page", page);
    getData();
  };

  useEffect(() => {
    if (loading === false && res?.data?.data) {
      setCompounds(res.data.data);
      setCompoundsCount(res?.data?.data?.length || 0);
      setMeta(res?.data?.meta);
    }
  }, [loading, res]);

  return [loading, compounds, compoundsCount, error, onPressPaginate, meta];
};

export default GetAllCompoundsHook;
