import React from 'react'
import Carousel from '../../Utility/Carousel/Carousel'
import SectionTitle from '../../Utility/SectionTitle/SectionTitle'
import GetSimilarUnitsHook from '../../../CustomHooks/UnitsHook/GetSimilarUnitsHook'
import { useTranslation } from 'react-i18next'
import NotFound from "../../Utility/NotFound/NotFound"
const SimiliarProperties = ({id,price}) => {
  const [loading,units,unitsCount] = GetSimilarUnitsHook(id,price)
  const [t] = useTranslation()
  return (
    <div className="similar__item similar-properties">
   <SectionTitle title={t("singleDetailsPage.SimilarProperties.title")} des={t("singleDetailsPage.SimilarProperties.description")}/>
    <div className="Similar__property-carousel">
      {
        loading===false?
        unitsCount?
        <Carousel units={units}/>
        :<NotFound/>
        :
        null
      }
    </div>
  </div>
  )
}

export default SimiliarProperties
