import { faUpload, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';

const SingleVideoUpload = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const fileInputRef = useRef(null);

  const handleVideoSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(file);
    }
    fileInputRef.current.value = ''; // Reset the file input value
  };

  const handleVideoRemove = () => {
    setSelectedVideo(null);
  };

  return (
    <div className='my-4'>
      <h4 className='color-primary fw-bold'>Upload a Video</h4>
      <label htmlFor='videoUpload' className='pointer my-3 bg-pri p-3 rounded text-capitalize text-white text-nowrap'>
            <span className='mx-2'>choose a video</span><FontAwesomeIcon icon={faUpload}/>
        </label>
      <input
        ref={fileInputRef}
        type="file"
        accept="video/*"
        onChange={handleVideoSelect}
        className='mb-3 opacity-0'
        id='videoUpload'
      />
      {selectedVideo && (
        <div style={{ position: 'relative', margin: '10px' }}>
          <video
            src={URL.createObjectURL(selectedVideo)}
            controls
            style={{ width: '400px', height: '300px' }}
          />
          <div className='remove-btn pointer text-white position-absolute bg-pri p-2 user-select-none'
            onClick={handleVideoRemove}
          >
          <FontAwesomeIcon icon={faX}/>
          </div>
        </div>
      )}
      {selectedVideo ? (
        <p>You have selected a video file.</p>
      ) : (
        <p>No video file selected yet.</p>
      )}
    </div>
  );
};

export default SingleVideoUpload;