import React from "react";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterGroup from "../../../components/Listing/ListingSidebar/FilterGroup/FilterGroup";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import ListingSidebar from "../../../components/Listing/ListingSidebar/ListingSidebar";
import ListContent from "../../../components/Listing/ListContent/ListContent";

const InstallmentUnitsPage = () => {
  const { t } = useTranslation();
  const sidebarActive = useSelector(
    (state) => state.rootReducer.sidebarReducer.filterSidebarActive
  );
  return (
    <div className="listing-page page">
      <CustomHelmet
        title={t("installment_page.breadcrumbTitle")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader installmentActive={"installmentActive"} />
      <BreadcrumbSection title={t("installment_page.breadcrumbTitle")} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 d-none d-xl-block">
              <ListingSidebar installment={true} />
            </div>
            <div className="col-xl-9">
              <ListContent installment={true} />
            </div>
          </div>
          <div
            className={`responsive-sidebar d-xl-none ${
              sidebarActive ? "active" : ""
            }`}
          >
            <FilterGroup installment={true} />
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default InstallmentUnitsPage;
