import React from "react";
import "./NewsSidebar.css";
import news_img from "../../../images/anonymous-image.png";
import BtnComponent from '../../../components/Utility/BtnComponent/BtnComponent'
const NewsSidebar = () => {
  return (
    <div className="news-sidebar sticky-top text-start">
      <aside>
        <div className="widget__sidebar mt-0">
          <div className="widget__sidebar__header">
            <h6 className="text-capitalize">search</h6>
          </div>
          <div className="widget__sidebar__body">
            <div className="input-group row">
             <div className="input-box col-9 p-0">
             <input
                type="text"
                name="search_term_string"
                className="custom-input"
                placeholder="Search article . . ."
              />
             </div>
              <div className="btn-box col-3 p-0">
                <BtnComponent type={"submit"} icon={<i className="fa fa-search"></i>}/>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <aside>
        <div className="widget__sidebar">
          <div className="widget__sidebar__header">
            <h6 className="text-capitalize">category</h6>
          </div>
          <div className="widget__sidebar__body">
            <ul className="list-unstyled text-start">
              <li>
                <a href="#" className="text-capitalize">
                  apartement
                  <span className="badge badge-primary">14</span>
                </a>
              </li>
              <li>
                <a href="#" className="text-capitalize">
                  villa
                  <span className="badge badge-primary">4</span>
                </a>
              </li>
              <li>
                <a href="#" className="text-capitalize">
                  family house
                  <span className="badge badge-primary">2</span>
                </a>
              </li>
              <li>
                <a href="#" className="text-capitalize">
                  modern villa
                  <span className="badge badge-primary">8</span>
                </a>
              </li>
              <li>
                <a href="#" className="text-capitalize">
                  town house
                  <span className="badge badge-primary">10</span>
                </a>
              </li>
              <li>
                <a href="#" className="text-capitalize">
                  office
                  <span className="badge badge-primary">12</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </aside>
      <aside>
        <div className="widget__sidebar">
          <div className="widget__sidebar__header">
            <h6 className="text-capitalize">recents news</h6>
          </div>
          <div className="widget__sidebar__body">
            <div className="widget__sidebar__body-img">
              <img
                src={news_img}
                alt=""
                className="img-fluid rounded"
                loading="lazy"
              />
              <div className="widget__sidebar__body-heading">
                <h6 className="text-capitalize">
                  tips memilih rumah bagi kelurga muda
                </h6>
              </div>
              <span className="badge badge-secondary p-1 text-capitalize mb-1">
                May 08, 2019{" "}
              </span>
            </div>
            <div className="widget__sidebar__body-img">
              <img
                src={news_img}
                alt=""
                className="img-fluid rounded"
                loading="lazy"
              />

              <div className="widget__sidebar__body-heading">
                <h6 className="text-capitalize">
                  tips memilih rumah bagi kelurga muda
                </h6>
              </div>
              <span className="badge badge-secondary p-1 text-capitalize mb-1">
                May 08, 2019
              </span>
            </div>
            <div className="widget__sidebar__body-img">
              <img
                src={news_img}
                alt=""
                className="img-fluid rounded"
                loading="lazy"
              />

              <div className="widget__sidebar__body-heading">
                <h6 className="text-capitalize">
                  twe are experience more than 10 years
                </h6>
              </div>
              <span className="badge badge-secondary p-1 text-capitalize mb-1">
                May 08, 2019
              </span>
            </div>
            <div className="widget__sidebar__body-img">
              <img
                src={news_img}
                alt=""
                className="img-fluid rounded"
                loading="lazy"
              />

              <div className="widget__sidebar__body-heading">
                <h6 className="text-capitalize">tips & trick in real estate</h6>
              </div>
              <span className="badge badge-secondary p-1 text-capitalize mb-1">
                May 08, 2019
              </span>
            </div>

            <div className="widget__sidebar__body-img">
              <img
                src={news_img}
                alt=""
                className="img-fluid rounded"
                loading="lazy"
              />

              <div className="widget__sidebar__body-heading">
                <h6 className="text-capitalize">
                  how buy real estate dream house
                </h6>
              </div>
              <span className="badge badge-secondary p-1 text-capitalize mb-1">
                May 08, 2019
              </span>
            </div>
          </div>
        </div>
      </aside>
      <aside>
        <div className="widget__sidebar">
          <div className="widget__sidebar__header">
            <h6 className="text-capitalize">tags</h6>
          </div>
          <div className="widget__sidebar__body">
            <div className="blog__tags p-0">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a href="#">#property</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">#wfh</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">#estate</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">#real estate</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">#listing</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">#rent</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">#sell</a>
                </li>

                <li className="list-inline-item">
                  <a href="#">#family</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">#happy</a>
                </li>

                <li className="list-inline-item">
                  <a href="#">#agents</a>
                </li>

                <li className="list-inline-item">
                  <a href="#">#promo</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default NewsSidebar;
