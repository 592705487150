import React, {useState } from 'react'
import Card from "../../../components/Utility/Cards/Card/Card";
import HorizontalCard from "../../../components/Utility/Cards/HorizontalCard/HorizontalCard";
import CardSkeleton from '../../Utility/Cards/CardSkeleton/CardSkeleton';
import HorizontalCardSkeleton from '../../Utility/Cards/HorizontalCardSkeleton/HorizontalCardSkeleton';
import NotFound from '../../Utility/NotFound/NotFound';
import CustomPagination from '../../Utility/CustomPagination/CustomPagination';
import GetAllWishlistHook from '../../../CustomHooks/Wishlist/GetAllWishlistHook'
// import ListContentNav from '../ListContentNav/ListContentNav';
import GetPremiumUnitsHook from '../../../CustomHooks/UnitsHook/GetPremiumUnitsHook';
// import ListContentNav from '../../Listing/ListContentNav/ListContentNav';

const PremiumListContent = () => {
    const [active,setActive] = useState("vertical-cards");
    const [,,wishlistIds] = GetAllWishlistHook()
    const [loading, units, unitsCount, meta, ,onPressPaginate] = GetPremiumUnitsHook()
    const twelveElements = Array.from({ length: 12 }, (_, index) => index + 1);
    // const handleChangeActive = (cardStyle)=>{
    //   setActive(cardStyle)
    // }
  return (
    <div className="list-content">
      <div className="tabs__custom-v2">
      {/* <ListContentNav changeActive={handleChangeActive}  active={active}/> */}
        <div className={
            active === "vertical-cards"
              ? "tab-content active"
              : "tab-content"
          }
          id="myTabContent"
        >
          <div
            className="tab-pane fade show active my-4"
            id="pills-tab-two"
            role="tabpanel"
            aria-labelledby="pills-tab-two"
          >
            <div className="row units-wrapper">
              {
              loading===false? 
             unitsCount>0?
              units?.map((item) => (
                <div className="col-sm-6 col-lg-4" key={item?.id}>
                <Card item={item} key={item?.id} favItem={wishlistIds}/>
              </div>
              ))
              : <NotFound/>
              :
              twelveElements.map((_,i)=>{
                return(
              <div className="col-sm-6 col-lg-4" key={i}>
              <CardSkeleton />
              </div>
                )
              })
              }
            </div>
          </div>
        </div>
        <div className={
            active === "horizontal-cards"
              ? "tab-content active"
              : "tab-content"
          }
          id="myTabContent"
        >
          <div
            className="tab-pane fade show active my-4"
            id="pills-tab-two"
            role="tabpanel"
            aria-labelledby="pills-tab-two"
          >
            <div className="row units-wrapper">
              {
              loading===false?
              unitsCount>0?
              units?.map((item) => (
                <div className="col-12 my-2" key={item?.id}>
                  <HorizontalCard item={item} key={item?.id} favItem={wishlistIds} />
                </div>
              ))
              :<NotFound/>
              :
              twelveElements.map((_,i)=>{
                return(
                  <HorizontalCardSkeleton key={i}/>
                )
              })
              }
            </div>
          </div>
        </div>
        <div className='pagination-content d-flex align-items-center justify-content-center' >
         {unitsCount>18&&
           <CustomPagination
             totalPages={meta?.last_page}
             currentPage={meta?.current_page}
             onChange={onPressPaginate}
           />
         }
        </div>
      </div>
  </div>
  )
}

export default PremiumListContent
