import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {useNavigate } from "react-router-dom";
import team from '../../../../images/team.jpg'
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faTiktok, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
const TeamCard = ({item}) => {
  const Navigate = useNavigate()
  return (
    <div className="team-member-card wrap-agent radius-20x overflow-hidden">
      <div className="team-member position-relative overflow-hidden">
        <div className="team-img position-relative">
          <img
            alt="team member"
            loading="lazy"
            className="img-fluid w-100"
            src={team}
          />
        </div>
        <div className="team-hover transition d-flex align-items-center justify-content-center">
        <ul className="list-row my-1 justify-content-around align-items-center contacts">
        <li className="list-row-item my-1">
              <a
                href={`https://wa.me/+20${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social whatsapp"
              >
              <FontAwesomeIcon icon={faWhatsapp}/>
              </a>
            </li>
            <li className="list-row-item my-1">
              <a
                href={`tel:+20${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social twitter"
              >
                 <FontAwesomeIcon icon={faPhone}/>
              </a>
            </li>
            <li className="list-row-item my-1">
              <a
                href={`tel:+20${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social instagram"
              >
                 <FontAwesomeIcon icon={faInstagram}/>
              </a>
            </li>
            <li className="list-row-item my-1">
              <a
                href={`tel:+20${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social twitter"
              >
                 <FontAwesomeIcon icon={faTwitter}/>
              </a>
            </li>
            <li className="list-row-item my-1">
              <a
                href={`tel:+20${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social facebook"
              >
                 <FontAwesomeIcon icon={faFacebookF}/>
              </a>
            </li>
            <li className="list-row-item my-1">
              <a
                href={`tel:+20${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social tiktok"
              >
                 <FontAwesomeIcon icon={faTiktok}/>
              </a>
            </li>
          </ul>
        </div>
        <div className="team-title p-3 txt-start">
          <h4 className="txt-start">mohamed khaled</h4>
          <span className="txt-start">Agent Real Estate</span>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
