import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCompoundDetails } from "../../Redux/Redux-Toolkit/Slices/Compounds/CompoundsSlice";

const GetCompoundDetailsHook = (id) => {
  const [loading,setLoading] = useState(false);
  const [compoundDetails,setCompoundDetails] = useState([]);
  const res = useSelector((state) => state.rootReducer.compoundsReducer.compoundDetails);
  const dispatch = useDispatch();
  const getData =async () => {
    setLoading(true);
    await dispatch(GetCompoundDetails(id));
    setLoading(false);
  }
  useEffect(() => {
    getData(); 
  },[]);

  useEffect(() => {
    getData(); 
  },[id]);

  useEffect(() => {
    try{
      if (loading === false) {
          if(res?.status === 200){
            setCompoundDetails(res?.data?.data)
       }
      }
    }catch(e){}
  },[loading]);


  return [loading,compoundDetails];
};

export default GetCompoundDetailsHook;
