import React, { useState } from "react";
import './SingleDetailsSidebar.css'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faShare } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import ShareComponent from "../../Utility/Share/ShareComponent/ShareComponent";
import { useTranslation } from "react-i18next";
import anonymous from "../../../images/anonymous.png"
const SingleDetailsSidebar = ({item}) => {
  const [active,setActive] = useState(false)
  const handleChangeActive = ()=>{
    setActive(!active)
  }
  const [t] = useTranslation()
  return (
    <div className="single-details-sidebar my-3">
          <div className="profile__agent__body">
            <div className="agent-info d-flex align-items-center">
              <div className="agent-image mx-2">
                <img className="h-100 w-100" src={item?.user?.image || anonymous} alt="agent-img" loading="lazy"/>
              </div>
              <div className="text-capitalize txt-start">
                <h4 >{item?.user?.name || "...."}</h4>
              </div>
            </div>
           <div className="contacts my-2 row">
            <div className="whatsapp-btn-wrapper col-6 ">
              <a  href={`https://wa.me/+20${item?.user?.phone}`} className="contact-btn transition whatsapp-btn bg-whatsapp" rel="noreferrer" target="_blank">
              {t("singleDetailsPage.singleDetailsSidebar.whatsapp")} <FontAwesomeIcon icon={faWhatsapp}/>
              </a>
            </div>
            <div className="phone-btn-wrapper col-6 " onClick={() => window.location.href = `tel:${item?.phone}`}>
              <a href={`tel:+20${item?.user?.phone}`} className="contact-btn transition phone-btn bg-twitter" rel="noreferrer" target="_blank">
              {t("singleDetailsPage.singleDetailsSidebar.phone")} <FontAwesomeIcon icon={faPhone}/>
                </a>
            </div>
            <div className="email-btn-wrapper col-6 ">
              <a href={`mailto:${item?.user?.email}`} className="contact-btn transition email-btn bg-youtube" rel="noreferrer" target="_blank">
              {t("singleDetailsPage.singleDetailsSidebar.email")} <FontAwesomeIcon icon={faEnvelope}/>
                </a>
            </div>
            <div className="share-btn-wrapper col-6 ">
              <button  className="contact-btn transition share-btn bg-twitter" onClick={()=>handleChangeActive()}>
              {t("singleDetailsPage.singleDetailsSidebar.share")} <FontAwesomeIcon icon={faShare}/>
                </button>
            </div>
           </div>
          </div>
          {active&&<ShareComponent handleChange={handleChangeActive} shareUrl={window.location.href}/>}
    </div>
  );
};

export default SingleDetailsSidebar;
