import React from "react";
import UserProfileSidebar from "../../../components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../components/User/UserDashboardBread/UserDashboardBread";
import UserDashboardFooter from "../../../components/User/UserDashboardFooter/UserDashboardFooter";
import Card from "../../../components/Utility/Cards/Card/Card";
import Cookies from "js-cookie"
import UserProfileHook from "../../../CustomHooks/User/UserProfileHook";
import NotFound from "../../../components/Utility/NotFound/NotFound"
import CardSkeleton from "../../../components/Utility/Cards/CardSkeleton/CardSkeleton";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const UserListPage = () => {
  const lang = Cookies.get("i18next")
  const [user,loading,data,t] = UserProfileHook()
  return (
    <div className="user-wishlist-page user-page page">
      <CustomHelmet
      title={t("user.userListPage.title")}
      />
      <div className={`d-none d-lg-block ${lang==="en"?"user-sidebar-wrap":"user-sidebar-wrap-ar"}`}>
        <UserProfileSidebar listPage={"listPage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav listPageActive={"page-active"} />
        <UserDashboardBread section_title={t("user.userListPage.title")} />
        <div className="dashboard-main-content">
            <div className="row-content">
                <div className="form-box">
                  <div className="form-title-wrap">
                    <h3 className="title">{t("user.userListPage.title")}</h3>
                    {t("user.userListPage.results")}
                  </div>
                  <div className="form-content pt-5 pb-0 min-h-100">
                    <div className="row">
                      {
                        loading===false?
                        data?.list?.length>0?
                          data?.list?.map((item)=>{return(
                            <div className="col-12 col-sm-6 col-xl-4" key={item?.id}>
                            <Card item={item}/>
                          </div>
                          )})
                          :<NotFound/>
                        :
                        <>
                        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                          <CardSkeleton />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                          <CardSkeleton />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                          <CardSkeleton />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                          <CardSkeleton />
                        </div>
                      </>
                      }
                    </div>
                  </div>
                </div>
            </div>
              <UserDashboardFooter />
        </div>
      </div>
    </div>
  );
};

export default UserListPage;
