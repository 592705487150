import React from "react";
import { useTranslation } from "react-i18next";

const SingleDetailAmenities = ({unitDetails}) => {
  const [t] = useTranslation()
  return (
    <div className="single__detail-features single-detail-amenities">
      <h5 className="text-capitalize detail-heading fw-bold color-primary fs-22">{t("singleDetailsPage.singleDetailsFacilities.title")}</h5>
      <ul className="list-unstyled icon-checkbox">
        {
          unitDetails?.property?.map((item,i)=>{return(
            <li key={i}>
              <span className="mx-1">{item?.data_trans?item?.data_trans[0]?.title_en:null}</span>
            </li>
          )})
        }
      </ul>
    </div>
  );
};

export default SingleDetailAmenities;
