import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {getOurMission } from "../../../Redux/Redux-Toolkit/Slices/OurSettings/AboutUsSlice";

const OurMissionHook = () => {
  const dispatch = useDispatch();
  const res = useSelector((state) => state.rootReducer.aboutUsReducer.ourMission);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // function for states
  const getData = async()=>{
        setLoading(true)
        await dispatch(getOurMission())
        setLoading(false)
  }
  useEffect(()=>{
    const cachedData = sessionStorage.getItem('ourMission');
    if (cachedData) {
      setData(JSON.parse(cachedData));
    } else {
      getData();
    }
  },[])
  useEffect(()=>{
    if(loading===false){
        if(res?.data?.data){
            setData(res.data.data)
            sessionStorage.setItem('ourMission',JSON.stringify(res.data.data))
        }
    }
  },[loading,res])
  return [data,loading]
};

export default OurMissionHook;
