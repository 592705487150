import React from "react";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import AboutUsHook from "../../CustomHooks/OurSettings/AboutUs/AboutUsHook";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
import img from "../../images/anonymous-image.png"
import DisplayWordPadContent from "../../components/Utility/DisplayWordpadContent/DisplayWordpadContent"
import WantToSell from "../../components/Home/WantToSell/WantToSell"
import OurMessageHook from "../../CustomHooks/OurSettings/AboutUs/OurMessageHook";
import OurMissionHook from "../../CustomHooks/OurSettings/AboutUs/OurMissionHook";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const AboutUsPage = () => {
  const [data,loading] = AboutUsHook();
  const [ourMessageData,ourMessageLoading] = OurMessageHook();
  const [ourMissionData,ourMissionLoading] = OurMissionHook();
  const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="about-us-page page">
      <CustomHelmet
      title={t("aboutUsPage.title")} 
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader aboutUsActive={"aboutUsActive"} />
      <BreadcrumbSection title={t("aboutUsPage.title")} />
      <section className="home__about bg-light">
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-8">
              <div className="title__leading txt-start">
                {
                loading === false ? (
                  data ? (
                    <>
                      <h2 className="text-capitalize txt-start">
                        {
                          lang==="en"?
                          data?.data_trans
                          ? data?.data_trans[1]?.title_en
                          : null
                          :
                          data?.data_trans
                          ? data?.data_trans[0]?.title_ar
                          : null

                        }
                      </h2>
                        {lang==="en"?
                        <DisplayWordPadContent content={data?.data_trans?data?.data_trans[1]?.description_en: null}/>
                        :
                        <DisplayWordPadContent content={data?.data_trans?data?.data_trans[0]?.description_ar: null}/>
                        }
                    </>
                  ) : null
                ) : (
                  <>
                    <Skeleton height={40} />
                    <Skeleton height={40} sx={{ margin: "5px 0" }} />
                    <Skeleton height={200} />
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="about__image">
              {loading === false ? (
                      data ? (
                        <img
                          src={data?.image || img}
                          alt="the company img"
                          className="img-fluid w-100 h-100"
                          loading="lazy"
                        />
                      ) : null
                    ) : null}
              </div>
            </div>
          </div>
          <div className="row my-5">
          <div className="col-lg-4">
              <div className="about__image">
              {ourMessageLoading === false ? (
                      ourMessageData ? (
                        <img
                          src={ourMessageData?.image || img}
                          alt="the company img"
                          className="img-fluid w-100 h-100"
                          loading="lazy"
                        />
                      ) : null
                    ) : null}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="title__leading txt-start">
                {
                loading === false ? (
                  data ? (
                    <>
                      <h2 className="text-capitalize">
                        {
                          lang==="en"?
                          ourMessageData?.data_trans
                          ? ourMessageData?.data_trans[1]?.title_en
                          : null
                          :
                          ourMessageData?.data_trans
                          ? ourMessageData?.data_trans[0]?.title_ar
                          : null

                        }
                      </h2>
                        {lang==="en"?
                        <DisplayWordPadContent content={ourMessageData?.data_trans?ourMessageData?.data_trans[1]?.description_en: null}/>
                        :
                        <DisplayWordPadContent content={ourMessageData?.data_trans?ourMessageData?.data_trans[0]?.description_ar: null}/>
                        }
                    </>
                  ) : null
                ) : (
                  <>
                    <Skeleton height={40} />
                    <Skeleton height={40} sx={{ margin: "5px 0" }} />
                    <Skeleton height={200} />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-lg-8">
              <div className="title__leading txt-start">
                {
                ourMissionLoading === false ? (
                  ourMissionData ? (
                    <>
                      <h2 className="text-capitalize">
                        {
                          lang==="en"?
                          ourMissionData?.data_trans
                          ? ourMissionData?.data_trans[1]?.title_en
                          : null
                          :
                          ourMissionData?.data_trans
                          ? ourMissionData?.data_trans[0]?.title_ar
                          : null

                        }
                      </h2>
                        {lang==="en"?
                        <DisplayWordPadContent content={ourMissionData?.data_trans?ourMissionData?.data_trans[1]?.description_en: null}/>
                        :
                        <DisplayWordPadContent content={ourMissionData?.data_trans?ourMissionData?.data_trans[0]?.description_ar: null}/>
                        }
                    </>
                  ) : null
                ) : (
                  <>
                    <Skeleton height={40} />
                    <Skeleton height={40} sx={{ margin: "5px 0" }} />
                    <Skeleton height={200} />
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="about__image">
              {ourMissionLoading === false ? (
                      ourMissionData ? (
                        <img
                          src={ourMissionData?.image || img}
                          alt="the company img"
                          className="img-fluid w-100 h-100"
                          loading="lazy"
                        />
                      ) : null
                    ) : null}
              </div>
            </div>
          </div>
        </div>
          <WantToSell />
      </section>
    </div>
  );
};

export default AboutUsPage;
