import React from "react";
import "./ListingSidebar.css";
import ListingSidebarHook from "../../../CustomHooks/Listing/ListingSidebarHook/ListingSidebarHook";
import CategoriesAnalysis from "./CategoriesAnalysis/CategoriesAnalysis";
import Share from "./Share/Share";
import FilterGroup from "./FilterGroup/FilterGroup";
const ListingSidebar = ({ installment }) => {
  const [
    t,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    onChangeCategoryId,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    lang,
    ,
    handleChangeActive,
    active,
    categories_loading,
    categories,
    ,
    ,
    ,
    ,
  ] = ListingSidebarHook(installment);

  return (
    <div className="listing-sidebar txt-start rounded">
      <div className="products__filter mb-30">
        <FilterGroup installment={installment} />
      </div>
      <div className="wrapper__list__category my-5">
        {!installment && (
          <CategoriesAnalysis
            categories_loading={categories_loading}
            categories={categories}
            onChangeCategoryId={onChangeCategoryId}
            lang={lang}
            t={t}
          />
        )}
      </div>
      <Share handleChangeActive={handleChangeActive} active={active} t={t} />
    </div>
  );
};

export default ListingSidebar;
