import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetMallDetails } from "../../Redux/Redux-Toolkit/Slices/Malls/MallsSlice";

const GetMallDetailsHook = (id) => {
  const [loading, setLoading] = useState(false);
  const [mallDetails, setMallDetails] = useState([]);
  const res = useSelector(
    (state) => state.rootReducer.MallsReducer.mallDetails
  );
  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);
    await dispatch(GetMallDetails(id));
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    try {
      if (loading === false) {
        if (res?.status === 200) {
          setMallDetails(res?.data?.data);
        }
      }
    } catch (e) {}
  }, [loading]);

  return [loading, mallDetails];
};

export default GetMallDetailsHook;
