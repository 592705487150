import React from 'react'
import ShareComponent from '../../../Utility/Share/ShareComponent/ShareComponent'
import BtnComponent from '../../../Utility/BtnComponent/BtnComponent'

const Share = ({handleChangeActive,active,t}) => {
  return (
    <div className='share-btn fw-bold'>
      <div className="share download mb-0">
        <div className='btn-box'  onClick={() => handleChangeActive()}>
          <BtnComponent value={t("listingPage.listingSidebar.share")}
          icon={ <i className="fa fa-share mr-3 mx-2" aria-hidden="true"></i>}/>
        </div>
      </div>
      {active && (
        <ShareComponent
          handleChange={handleChangeActive}
          shareUrl={window.location.href}
        />
      )}
    </div>
  )
}

export default Share 
