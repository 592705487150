import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";
const initialState = {
    allGates:[],
    error:null,
    loading:true
}
export const GetAllGates = createAsyncThunk("allGates",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/gates')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
const GatesSlice = createSlice({
    name:"GatesSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllGates.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllGates.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allGates =action?.payload?.data
        })
        .addCase(GetAllGates.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.allGates =action?.payload?.data
        })
        
    }
})
export const gatesReducer = GatesSlice.reducer
export const gatesActions = GatesSlice.actions