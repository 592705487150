import React from "react";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const ProjectSidebar = () => {
  const [t] = useTranslation();
  return (
    <div className="agency-sidebar">
      <div className="products__filter mb-30">
        <div className="products__filter__group">
          <div className="products__filter__header">
            <h5 className="fw-bold text-capitalize">
              {t("projectDetailsPage.sidebar.contactForm")}
            </h5>
          </div>
          <div className="products__filter__body">
            <div className="form-group">
              <label> {t("projectDetailsPage.sidebar.fullName")}</label>
              <input type="text" className="custom-input" />
            </div>
            <div className="form-group">
              <label>
                {" "}
                {t("projectDetailsPage.sidebar.email")}({" "}
                {t("projectDetailsPage.sidebar.optional")})
              </label>
              <input type="email" className="custom-input" />
            </div>
            <div className="form-group">
              <label> {t("projectDetailsPage.sidebar.phone")}</label>
              <input type="text" className="custom-input" />
            </div>

            <div className="form-group">
              <label> {t("projectDetailsPage.sidebar.yourMessage")}</label>
              <textarea className="custom-input" rows="3"></textarea>
            </div>
          </div>
          <div className="products__filter__footer">
            <div className="form-group mb-0">
              <div className="btn-box">
                <BtnComponent
                  value={t("projectDetailsPage.sidebar.submit")}
                  type={"submit"}
                  icon={<FontAwesomeIcon icon={faPaperPlane}/>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSidebar;
