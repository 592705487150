import { useState } from "react";
import GetAllCategoriesHook from "../../CustomHooks/Categories/GetAllCategoriesHook";
import FilterHook from "../../CustomHooks/FiltrationHooks/FilterHook";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import FormatNumberHook from "../FormatNumber/FormatNumberHook";
const SearchBoxHook = () => {
  const [active, setActive] = useState("buy");
  const [categories_loading, categories] = GetAllCategoriesHook();
  const [formatNumber] = FormatNumberHook();
  // start for range slider
  const [value, setValue] = useState([0, 1000]);
  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  const handleChangeActive = (type) => {
    setActive(type);
  };
  const roomsList = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
  ];
  const bathRoomsList = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
  ];
  const categoryList = [
    categories_loading === false
      ? categories
        ? categories?.map((item, i) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : null
              }`,
              label: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : null
              }`,
            };
          })
        : null
      : null,
  ];
  // start add the all option to the lists
  categoryList[0]?.unshift({
    id: "0",
    value: "0",
    label: t("listingPage.listingSidebar.all"),
  });
  // End add the all option to the lists
  let categoryValueResult = "";
  const getCategoryValue = () => {
    let result1 = categoryList
      ? categoryList[0]?.filter((item) => {
          return item?.id === localStorage.getItem("category_id");
        })
      : null;
    categoryValueResult = result1 ? result1[0]?.value : "";
  };
  getCategoryValue();
  const [
    minSpace,
    maxSpace,
    rooms,
    bathrooms,
    type,
    searchWord,
    property_id,
    category_id,
    minPrice,
    maxPrice,
    onChangeMinSpace,
    onChangeMaxSpace,
    onChangeBathrooms,
    onChangeType,
    onChangeSearchWord,
    onChangeRooms,
    onChangePropertyId,
    onChangeCategoryId,
    onChangeMaxPrice,
    onChangeMinPrice,
    onSearch,
    onSearchSidebar,
    Reset,
    sort,
    onChangeSort,
    onChangeCode,
    code,
    onChangeMainCategory,
    gateId,
    onChangeGateId,
    onChangeFinishing,
    onChangeFurnished,
    onChangeDistrictId,
  ] = FilterHook();

  return [
    categoryList,
    active,
    roomsList,
    bathRoomsList,
    categoryValueResult,
    onChangeBathrooms,
    onChangeType,
    onChangeRooms,
    onChangeCategoryId,
    onSearch,
    Reset,
    code,
    handleChangeActive,
    t,
    minPrice,
    maxPrice,
    value,
    handleChange,
    formatNumber,
    onChangeGateId,
    onChangeDistrictId,
    searchWord,
    onChangeSearchWord,
  ];
};

export default SearchBoxHook;
