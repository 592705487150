import React from "react";
import DevelopersCarousel from "./DevelopersCarousel.js/DevelopersCarousel";
import GetAllDevelopersHook from "../../../CustomHooks/Developers/GetAllDevelopersHook";
import NotFound from "../../Utility/NotFound/NotFound";

const Developers = () => {
  const [loading, developers,developersCount,,]= GetAllDevelopersHook()
  return (
    <section className="categories-analysis bg-sec-2 py-5 px-4">
        <div className="analysis-wrapper d-flex flex-wrap flex-sm-nowrap justify-content-around align-content-center">
          {
            developersCount>0?
            <DevelopersCarousel items={developers} loading={loading}/>
            :<NotFound/>
          }
        </div>
    </section>
  );
};

export default Developers; 
