import { useEffect, useState } from "react";
import {
  GetAllUnits,
  GetInstallmentUnits,
} from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";
import { useDispatch } from "react-redux";
import { changeFilterSidebarActive } from "../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import { useNavigate, useSearchParams } from "react-router-dom";

const FilterHook = (installment) => {
  const [searchWord, setSearchWord] = useState("");
  const [code, setCode] = useState("");
  const [sort, setSort] = useState("");
  const [minSpace, setMinSpace] = useState("");
  const [maxSpace, setMaxSpace] = useState("");
  const [rooms, setRooms] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [type, setType] = useState("");
  const [property_id, set_property_id] = useState("");
  const [category_id, set_category_id] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [finishing, setFinishing] = useState("");
  const [furnished, setFurnished] = useState("");
  const [paying, set_paying] = useState("");
  const [sale_status, set_sale_status] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryUrl = searchParams.get("category") || "";
  const searchWordUrl = searchParams.get("filter_search") || "";
  const minSpaceUrl = searchParams.get("size_min") || "";
  const maxSpaceUrl = searchParams.get("size_max") || "";
  const typeUrl = searchParams.get("type") || "";
  const roomsUrl = searchParams.get("rooms") || "";
  const bathroomsUrl = searchParams.get("bathrooms") || "";
  const propertyIdUrl = searchParams.get("property_id") || "";
  const sortUrl = searchParams.get("sort") || "";
  const mainCategoryUrl = searchParams.get("main_category") || "";
  const finishingUrl = searchParams.get("finishing") || "";
  const furnishedUrl = searchParams.get("furnished") || "";
  const min_price_url = searchParams.get("min_price") || "";
  const max_price_url = searchParams.get("max_price") || "";
  const paying_url = searchParams.get("paying") || "";
  const sale_status_url = searchParams.get("sale_status") || "";
  const page_url = searchParams.get("page") || 1;

  // set search params function
  const add_search_params = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };
  // get the data from the server

  const getFilterData = async (queryString) => {
    await dispatch(
      installment ? GetInstallmentUnits(queryString) : GetAllUnits(queryString)
    );
  };

  // when the user click to search of specific unit
  const handleChangeFilterSidebarActive = () => {
    dispatch(changeFilterSidebarActive());
  };

  // on search general
  const onSearch = (e) => {
    e.preventDefault();
    Navigate(`/listing?filter_search=${searchWord}&price_min=${minPrice}&price_max=${maxPrice}
      &size_min=${minSpace}&size_max=${maxSpace}&type=${type}&rooms=${rooms}&bathroom=${bathrooms}&property_id=${property_id}
      &category_id=${category_id}&sort=${sort}&code=${code}&main_category=${mainCategory}&paying=${paying}&sale_status=${sale_status}`);
  };

  // search btn in the sidebar
  const onSearchSidebar = () => {
    handleChangeFilterSidebarActive();
    getFilterData(`filter_search=${searchWord}&price_min=${minPrice}&price_max=${maxPrice}
      &size_min=${minSpace}&size_max=${maxSpace}&type=${type}&rooms=${rooms}&bathroom=${bathrooms}&property_id=${property_id}
      &category_id=${category_id}&sort=${sort}&Furnished=${furnished}&Finishing_type=${finishing}
      &paying=${paying}&sale_status=${sale_status}`);
  };

  // function to reset the filter values
  const Reset = () => {
    setSearchWord("");
    setMinPrice("");
    setMaxPrice("");
    setMinSpace("");
    setMaxSpace("");
    setType("");
    setRooms("");
    setBathrooms("");
    set_property_id("");
    set_category_id("");
    setSort("");
    setFurnished("");
    setFinishing("");
    set_paying("");
    set_sale_status("");
    getFilterData();
    setSearchParams({});
    Navigate("/listing");
  };

  // get the data depends on the url params
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    const commonQueryString = `filter_search=${searchWordUrl}&type=${typeUrl}&rooms=${roomsUrl}&bathroom=${bathroomsUrl}
      &property_id=${propertyIdUrl}&category_id=${categoryUrl}&sort=${sortUrl}
      &main_category=${mainCategoryUrl}&sale_status=${sale_status_url}&Furnished=${furnishedUrl}&
        Finishing_type=${finishingUrl}&size_min=${minSpaceUrl}&size_max=${maxSpaceUrl}&page=${page_url}`;

    const additionalQueryString = installment ? "" : `&paying=${paying_url}`;

    getFilterData(commonQueryString + additionalQueryString);
  }, [
    searchWordUrl,
    typeUrl,
    roomsUrl,
    bathroomsUrl,
    propertyIdUrl,
    categoryUrl,
    sortUrl,
    mainCategoryUrl,
    paying_url,
    sale_status_url,
    finishingUrl,
    furnishedUrl,
    maxSpaceUrl,
    minSpaceUrl,
  ]);

  // functions that handle various user interactions and updates the corresponding values in the browser's local storage.
  const onChangeCode = (e) => {
    setCode(e.target.value);
    add_search_params("code", e.target.value);
  };
  const onChangeSearchWord = (e) => {
    setSearchWord(e.target.value);
    add_search_params("filter_search", e.target.value);
  };
  const onChangeMinPrice = (e) => {
    setMinPrice(e);
    add_search_params("min_price", e);
  };
  const onChangeMaxPrice = (e) => {
    setMaxPrice(e);
    add_search_params("max_price", e);
  };
  const onChangeSort = (e) => {
    setSort(e.value);
    add_search_params("sort", e.value);
  };
  const onChangeType = (e, val) => {
    if (e.target) {
      if (e.target.value === 0) {
        setType(val);
        add_search_params("type", val);
      } else {
        setType(e.target.value);
        add_search_params("type", e.target.value);
      }
    } else {
      setType(e.value);
      add_search_params("type", e.value);
    }
  };
  const onChangeMinSpace = (e) => {
    setMinSpace(e.target.value);
    add_search_params("min_space", e.target.value);
  };
  const onChangeMaxSpace = (e) => {
    setMaxSpace(e.target.value);
    add_search_params("max_space", e.target.value);
  };
  const onChangeBathrooms = (e) => {
    setBathrooms(e.value);
    add_search_params("bathrooms", e.value);
  };
  const onChangeRooms = (e) => {
    setRooms(e.value);
    add_search_params("rooms", e.value);
  };
  const onChangePropertyId = (e) => {
    if (e.target.checked === true) {
      set_property_id(e.target.id);
      add_search_params("property_id", e.target.value);
    } else {
      set_property_id("");
      add_search_params("property_id", "");
    }
  };
  const onChangeFinishing = (e) => {
    if (e.target.checked === true) {
      setFinishing(e.target.value);
      add_search_params("finishing", e.target.value);
    } else {
      setFinishing("");
      add_search_params("finishing", "");
    }
  };
  const onChangeFurnished = (e) => {
    if (e.target.checked === true) {
      setFurnished(e.target.value);
      add_search_params("furnished", e.target.value);
    } else {
      setFurnished("");
      add_search_params("furnished", e.target.value);
    }
  };
  const onChangeCategoryId = (e) => {
    if (e.target) {
      set_category_id(e.target.id);
      add_search_params("category", e.target.id);
    } else {
      set_category_id(e.id);
      add_search_params("category", e.id);
    }
  };
  const onChangeMainCategory = (e) => {
    if (e.target) {
      setMainCategory(e.target.id);
      add_search_params("main_category", e.target.id);
    } else {
      setMainCategory(e.id);
      add_search_params("main_category", e.id);
    }
  };
  const onChangePaying = (e) => {
    if (e.target) {
      set_paying(e.target.value);
      add_search_params("paying", e.target.value);
    } else {
      set_paying(e.value);
      add_search_params("paying", e.value);
    }
  };
  const onChangeSaleStatus = (e) => {
    if (e.target) {
      set_sale_status(e.target.id);
      add_search_params("sale_status", e.target.id);
    } else {
      set_sale_status(e.value);
      add_search_params("sale_status", e.value);
    }
  };
  return [
    minSpace,
    maxSpace,
    rooms,
    bathrooms,
    type,
    searchWord,
    property_id,
    category_id,
    minPrice,
    maxPrice,
    onChangeMinSpace,
    onChangeMaxSpace,
    onChangeBathrooms,
    onChangeType,
    onChangeSearchWord,
    onChangeRooms,
    onChangePropertyId,
    onChangeCategoryId,
    onChangeMaxPrice,
    onChangeMinPrice,
    onSearch,
    onSearchSidebar,
    Reset,
    sort,
    onChangeSort,
    onChangeCode,
    code,
    onChangeMainCategory,
    onChangeFinishing,
    onChangeFurnished,
    paying,
    onChangePaying,
    sale_status,
    onChangeSaleStatus,
  ];
};

export default FilterHook;
