import React from "react";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserPassHook from "../../../CustomHooks/User/UserPassHook";
const UserSettingPassSection = () => {
  const [password,current_password,password_confirmation,
    onChange_password,onChange_current_pass,onChange_password_confirm,loading,isPress,
    onSubmit,t,handleShowPassword,handlePasswordState1,handlePasswordState2,
    handlePasswordState3,showPassWordIcon1,passwordState1,showPassWordIcon2,passwordState2,
  showPassWordIcon3,passwordState3] 
    = UserPassHook()
  return (
    <div className="user-setting-pass-section my-3">
      <div className="change-pass">
        <div className="form-box">
          <div className="form-title-wrap">
            <h3 className="title">{t("user.userSettingPage.userSettingPassSection.changePassword")}</h3>
          </div>
          <div className="form-content">
            <div className="contact-form-action">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="input-box">
                    <label className="label-text">{t("user.userSettingPage.userSettingPassSection.currentPassword")}</label>
                      <div className="form-group">
                        <FontAwesomeIcon icon={faLock} />
                        <span
                          className={handleShowPassword()}
                          onClick={handlePasswordState1}
                        >
                          <FontAwesomeIcon icon={showPassWordIcon1} />
                        </span>
                        <input
                        value={current_password}
                        onChange={onChange_current_pass}
                          name="current-pass"
                          className="custom-input"
                          type={passwordState1}
                          placeholder={t("user.userSettingPage.userSettingPassSection.currentPassword")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-box">
                      <label className="label-text">{t("user.userSettingPage.userSettingPassSection.newPassword")}</label>
                      <div className="form-group">
                        <FontAwesomeIcon icon={faLock} />
                        <span
                          className={handleShowPassword()}
                          onClick={handlePasswordState2}
                        >
                          <FontAwesomeIcon icon={showPassWordIcon2} />
                        </span>
                        <input
                        value={password}
                        onChange={onChange_password}
                          name="new-pass"
                          className="custom-input"
                          type={passwordState2}
                          placeholder={t("user.userSettingPage.userSettingPassSection.newPassword")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-box">
                      <label className="label-text">{t("user.userSettingPage.userSettingPassSection.confirmPassword")}</label>
                      <div className="form-group">
                        <FontAwesomeIcon icon={faLock} />
                        <span
                           className={handleShowPassword()}
                          onClick={handlePasswordState3}
                        >
                          <FontAwesomeIcon icon={showPassWordIcon3} />
                        </span>
                        <input
                        value={password_confirmation}
                        onChange={onChange_password_confirm}
                          name="confirm-new-pass"
                          className="custom-input"
                          type={passwordState3}
                          placeholder={t("user.userSettingPage.userSettingPassSection.confirmPassword")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-12 col-md-4">
                    <div className="btn-box">
                      <BtnComponent
                      loading={isPress===true && loading===true? true:false}
                      type={"submit"} value={t("user.userSettingPage.userSettingPassSection.changePassword")} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettingPassSection;
