import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {notificationsCount } from "../../Redux/Redux-Toolkit/Slices/User/UserNotificationSlice";
const GetNotificationsCountHook = () => {
    const [loading,setLoading] = useState(true);
    const [count,setCount] = useState(0);
    const res = useSelector((state) => state.rootReducer.userNotificationReducer.notificationsCount);
    const dispatch = useDispatch();
  
    const getData =async () => {
      setLoading(true);
      await dispatch(notificationsCount());
      setLoading(false);
    }
    useEffect(() => {
      getData();
    },[]);
  
    useEffect(() => {
      try{
        if (loading === false) {
          if(res?.status === 200){
            setCount(res?.unread_count)
          }else{
            setCount(0)
          }
        }
      }catch(e){}
    },[loading,res]);
  
  
    return [loading,count]
}

export default GetNotificationsCountHook
