import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { usePostData, usePostDataToken } from "../../../../Hooks/PostDataHook";

const initialState = {
    login:[],
    logout:[],
    error:null,
    loading:true,
}
export const login = createAsyncThunk("login",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostData('/login',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
export const logout = createAsyncThunk("logout",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostDataToken('/logout',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})

const LoginSlice = createSlice({
    name:"LoginSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(login.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(login.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.login = action?.payload
        })
        .addCase(login.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.login = action?.payload
        })
        // logout
        .addCase(logout.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(logout.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.logout = action?.payload
        })
        .addCase(logout.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.logout = action?.payload
        })
    }
})
export const loginReducer = LoginSlice.reducer
export const LoginActions = LoginSlice.actions