import React from 'react'
import './OurTeam.css'
import SectionTitle from '../../Utility/SectionTitle/SectionTitle'
import TeamCarousel from './TeamCarousel/TeamCarousel'
const OurTeam = () => {
  return (
    <section className="our__team">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
            <SectionTitle title={"meet our team"} des={"see our top of the month teams"}/>
            </div>
        </div>
        <div className="row p-3">
            <TeamCarousel/>
        </div>
    </div>
</section>
  )
}

export default OurTeam
