import React from "react";
import NewsCard from "../../../components/News/NewsCard/NewsCard";
// import news_img from '../../../images/anonymous-image.png'
import NewsSidebar from "../../../components/News/NewsSidebar/NewsSidebar";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import { useTranslation } from "react-i18next";
const NewsPage = () => {
  const [t] = useTranslation()
  return (
    <div className="news-page page">
      <CustomHelmet
      title={"News"}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader breadcrumbTitle={"News"} newsActive={"newsActive"}/>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 ">
                  <NewsCard/>
                </div>
                <div className="col-12 col-md-6 col-lg-6 ">
                <NewsCard/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <NewsCard/>
                </div>
                <div className="col-md-6 col-lg-6 ">
                <NewsCard/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <NewsCard/>
                </div>
                <div className="col-md-6 col-lg-6 ">
                <NewsCard/>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
             <NewsSidebar />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewsPage;
