import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
    allServicesCategory:[],
    allServiceList:[],
    serviceDetails:[],
    error:null,
    loading:true
}
export const GetAllServicesCategory = createAsyncThunk("allServicesCategory",async(page,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/category_service?per_page=${12}&page=${page}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const GetAllServiceList = createAsyncThunk("allServiceList",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/category_service/${id}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const GetServiceDetails = createAsyncThunk("serviceDetails",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/services/${id}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

const ServicesSlice = createSlice({
    name:"ServicesSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllServicesCategory.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllServicesCategory.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allServicesCategory =action?.payload?.data
        })
        .addCase(GetAllServicesCategory.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.allServicesCategory =action?.payload?.data
        })
        // get all service list
        .addCase(GetAllServiceList.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllServiceList.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allServiceList =action?.payload?.data
        })
        .addCase(GetAllServiceList.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.allServiceList =action?.payload?.data
        })
        // service details
        .addCase(GetServiceDetails.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetServiceDetails.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.serviceDetails =action?.payload
        })
        .addCase(GetServiceDetails.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.serviceDetails =action?.payload
        })
    }
})
export const servicesReducer = ServicesSlice.reducer
export const servicesActions = ServicesSlice.actions