import React from "react";
import "./DeveloperCard.css";
// import Cookies from "js-cookie"

const DeveloperCard = ({ item }) => {
  // const lang = Cookies.get("i18next")
  return (
    <div className="developer-card city-card radius-5 overflow-hidden card__image-hover-style-v3 m-1 pointer">
      <div className="gate-img card__image-hover-style-v3-thumb h-230">
        <img
          loading="lazy"
          src={item?.cover}
          alt="developer-img"
          className="img-fluid w-100"
        />
      </div>
      <div className="overlay">
        <div className="desc">
          <h6 className="text-capitalize fw-bold">{item?.name}</h6>
          <div className="d-flex align-items-center justify-content-center">
            <p className="text-capitalize mx-2">عدد المشاريع</p>
            <p className="text-capitalize fw-bold fs-22">
              {item?.project_count || 0}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeveloperCard;
