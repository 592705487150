import React from "react";
import logo from "../../../images/anonymous-image.png";
import UserNotificationsHook from "../../../CustomHooks/User/UserNotificationsHook";
import { Skeleton } from "@mui/material";
import MarkNotificationsAsReadHook from "../../../CustomHooks/Notificatoins/MarkNotificationsAsReadHook";
const UserNotificationsContent = () => {
  const [,loading,data,t] = UserNotificationsHook();
  const [,] = MarkNotificationsAsReadHook()
  const NotSkeleton = () => {
    return (
      <li className="nots-item">
        <div className="img-box">
          <Skeleton
            variant="circular"
            height={50}
            width={50}
            sx={{ margin: "0 5px" }}
          />
        </div>
        <div className="text">
          <Skeleton
            variant="h5"
            height={20}
            width={220}
            sx={{ margin: "3px 0px", width: "100%" }}
          />
          <Skeleton
            variant="h5"
            height={20}
            width={220}
            sx={{ margin: "3px 0px", width: "100%" }}
          />
        </div>
      </li>
    );
  };
  return (
    <div className="user-notifications-content row-content">
      <div className="form-box">
        <div className="form-title-wrap border-bottom-0 pb-0">
          <h3 className="title text-capitalize my-3">{t("user.userNotificationsPage.title")} <span className="color-primary">{data?.length || 0}</span></h3>
        </div>
        <div className="nots-box">
          <ul>
            {loading ===false ? 
               data?.map((item, i) => {
                return (
                  <li className="nots-item border-bottom" key={i}>
                    <div className="img-box">
                      <img src={logo} alt="logo-img" loading="lazy" />
                    </div>
                    <div className="text">
                      {
                        item?.data?.item?
                        <>
                        <h5>{item.data.item.title}</h5>
                        <p>{item.data.item.content}</p>
                        </>
                        :
                        <>
                        <h5>{item?.data?.title}</h5>
                        <p className="my-0">{item?.data?.message}</p>
                        <p className="my-0">{item?.data?.product_title}</p>
                        </>
                      }
                    </div>
                  </li>
                );
              }
            ) : (
              <>
              <NotSkeleton />
              <NotSkeleton />
              <NotSkeleton />
            </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserNotificationsContent;
