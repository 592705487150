import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import anonymous from "../../../images/anonymous.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeActive } from "../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import Cookies from "js-cookie";
import UserProfileHook from "../../../CustomHooks/User/UserProfileHook";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBuilding,
  faEnvelope,
  faGear,
  faHeart,
  faList,
  faOutdent,
  faSchool,
  faSquarePlus,
  faTableColumns,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import LogoutHook from "../../../CustomHooks/Auth/LogoutHook";
const Sidebar = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(changeActive());
  };
  const active = useSelector(
    (state) => state.rootReducer.sidebarReducer.active
  );
  const [user, ,] = UserProfileHook();
  const { t, i18n } = useTranslation();
  const lang = Cookies.get("i18next");
  const handleChangeLang = () => {
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
  };
  const [, onLogout] = LogoutHook();
  return (
    <aside
      className={`home-sidebar transition d-block d-lg-none ${
        lang === "ar" ? "home-sidebar-ar" : "home-sidebar-en"
      } ${active === true ? "active" : ""}`}
      onClick={() => handleClick()}
    >
      <div className="sidebar-content">
        {typeof Cookies.get("user") !== "undefined" && (
          <div className="author-content d-flex align-items-center position-relative">
            <div
              className="author-img avatar-sm"
              onClick={() => Navigate("/user-profile")}
            >
              {user !== " " ? (
                <img
                  src={user?.image || anonymous}
                  alt={"profile_img"}
                  loading="lazy"
                />
              ) : (
                <Skeleton
                  variant="circular"
                  width={50}
                  height={50}
                  sx={{ margin: "0px 5px" }}
                />
              )}
            </div>
            <div className="author-bio">
              <div>
                {user !== " " ? (
                  <h4 className="author__title fs-18 fw-bold text-capitalize my-1">
                    <Link to={"/user-profile"}>{user?.name}</Link>
                  </h4>
                ) : (
                  <Skeleton
                    variant="rounded"
                    width={120}
                    height={20}
                    sx={{ margin: "5px 0" }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div className="sidebar-menu-wrap">
          <ul className="sidebar-menu">
            {typeof Cookies.get("user") !== "undefined" && (
              <>
                <Link
                  to="/user-profile"
                  className={`sidebar-menu-item-1 sidebar-menu-item`}
                >
                  <span className="color-primary mx-3">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <span>{t("user.userProfileSidebar.profile")}</span>
                </Link>
                <Link
                  to="/user-notifications"
                  className={`sidebar-menu-item-1 sidebar-menu-item`}
                >
                  <span className="color-primary mx-3">
                    <FontAwesomeIcon icon={faBell} />
                  </span>
                  <span> {t("generalSections.sidebar.notifications")}</span>
                </Link>
                <Link
                  to="/user-list"
                  className={`sidebar-menu-item-2 sidebar-menu-item`}
                >
                  <span className="color-primary mx-3">
                    <FontAwesomeIcon icon={faList} />
                  </span>
                  <span>{t("user.userProfileSidebar.list")}</span>
                </Link>
                <Link
                  to="/user-wishlist"
                  className={`sidebar-menu-item-4 sidebar-menu-item`}
                >
                  <span className="color-primary mx-3">
                    <FontAwesomeIcon icon={faHeart} />
                  </span>
                  <span>{t("generalSections.sidebar.wishlist")}</span>
                </Link>
              </>
            )}
            <Link
              to="/listing"
              className={`sidebar-menu-item-2 sidebar-menu-item`}
            >
              <span className="color-primary mx-3">
                <FontAwesomeIcon icon={faList} />
              </span>
              <span>{t("generalSections.sidebar.units")}</span>
            </Link>
            <Link
              to="/services-category"
              className={`sidebar-menu-item-2 sidebar-menu-item`}
            >
              <span className="color-primary mx-3">
                <FontAwesomeIcon icon={faSchool} />
              </span>
              <span>{t("generalSections.sidebar.services")}</span>
            </Link>
            {/* <Link
              to="/services-category"
              className={`sidebar-menu-item-2 sidebar-menu-item`}
            >
              <span className="color-primary mx-3">
                <FontAwesomeIcon icon={faBuilding} />
              </span>
              <span>{t("generalSections.sidebar.agency")}</span>
            </Link> */}
            <Link
              to="/contact-us"
              className={`sidebar-menu-item-5 sidebar-menu-item`}
            >
              <span className="color-primary mx-3">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <span>{t("generalSections.sidebar.contactUs")}</span>
            </Link>
            <Link
              to="/about-us"
              className={`sidebar-menu-item-5 sidebar-menu-item`}
            >
              <span className="color-primary mx-3">
                <FontAwesomeIcon icon={faUsers} />
              </span>
              <span>{t("generalSections.sidebar.aboutUs")}</span>
            </Link>
            {typeof Cookies.get("user") !== "undefined" && (
              <>
               <Link
                  to="/user-setting"
                  className={`sidebar-menu-item-5 sidebar-menu-item`}
                >
                  <span className="mx-2 color-primary mx-3">
                    <FontAwesomeIcon icon={faGear} />
                  </span>
                  <span>{t("generalSections.sidebar.setting")}</span>
                </Link>
                <li
                  className="sidebar-menu-item-6 sidebar-menu-item"
                  onClick={onLogout}
                >
                  <span className="mx-2 color-primary mx-3">
                    <FontAwesomeIcon icon={faOutdent} />
                  </span>
                  <span>{t("user.userProfileSidebar.logout")}</span>
                </li>
              </>
            )}
            {typeof Cookies.get("user") !== "undefined"&&
            user?.role !== "client" ? (
              user?.role === "unit_onwer" ? (
                <Link
                  to="/user-add-unit"
                  className={`sidebar-menu-item-5 sidebar-menu-item`}
                >
                  <span>
                    <FontAwesomeIcon icon={faSquarePlus} />
                  </span>
                  <span>{t("user.userProfileSidebar.addUnit")}</span>
                </Link>
              ) : (
                <a
                  href="https://admin-hadayekalahram.shahid3qar.com/"
                  rel="noreferrer"
                  target="_blank"
                  className={`sidebar-menu-item-5 sidebar-menu-item`}
                >
                  <span>
                    <FontAwesomeIcon icon={faTableColumns} />
                  </span>
                  <span>{t("user.userProfileSidebar.dashboard")}</span>
                </a>
              )
            ) : null
            }
            <li className="sidebar-menu-item">
              <span
                className="lang-span"
                role="button"
                onClick={() => handleChangeLang()}
              >
                {lang === "ar" ? "en" : "ar"}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
