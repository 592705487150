import React from "react";
import { useTranslation } from "react-i18next";

const AuthTitle = () => {
  const [t] = useTranslation()
  return <h2 className="fw-bold primary-color text-capitalize">
    {t("authPages.authTitle")}
  </h2>;
};

export default AuthTitle;
