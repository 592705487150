import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
    allCompounds:[],
    compoundDetails:[],
    error:null,
    loading:true
}
export const GetAllCompounds = createAsyncThunk("allCompounds",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/projects?per_page=${8}&${queryString}`)
        return res
    }
    catch(error){
        // console.clear()
        return rejectWithValue(error)
    }
}
)

export const GetCompoundDetails = createAsyncThunk("compoundDetails",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/projects/${id}`)
        return res
    }
    catch(error){
        // console.clear()
        return rejectWithValue(error)
    }
}
)

const CompoundsSlice = createSlice({
    name:"CompoundsSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllCompounds.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllCompounds.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allCompounds =action?.payload?.data
        })
        .addCase(GetAllCompounds.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.response
            state.allCompounds =action?.payload?.response
        })
        // compound details
        .addCase(GetCompoundDetails.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetCompoundDetails.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.compoundDetails =action?.payload
        })
        .addCase(GetCompoundDetails.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.compoundDetails =action?.payload
        })
    }
})
export const compoundsReducer = CompoundsSlice.reducer
export const compoundsActions = CompoundsSlice.actions