import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDeveloperDetails } from "../../Redux/Redux-Toolkit/Slices/Developers/DevelopersSlice";

const GetDeveloperDetailsHook = (id) => {
  const [loading,setLoading] = useState(false);
  const [developerDetails,setDeveloperDetails] = useState([]);
  const res = useSelector((state) => state.rootReducer.DevelopersReducer.DeveloperDetails);
  const dispatch = useDispatch();
  
  const getData =async () => {
    setLoading(true);
    await dispatch(GetDeveloperDetails(id));
    setLoading(false);
  }
  useEffect(() => {
    getData(); 
  },[]);

  useEffect(() => {
    getData(); 
  },[id]);

  useEffect(() => {
    try{
      if (loading === false) {
          if(res?.status === 200){
            setDeveloperDetails(res?.data?.data)
       }
      }
    }catch(e){}
  },[loading]);


  return [loading,developerDetails];
};

export default GetDeveloperDetailsHook;
