import { useState,useEffect, useCallback, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { GetUserNotifications } from "../../Redux/Redux-Toolkit/Slices/User/UserNotificationSlice";
import { useTranslation } from "react-i18next";
const UserNotificationsHook = () => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const res = useSelector((state) => state.rootReducer.userNotificationReducer.userNotifications);
  const dispatch = useDispatch();
  const [t] = useTranslation()

  let user;
  try{
    if (typeof Cookies.get("user") !== "undefined") {
      user = JSON.parse(Cookies.get("user"));
    }
  }catch(e){}


  const memoizedGetData = useCallback(async () => {
    setLoading(true);
    await dispatch(GetUserNotifications());
    setLoading(false);
  },[dispatch]);
  
  useEffect(() => {
    memoizedGetData();
  },[memoizedGetData]);
  const memoizedRes = useMemo(() => res, [res]);

  useEffect(() => {
    try {
      if (loading === false && memoizedRes) {
          if (memoizedRes?.data) {
            setData(memoizedRes.data);
          }
      }
    } catch (e) {}
  },[loading,memoizedRes]);

  return [user,loading,data,t]
};

export default UserNotificationsHook;
