import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOurInfo } from "../../../Redux/Redux-Toolkit/Slices/OurSettings/OurInfoSlice";

const OurInfoHook = () => {
  const [data, setData] = useState([]);
  const [loading, set_loading] = useState(true);
  const dispatch = useDispatch();
  const res = useSelector((state) => state.rootReducer.OurInfoReducer.ourInfo);
  // function for states
  const getData = async()=>{
        set_loading(true)
        await dispatch(getOurInfo())
        set_loading(false)
  }
  useEffect(()=>{
    const cachedInfo = sessionStorage.getItem('ourInfo');
    if (cachedInfo) {
      setData(JSON.parse(cachedInfo));
    } else {
      getData();
    }
  },[])

  useEffect(()=>{
    if(loading===false){
        if(res?.data?.data){
          setData(res.data.data)
          sessionStorage.setItem('ourInfo',JSON.stringify(res.data.data))
        }
    }
  },[loading,res])
  return [data,loading]
};

export default OurInfoHook;
