import "./App.css";
import HomePage from "./pages/HomePage/HomePage";
import { Routes, Route } from "react-router-dom";
import SingleDetailsPage from "./pages/Unit/SingleDetailsPage/SingleDetailsPage";
import ListingPage from "./pages/Unit/ListingPage/ListingPage";
import LoginPage from "./pages/AuthPages/LoginPage/LoginPage";
import SignUpPage from "./pages/AuthPages/SignUpPage/SignUpPage";
import ForgetPassPage from "./pages/AuthPages/ForgetPassPage/ForgetPassPage";
import VerifyPassCodePage from "./pages/AuthPages/VerifyPassCodePage/VerifyPassCodePage";
import NewPassPage from "./pages/AuthPages/NewPassPage/NewPassPage";
import AgencyPage from "./pages/Agency/AgencyPage/AgencyPage";
import AgencyDetailsPage from "./pages/Agency/AgencyDetailsPage/AgencyDetailsPage";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import NewsPage from "./pages/News/NewsPage/NewsPage";
import NewsDetailsPage from "./pages/News/NewsDetailsPage/NewsDetailsPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import AgentsPage from "./pages/Agent/AgentsPage/AgentsPage";
import AgentDetailsPage from "./pages/Agent/AgentDetailsPage/AgentDetailsPage";
import UserProfilePage from "./pages/User/UserProfilePage/UserProfilePage";
import UserSettingPage from "./pages/User/UserSettingPage/UserSettingPage";
import UserWishlistPage from "./pages/User/UserWishlistPage/UserWishlistPage";
import UserListPage from "./pages/User/UserListPage/UserListPage";
import UserNotificationsPage from "./pages/User/UserNotificationsPage/UserNotificationsPage";
import { ToastContainer } from "react-toastify";
import PublisherSignUpPage from "./pages/AuthPages/PublisherSignUpPage/PublisherSignUpPage";
import UserAddUnitPage from "./pages/User/UserAddUnitPage/UserAddUnitPage";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useEffect } from "react";
import i18n from "./i18n/i18n";
import ServicesCategoryPage from "./pages/Services/ServicesCategoryPage/ServicesCategoryPage";
import ServiceDetailsPage from "./pages/Services/ServiceDetailsPage/ServiceDetailsPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute ";
import ProtectRoutsHook from "./CustomHooks/Auth/ProtectRoutsHook";
import ServiceListPage from "./pages/Services/ServiceListPage/ServiceListPage";
import ClearLocalStorageOnReload from "./components/ClearLocalStorageOnReload/ClearLocalStorageOnReload";
import OurInfoHook from "./CustomHooks/OurSettings/OurInfo/OurInfoHook";
import PremiumPage from "./pages/PremiumPage/PremiumPage";
import Sidebar from "./components/Utility/Sidebar/Sidebar";
import CompoundsDetailsPage from "./pages/Compounds/CompoundsDetailsPage/CompoundsDetailsPage";
import CompoundsPage from "./pages/Compounds/CompoundsPage/CompoundsPage";
import DevelopersPage from "./pages/Developers/DevelopersPage/DevelopersPage";
import DeveloperDetailsPage from "./pages/Developers/DeveloperDetailsPage/DeveloperDetailsPage";
import UserLayout from "./pages/User/UserLayout/UserLayout";
import GeneralLayout from "./pages/GeneralLayout/GeneralLayout";
import MallsPage from "./pages/Malls/MallsPage/MallsPage";
import MallDetailsPage from "./pages/Malls/MallDetailsPage/MallDetailsPage";
import InstallmentUnitsPage from "./pages/Unit/installment_units_page/InstallmentUnitsPage";
function App() {
  const [,] = OurInfoHook();
  const { t, i18n } = useTranslation();
  // protect routs hook
  const [, isUser] = ProtectRoutsHook();
  const lng = cookies.get("i18next") || "ar";
  useEffect(() => {
    window.document.dir = i18n?.dir();
  }, [lng]);
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.error = function () {};
      console.debug = function () {};
      console.warn = function () {};
      console.info = function () {};
    }
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route element={<PrivateRoute auth={isUser} />}>
          <Route path="/user" element={<UserLayout />}>
            <Route index element={<UserProfilePage />} />
            <Route path="notifications" element={<UserNotificationsPage />} />
            <Route path="setting" element={<UserSettingPage />} />
            <Route path="list" element={<UserListPage />} />
            <Route path="add-unit" element={<UserAddUnitPage />} />
            <Route path="wishlist" element={<UserWishlistPage />} />
            <Route path="forget-pass" element={<ForgetPassPage />} />
            <Route path="verify-pass-code" element={<VerifyPassCodePage />} />
            <Route path="new-pass" element={<NewPassPage />} />
          </Route>
        </Route>
        {/* general pages  */}
        <Route path="/" element={<GeneralLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/single-details/:id" element={<SingleDetailsPage />} />
          <Route path="/listing" element={<ListingPage />} />
          <Route path="/installment" element={<InstallmentUnitsPage />} />
          <Route path="/premium" element={<PremiumPage />} />
          <Route path="/agency" element={<AgencyPage />} />
          <Route path="/agency-details/:id" element={<AgencyDetailsPage />} />
          <Route path="/services-category" element={<ServicesCategoryPage />} />
          <Route path="/service-details/:id" element={<ServiceDetailsPage />} />
          <Route path="/compounds" element={<CompoundsPage />} />
          <Route
            path="/compound-details/:id"
            element={<CompoundsDetailsPage />}
          />
          <Route path="/developers" element={<DevelopersPage />} />
          <Route
            path="/developer-details/:id"
            element={<DeveloperDetailsPage />}
          />
          <Route path="/malls" element={<MallsPage />} />
          <Route path="/mall-details/:id" element={<MallDetailsPage />} />
          <Route path="/service-list/:id" element={<ServiceListPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news-details/:id" element={<NewsDetailsPage />} />
          <Route path="/agents" element={<AgentsPage />} />
          <Route path="/agent-details/:id" element={<AgentDetailsPage />} />
        </Route>
        {/*Start Auth pages*/}
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/publisher-sign-up" element={<PublisherSignUpPage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* End Auth pages*/}

        {/*Start error page */}
        <Route path="*" exact={true} element={<ErrorPage />} />
        {/*End error page */}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition="Bounce"
      />
      <Sidebar />
      <ClearLocalStorageOnReload />
    </div>
  );
}

export default App;
