import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAgencyDetails } from "../../Redux/Redux-Toolkit/Slices/Agency/AgencySlice";
const GetAgencyDetailsHook = (id) => {
  const [loading,setLoading] = useState(false);
  const [agencyDetails,setAgencyDetails] = useState([]);
  const res = useSelector((state) => state.rootReducer.agencyReducer.agencyDetails);
  const dispatch = useDispatch();

  const getData =async () => {
    setLoading(true);
    await dispatch(GetAgencyDetails(id));
    setLoading(false);
  }
  useEffect(() => {
    getData();
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res) {
          setAgencyDetails(res?.data?.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,agencyDetails];
};

export default GetAgencyDetailsHook;
