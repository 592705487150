import React from "react";
import DeveloperCard from "../../../../components/Developers/DeveloperCard/DeveloperCard";
import NotFound from "../../../../components/Utility/NotFound/NotFound";
import CardSkeleton from "../../../../components/Utility/Cards/CardSkeleton/CardSkeleton";
import { useNavigate } from "react-router-dom";
import GetAllDevelopersHook from "../../../../CustomHooks/Developers/GetAllDevelopersHook";
import CustomPagination from "../../../../components/Utility/CustomPagination/CustomPagination";

const DevelopersListContent = () => {
  const Navigate = useNavigate();
  const unitsSkeleton = Array.from({ length: 12 }, (_, index) => index + 1);
  const [loading, developers, developersCount, , onPressPaginate, meta] =
    GetAllDevelopersHook();
  return (
    <div className="compounds-list">
      <div className="row units-wrapper">
        {loading === false ? (
          developersCount > 0 ? (
            developers?.map((item) => (
              <div
                className="col-sm-4"
                key={item?.id}
                onClick={() => Navigate(`/developer-details/:${item?.id}`)}
              >
                <DeveloperCard item={item} />
              </div>
            ))
          ) : (
            <NotFound />
          )
        ) : (
          unitsSkeleton.map((_, i) => (
            <div className="col-sm-4" key={i}>
              <CardSkeleton />
            </div>
          ))
        )}
      </div>
      <div className="row my-5">
        {developersCount > 1 && (
          <CustomPagination
            totalPages={meta?.last_page}
            currentPage={meta?.current_page}
            onChange={onPressPaginate}
          />
        )}
      </div>
    </div>
  );
};

export default DevelopersListContent;
