import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
    allDevelopers:[],
    DeveloperDetails:[],
    error:null,
    loading:true
}
export const GetAllDevelopers = createAsyncThunk("allDevelopers",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/developers?per_page=${15}&${queryString}`)
        return res
    }
    catch(error){
        // console.clear()
        return rejectWithValue(error)
    }
}
)

export const GetDeveloperDetails = createAsyncThunk("DeveloperDetails",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/developers/${id}`)
        return res
    }
    catch(error){
        // console.clear()
        return rejectWithValue(error)
    }
}
)

const DevelopersSlice = createSlice({
    name:"DevelopersSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllDevelopers.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllDevelopers.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allDevelopers =action?.payload?.data
        })
        .addCase(GetAllDevelopers.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.response
            state.allDevelopers =action?.payload?.response
        })
        // Developer details
        .addCase(GetDeveloperDetails.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetDeveloperDetails.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.DeveloperDetails =action?.payload
        })
        .addCase(GetDeveloperDetails.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.DeveloperDetails =action?.payload
        })
    }
})
export const DevelopersReducer = DevelopersSlice.reducer
export const DevelopersActions = DevelopersSlice.actions