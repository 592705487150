import React from "react";
import "./AgencyCard.css";
import {useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import img from "../../../images/anonymous-image.png"
// import Cookies from "js-cookie"
const AgencyCard = ({ item }) => {
  const Navigate = useNavigate();
  // const lang= Cookies.get("i18next")
  const [t] =useTranslation()
  return (
    <div
      className="project-card row text-start mx-1"
      onClick={() => Navigate(`/agency-details-page/:${item?.id}`)}
    >
      <div className="col-12 p-0">
        <div onClick={() => Navigate(`/agency-details-page/:${item?.id}`)}
          className="profile__agency-logo">
          <img
            src={item?.agency_image || img}
            alt="project-img"
            loading="lazy"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="profile__agency-info">
          <h5 className="project-title text-capitalize">
            {item?.company_name}
          </h5>
          <p className="text-capitalize mb-1">
           {item?.address}
          </p>
          <ul className="list-unstyled mt-2">
            <li>
                <span>
                  <i className="fa fa-building"></i>
                </span>
                <span> {t("projectsPage.projectCard.whatsapp")} : {item?.phone}</span>
            </li>
            <li>
                <span>
                  <i className="fa fa-phone"></i>
                </span>
                <span> {t("projectsPage.projectCard.mobile")} : {item?.phone}</span>
            </li>
            <li>
                <span>
                  <i className="fa fa-envelope"></i>
                </span>
                <span>{t("projectsPage.projectCard.email")} : {item?.email}</span>
            </li>
          </ul>
          <ul className="list-row">
            <li className="list-row-item">
              <a href={item?.user?.facebook} className="btn-social facebook">
                <FontAwesomeIcon icon={faFacebookF}/>
              </a>
            </li>
            <li className="list-row-item">
              <a href={item?.twitter} className="btn-social twitter">
              <FontAwesomeIcon icon={faTwitter}/>
              </a>
            </li>
            <li className="list-row-item">
              <a href={item?.linkedin} className="btn-social linkedin">
              <FontAwesomeIcon icon={faLinkedin}/>
              </a>
            </li>
            <li className="list-row-item">
              <a href={item?.instagram} className="btn-social instagram ">
              <FontAwesomeIcon icon={faInstagram}/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AgencyCard;
