import React from 'react';
import { Helmet } from 'react-helmet-async';
const CustomHelmet = ({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  ogType,
  twitterCard,
  twitterTitle,
  twitterDescription,
  twitterImage,
  viewport,
  canonical,
  icon,
  robots,
  // Add more props as needed
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Social Meta Tags */}
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content={ogType} />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={twitterTitle || title} />
      <meta name="twitter:description" content={twitterDescription || description} />
      <meta name="twitter:image" content={twitterImage} />
      {/* Other Important Tags */}
      <meta name="viewport" content={viewport || 'width=device-width, initial-scale=1'} />
      <link rel="canonical" href={canonical} />
      <link rel="icon" href={icon} />
      <meta name="robots" content={robots} />
    </Helmet>
  );
};

export default CustomHelmet;