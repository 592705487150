import React from "react";
import "./UserWishlistPage.css";
import UserProfileSidebar from "../../../components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../components/User/UserDashboardBread/UserDashboardBread";
import Card from "../../../components/Utility/Cards/Card/Card";
import GetAllWishlistHook from "../../../CustomHooks/Wishlist/GetAllWishlistHook";
import CardSkeleton from "../../../components/Utility/Cards/CardSkeleton/CardSkeleton";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import NotFound from "../../../components/Utility/NotFound/NotFound";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const UserWishlistPage = () => {
  const [wishlist_loading, wishlist, wishlistIds] = GetAllWishlistHook();
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  return (
    <div className="user-wishlist-page page">
       <CustomHelmet
      title={t("user.userWishlistPage.title")}
      />
      <div
        className={`d-none d-lg-block ${
          lang === "en" ? "user-sidebar-wrap" : "user-sidebar-wrap-ar"
        }`}
      >
        <UserProfileSidebar wishlistPage={"wishlistPage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav wishlistPageActive={"page-active"} />
        <UserDashboardBread section_title={t("user.userWishlistPage.title")} />
        <div className="dashboard-main-content">
          <div className="row-content">
            <div className="form-box">
              <div className="form-title-wrap">
                <h3 className="title">{t("user.userWishlistPage.title")}</h3>
                {t("user.userWishlistPage.results")} {wishlist?.length}
              </div>
              <div className="form-content pt-5 pb-0">
                <div className="row">
                  {wishlist_loading === false ? (
                    wishlist?.length>0?
                    wishlist?.map((item) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-xl-3"
                        key={item?.id}
                      >
                        <Card item={item?.unit} favItem={wishlistIds} />
                      </div>
                    ))
                    :<NotFound/>
                  ) : (
                    <>
                      <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                        <CardSkeleton />
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                        <CardSkeleton />
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                        <CardSkeleton />
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                        <CardSkeleton />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWishlistPage;
