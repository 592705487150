import React from 'react'
import './RealStateCard.css'
import { Link } from 'react-router-dom'
import card_img from '../../../images/anonymous-image.png'
const RealStateCard = () => {
  return (
    <div className="real-state-card card__image card__box-v1">
    <div className="row no-gutters">
      <div className="col-md-4 col-lg-3 col-xl-4">
        <div className="card__image__header h-250">
          <Link to="/single-details-page:id">
            <div className="ribbon text-capitalize">
              sold out
            </div>
            <img
              src={card_img}
              alt=""
              className="img-fluid w100 img-transition"
              loading="lazy"
            />
            <div className="info"> for sale</div>
          </Link>
        </div>
      </div>
      <div className="col-md-4 col-lg-6 col-xl-5 my-auto">
        <div className="card__image__body">
          <span className="badge badge-primary text-capitalize mb-2">
            house
          </span>
          <h6>
            <Link to="/single-details-page:id">vila in coral gables</Link>
          </h6>
          <div className="card__image__body-desc">
            <p className="text-capitalize">
              Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Libero, alias!
            </p>
            <p className="text-capitalize">
              <i className="fa fa-map-marker"></i>
              west flaminggo road, las vegas
            </p>
          </div>

          <ul className="list-inline card__content">
            <li className="list-inline-item">
              <span>
                baths <br></br>
                <i className="fa fa-bath"></i> 2
              </span>
            </li>
            <li className="list-inline-item">
              <span>
                beds <br></br>
                <i className="fa fa-bed"></i> 3
              </span>
            </li>
            <li className="list-inline-item">
              <span>
                rooms <br></br>
                <i className="fa fa-inbox"></i> 3
              </span>
            </li>
            <li className="list-inline-item">
              <span>
                area <br></br>
                <i className="fa fa-map"></i> 4300 sq ft
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-4 col-lg-3 col-xl-3 my-auto card__image__footer-first">
        <div className="card__image__footer">
          <figure>
            <img
              src="images/80x80.jpg"
              alt=""
              className="img-fluid rounded-circle"
              loading="lazy"
            />
          </figure>
          <ul className="list-inline my-auto">
            <li className="list-inline-item name">
              <a href="/">tom wilson</a>
            </li>
          </ul>
          <ul className="list-inline my-auto ml-auto price">
            <li className="list-inline-item ">
              <h6>$350.000</h6>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}

export default RealStateCard
