import { Skeleton } from "@mui/material";
import React from "react";

const CategoriesAnalysis = ({
  categories_loading,
  categories,
  onChangeCategoryId,
  lang,
  t,
}) => {
  return (
    <div className="categories-analysis">
      <div className="widget widget__archive">
        <div className="widget__title">
          <h5 className="mb-0">
            {t("listingPage.listingSidebar.categoriesProperty")}
          </h5>
        </div>
        <ul className="list-unstyled">
          {categories_loading === false ? (
            categories?.map((item, i) => {
              return (
                <li
                  className="categories-list-item"
                  id={item?.id}
                  key={item?.id}
                  onClick={onChangeCategoryId}
                >
                  <span>
                    {lang === "en"
                      ? item?.data_trans
                        ? item?.data_trans[1]?.title_en
                        : null
                      : item?.data_trans
                      ? item?.data_trans[0]?.title_ar
                      : null}
                  </span>
                  <span className="badge badge-primary mx-1">
                    {item?.count_unit}
                  </span>
                </li>
              );
            })
          ) : (
            <>
              <Skeleton
                variant="rounded"
                width={150}
                height={30}
                sx={{ margin: "5px 0" }}
              />
              <Skeleton
                variant="rounded"
                width={150}
                height={30}
                sx={{ margin: "5px 0" }}
              />
              <Skeleton
                variant="rounded"
                width={150}
                height={30}
                sx={{ margin: "5px 0" }}
              />
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default CategoriesAnalysis;
