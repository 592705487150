import React, { useEffect, useRef } from "react";
import './Landing.css'
import SearchBox from "../Landing/SearchBox/SearchBox";
import Header from "../Header/Header";
import bgVideo from "../../../images/7578540-uhd_3840_2160_30fps.mp4"
const Landing = () => {
  const video = useRef("video")
  useEffect(()=>{
    video.current.play()
  },[])
  return (
    <div className="landing bg-theme">
      <div className="bg-overlay"></div>
      <video  autoPlay loop muted ref={video}>
        <source src={bgVideo} type="video/mp4"/>
      </video>
      <Header />
      <SearchBox />
    </div>
  );
};

export default Landing;
