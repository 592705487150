import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllServicesCategory } from "../../Redux/Redux-Toolkit/Slices/Services/ServicesSlice";
const GetAllServicesCategoryHook = () => {
  const [loading,setLoading] = useState(false);
  const [servicesCategory,setServicesCategory] = useState([]);
  const res = useSelector((state) => state.rootReducer.servicesReducer.allServicesCategory);
  const dispatch = useDispatch();

  const getData =async (page) => {
    setLoading(true);
    await dispatch(GetAllServicesCategory(page));
    setLoading(false);
  }
  useEffect(() => {
    const cachedServicesCategory = sessionStorage.getItem('servicesCategory');
    if (cachedServicesCategory) {
      setServicesCategory(JSON.parse(cachedServicesCategory));
    } else {
      getData();
    }
  },[]);

  const onPressPaginate = (page)=>{
    getData(page)
  }
  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.data?.data) {
            setServicesCategory(res.data.data);
            sessionStorage.setItem("servicesCategory",JSON.stringify(res.data.data))
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,servicesCategory,onPressPaginate];
};

export default GetAllServicesCategoryHook;
