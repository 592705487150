import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faMap} from "@fortawesome/free-solid-svg-icons";
const ServiceDetailsInfo = ({serviceDetails}) => {
  const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="single__detail-features single-detail-info ">
      <div className="property__detail-info">
        <div className="row">
          <div className="col-12 my-4">
            <ul className="property__detail-info-list list-unstyled">
            <li>
              <span>
                  <span className="mx-2 color-primary"><FontAwesomeIcon icon={faMap}/></span>
                  <b>{t("singleDetailsPage.singleDetailsInfo.location")}:</b>
                </span>
                <span className="mx-2">
                {serviceDetails?.data?.address}
                </span>
              </li>
            </ul>
          </div>
            {serviceDetails?.data?.features?.length>0 &&
          <div className="col-12 my-4">
          <h5 className="text-capitalize fw-bold color-primary">{t("servicePage.features")}</h5>
          <ul className="property__detail-info-list list-unstyled">
                { serviceDetails?.data?.features?.length>0?
                serviceDetails?.data?.features?.map((item,i)=>{return(
                    <li key={i}>
                      <span className="mx-2 color-primary"><FontAwesomeIcon icon={faCheck}/></span>
                    <span className="mx-2">
                    {
                        lang==="en"?
                        item?.data_trans?item?.data_trans[0].title_en:null
                        :
                        item?.data_trans?item?.data_trans[1].title_ar:null
                        }
                    </span>
                  </li>
                )})
                :
                null
              }
          </ul>
          </div>
            }
            {serviceDetails?.data?.features?.length>0 &&
          <div className="col-12 my-4">
          <h5 className="text-capitalize fw-bold color-primary">{t("servicePage.features")}</h5>
          <ul className="property__detail-info-list list-unstyled">
                { serviceDetails?.data?.features?.length>0?
                serviceDetails?.data?.features?.map((item,i)=>{return(
                    <li key={i}>
                      <span className="mx-2 color-primary"><FontAwesomeIcon icon={faCheck}/></span>
                    <span className="mx-2">
                    {
                        lang==="en"?
                        item?.data_trans?item?.data_trans[0].title_en:null
                        :
                        item?.data_trans?item?.data_trans[1].title_ar:null
                        }
                    </span>
                  </li>
                )})
                :
                null
              }
          </ul>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsInfo;
