import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../UseNotification/UseNotification";
import { addUnit } from "../../Redux/Redux-Toolkit/Slices/User/UserUnitSlice";
const UserAddUnitHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.rootReducer.UserUnitReducer);
  // hook states
  const [images,setImages] = useState([]);
  const [imagesCount,setImagesCount] = useState("0");
  const [selectedImage,setSelectedImage] = useState("")
  const [video,setVideo] = useState();
  const [videoCount,setVideoCount] = useState("0");
  const [enTitle,setEnTitle] = useState("");
  const [arTitle,setArTitle] = useState("");
  const [enDescription,setEnDescription] = useState("");
  const [arDescription,setArDescription] = useState("");
  const [price,setPrice] = useState("");
  const [location,setLocation] = useState("");
  const [area,setArea] = useState("");
  const [serviceCharges,setServiceCharges] = useState("");
  const [rooms,setRooms] = useState("");
  const [bathroom,setBathroom] = useState("");
  const [floor,setFloor] = useState("");
  const [category,setCategory] = useState("");
  const [type,setType] = useState("");
  const [district,setDistrict] = useState("");
  const [paying,setPaying] = useState("");
  const [propertiesValue,setProperties] = useState([]);
  const [propertyId,setPropertyId] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [finishing, setFinishing] = useState("");
  const [furnished, setFurnished] = useState("");
  const [gateId, setGateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [loading,setLoading] = useState(true);
  const [isPress,setIsPress] = useState(false);

    // function for states
   // when user choose photo this method will store this photo

   const onChangeImages =(event)=>{
    if(event?.target?.files && event?.target?.files[0]){
      setImages(URL.createObjectURL(event.target.files[0]))
      setSelectedImage(event.target.files[0])
      setImagesCount(event?.target?.files?.length)
      console.log(event.target.files)
    }
    }
   const onChangeVideo =(event)=>{
    if(event.target.files && event.target.files[0]){
        setVideo(URL.createObjectURL(event.target.files[0]))
        setVideoCount(event?.target?.files?.length)
      }
    }
  const onChangeEnTitle = (e) => {
    setEnTitle(e.target.value);
  };
  const onChangeArTitle = (e) => {
    setArTitle(e.target.value);
  };
  const onChangeEnDescription = (e) => {
    setEnDescription(e.target.value);
  };
  const onChangeArDescription = (e) => {
    setArDescription(e.target.value);
  };
  const onChangePrice = (e) => {
    setPrice(e.target.value);
  };
  const onChangeLocation = (e) => {
    setLocation(e.target.value);
  };
  const onChangeArea = (e) => {
    setArea(e.target.value);
  };
  const onChangeServiceCharges = (e) => {
    setServiceCharges(e.target.value);
  };
  const onChangeRooms = (e) => {
    setRooms(e.target.value);
  };
  const onChangeFloor = (e) => {
    setFloor(e.target.value);
  };
  const onChangeBathrooms = (e) => {
    setBathroom(e.target.value);
  };
  const onChangeCategory = (e) => {
    setCategory(e.id);
  };
  const onChangeType = (e, val) => {
    if (e.target) {
      if (e.target.value === 0) {
        setType(val);
        localStorage.setItem("type", val);
      } else {
        setType(e.target.value);
        localStorage.setItem("type", e.target.value);
      }
    } else {
      setType(e.value);
      localStorage.setItem("type", e.value);
    }
  };
  const onChangeDistrict = (e) => {
    setDistrict(e.id);
  };
  const onChangePaying = (e) => {
    setPaying(e.id);
  };
  const onChangePropertyId = (e) => {
    if (e.target.checked === true) {
      setPropertyId(e.target.id);
      localStorage.setItem("propertyId",e.target.id);
    }else{
      localStorage.removeItem("propertyId");
      setPropertyId("");
    }
  };
  const onChangeFinishing = (e) => {
    if (e.target.checked === true) {
      setFinishing(e.target.value);
      localStorage.setItem("finishing",e.target.value);
    }else{
      setFinishing("");
      localStorage.removeItem("finishing");
    }
  };
  const onChangeFurnished = (e) => {
    if (e.target.checked === true) {
      setFurnished(e.target.value);
      localStorage.setItem("furnished",e.target.value);
    }else{
      localStorage.removeItem("furnished");
      setFurnished("");
    }
  };
  const onChangeCategoryId = (e) => {
    if (e.target) {
      setCategory(e.target.id);
      localStorage.setItem("category_id", e.target.id);
      localStorage.removeItem("main_category")
    } else {
      setCategory(e.id);
      localStorage.setItem("category_id", e.id);
      localStorage.removeItem("main_category")
    }
  };
  const onChangeMainCategory = (e) => {
    if (e.target){
      setMainCategory(e.target.id);
      localStorage.setItem("main_category",e.target.id);
    } else {
      setMainCategory(e.id);
      localStorage.setItem("main_category",e.id);
    }
  };
  const onChangeGateId = (e) => {
    if (e.target) {
      setGateId(e.target.id);
      localStorage.setItem("gate_id",e.target.id);
    } else {
      setGateId(e.id);
      localStorage.setItem("gate_id",e.id);
    }
  };
  const onChangeDistrictId = (e) => {
    if (e.target) {
      setDistrictId(e.target.id);
      localStorage.setItem("district_id",e.target.id);
    } else {
      setDistrictId(e.id);
      localStorage.setItem("district_id",e.id);
    }
  };
  // on submit fn
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsPress(true);
    setLoading(true);
    const formData = new FormData()
    selectedImage !== "" && formData.append("image",selectedImage);
    formData.append("en[title]",enTitle)
    formData.append("en[description]",enDescription)
    formData.append("ar[title]",arTitle)
    formData.append("ar[description]",arDescription)
    formData.append("type",type)
    formData.append("paying",paying)
    formData.append("service_charges",serviceCharges)
    formData.append("price",price)
    formData.append("size",area)
    formData.append("rooms",rooms)
    formData.append("bathroom",bathroom)
    formData.append("floor",floor)
    formData.append("location",location)
    formData.append("image[]",images)
    formData.append("video",video)
    formData.append("category_id",category)
    formData.append("district_id",district)
    formData.append("type",district)
    formData.append("paying",district)
    formData.append("properties[]",propertiesValue)
    await dispatch(addUnit(formData));
    setLoading(false);
    setIsPress(false);
  };

// 
useEffect(() => {
    if (loading === false) {
      if(response){
        // console.log(response)
      }
      //  if(response?.addUnit?.data?.status === 200){
      //   Notify(response?.addUnit?.data?.message,"success")
      //  }else{
      //   Notify("there is a problem","error")
      //  }
    }
  },[loading,response]);
  
  return [images,imagesCount,video,videoCount,enTitle,arTitle,enDescription,arDescription,price,
    location,area,serviceCharges,rooms,bathroom,floor,category,type,district,paying,propertiesValue,
    onChangeImages,onChangeVideo,onChangeEnTitle,onChangeArTitle,onChangeEnDescription,
    onChangeArDescription,onChangePrice,onChangeLocation,onChangeArea,
    onChangeServiceCharges,onChangeRooms,onChangeBathrooms,onChangeFloor,onChangeCategory,onChangeType,
    onChangeDistrict,onChangePaying,onChangePropertyId,onChangeFinishing,onChangeFurnished,
    onChangeCategoryId,onChangeMainCategory,onChangeGateId,onChangeDistrictId
    ,mainCategory,finishing,furnished,gateId,districtId,loading,isPress,onSubmit
  ]

};

export default UserAddUnitHook;
