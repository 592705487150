import React from "react";
import AgentCard from "../../../components/Agents/AgentCard/AgentCard";
import AgentsSidebar from "../../../components/Agents/AgentsSidebar/AgentsSidebar";
import Footer from "../../../components/GeneralSections/Footer/Footer";
import ScrollToTopBtn from "../../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Cta from "../../../components/Home/Cta/Cta";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import { useTranslation } from "react-i18next";

const AgentsPage = () => {
  const [t] = useTranslation()
  return (
    <div className="agents-page page">
       <CustomHelmet
      title={t("SEO.meta.title")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader breadcrumbTitle={"Agents"} agentsActive={"agentsActive"}/>
      <section className="profile__agents">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <AgentsSidebar />
            </div>
            <div className="col-lg-8">
              <div className="row no-gutters">
                <div className="col-lg-12 cards mt-0">
                  <AgentCard />
                </div>
                <div className="col-lg-12 cards">
                  <AgentCard />
                </div>
                <div className="col-lg-12 cards">
                  <AgentCard />
                </div>
                <div className="col-lg-12 cards">
                  <AgentCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Cta />
    </div>
  );
};

export default AgentsPage;
