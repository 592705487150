import { useState,useEffect, useCallback, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import {MarkNotificationsAsRead } from "../../Redux/Redux-Toolkit/Slices/User/UserNotificationSlice";
const MarkNotificationsAsReadHook = () => {
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState([]);
    const res = useSelector((state) => state.rootReducer.userNotificationReducer.markAsRead);
    const dispatch = useDispatch();
  
    const memoizedGetData = useCallback(async () => {
        setLoading(true);
        await dispatch(MarkNotificationsAsRead());
        setLoading(false);
      },[dispatch]);
      useEffect(() => {
        memoizedGetData();
      },[memoizedGetData]);
      const memoizedRes = useMemo(() => res, [res]);
      useEffect(() => {
        try {
          if (loading === false) {
              if (memoizedRes?.data) {
                setData(memoizedRes.data);
              }
          }
        } catch (e) {}
      },[loading, memoizedRes]);


    return [loading,data]
}

export default MarkNotificationsAsReadHook
