import React from "react";
import "./UserProfilePage.css";
import UserProfileSidebar from "../../../components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../components/User/UserDashboardBread/UserDashboardBread";
import UserProfileInfoSection from "../../../components/User/UserProfile/UserProfileInfoSection";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const UserProfilePage = () => {
  const lang = Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="user-profile-page d-flex justify-content-end page">
       <CustomHelmet
      title={t("user.userProfilePage.title")}
      />
      <div className={`d-none d-lg-block ${lang==="en"?"user-sidebar-wrap":"user-sidebar-wrap-ar"}`}>
        <UserProfileSidebar profilePage={"profilePage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav profilePageActive={"page-active"} />
        <UserDashboardBread section_title={t("user.userProfilePage.title")} />
        <div className="dashboard-main-content">
         <UserProfileInfoSection />
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
