import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import SlideShow from "../../../components/SingleDetails/SlideShow/SlideShow";
import MallUnits from "./MallUnits/MallUnits";
import CategoriesAnalysisSideBar from "../../../components/Utility/CategoriesAnalysisSideBar/CategoriesAnalysisSideBar";
import GetMallDetailsHook from "../../../CustomHooks/Malls/GetMallDetailsHook";
import { useParams } from "react-router-dom";
import ContactButtons from "../../../components/UI/contact-btns/ContactButtons";
import DisplayWordpadContent from "../../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
const MallDetailsPage = () => {
  const { id } = useParams();
  const [t] = useTranslation();
  const [loading, mallDetails] = GetMallDetailsHook(id?.slice(1, id.length));
  console.log(mallDetails);
  return (
    <div className="malls-page page">
      <CustomHelmet
        title={t("mallsDetailsPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader mallsActive={"mallsActive"} />
      <BreadcrumbSection title={t("mallsDetailsPage.title")} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-none d-xl-block">
              <CategoriesAnalysisSideBar />
            </div>
            <div className="col-xl-8">
              {loading === false ? (
                <div className="content-wrapper">
                  <div className="mall-img">
                    {loading === false ? (
                      mallDetails?.images?.length > 4 ? (
                        <SlideShow unitDetails={mallDetails} />
                      ) : (
                        <img
                          style={{ maxHeight: "600px" }}
                          className="w-100 h-100"
                          src={mallDetails?.cover_image}
                          alt="mall-img"
                          loading="lazy"
                        />
                      )
                    ) : (
                      <HomeSectionSkeleton />
                    )}
                  </div>
                  <div className="mall-info my-3 txt-start">
                    <div className="info-head d-flex justify-content-between flex-wrap my-4">
                      <div>
                        <h3 className="fw-bold m-0">
                          {mallDetails?.data_trans
                            ? mallDetails?.data_trans[0]?.title_en
                            : "..."}
                        </h3>
                        <p>{mallDetails?.project?.address || "...."}</p>
                      </div>
                      <div>
                        <h3 className="fw-bold m-0">Contact info</h3>
                        <p className="m-0">
                          <span className="mx-1 color-primary ">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <span>
                            {mallDetails?.project?.user?.phone || "...."}
                          </span>
                        </p>
                        <p className="m-0">
                          <span className="mx-1 color-primary">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <span>
                            {mallDetails?.project?.user?.email || "...."}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="info-elements row">
                      <div className="col-lg-6">
                        <ul>
                          <li className="my-2">
                            <h3 className="fw-bold color-primary text-capitalize d-inline">
                              project Name
                            </h3>
                            <p className="fs-22 d-inline mx-2">
                              <span>/</span>
                              <span>
                                {mallDetails?.project?.data_trans
                                  ? mallDetails?.project?.data_trans[1].title_en
                                  : "..."}
                              </span>
                            </p>
                          </li>
                          <li className="my-2">
                            <h3 className="fw-bold color-primary text-capitalize d-inline">
                              developer name
                            </h3>
                            <p className="fs-22 d-inline mx-2">
                              <span>/</span>
                              <span>
                                {mallDetails?.project?.user?.name || "..."}
                              </span>
                            </p>
                          </li>
                          <li className="my-2">
                            <h3 className="fw-bold color-primary text-capitalize d-inline">
                              The district
                            </h3>
                            <p className="fs-22 d-inline mx-2">
                              <span>/</span>
                              <span>
                                {mallDetails?.project?.address || "..."}
                              </span>
                            </p>
                          </li>
                          <li className="my-2">
                            <h3 className="fw-bold color-primary text-capitalize d-inline">
                              the finishing type
                            </h3>
                            <p className="fs-22 d-inline mx-2">
                              <span>/</span>
                              <span>
                                {mallDetails?.project?.finish_type || "..."}
                              </span>
                            </p>
                          </li>
                          <li className="my-2">
                            <h3 className="fw-bold color-primary text-capitalize d-inline">
                              the price begin from
                            </h3>
                            <p className="fs-22 d-inline mx-2">
                              <span>/</span>
                              <span>
                                {mallDetails?.project?.price || "..."} "EG"{" "}
                              </span>
                            </p>
                          </li>
                          <li className="my-2">
                            <h3 className="fw-bold color-primary text-capitalize d-inline">
                              the pay system
                            </h3>
                            <p className="fs-22 d-inline mx-2">
                              <span>/</span>
                              <span>
                                {mallDetails?.project?.method_payment || "..."}
                              </span>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ContactButtons
                          whatsapp={mallDetails?.project?.user?.phone}
                          phone={mallDetails?.project?.user?.phone}
                          email={mallDetails?.project?.user?.email}
                        />
                      </div>
                    </div>
                    <div className="description">
                      <DisplayWordpadContent
                        content={
                          mallDetails?.data_trans
                            ? mallDetails?.data_trans[0]?.description_en
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="mall-pay-plans my-5">
                    <h3 className="fw-bold txt-start mb-3 mt-5 text-capitalize">
                      pay plans
                    </h3>
                    <ul className="d-flex align-content-center justify-content-center">
                      <li
                        style={{ height: "250px" }}
                        className="fs-18 fw-bold bg-twitter-light wd-fit py-1 py-md-3 px-1 px-md-5 mx-1 mx-md-3 transition pointer rounded"
                      >
                        <h4 className="fs-55 fw-bold my-2 color-sec">10%</h4>
                        <p className="text-secondary">
                          <span className="d-block my-1">مقدم</span>
                          <span className="d-block my-1">8 سنوات</span>
                          <span className="d-block my-1">
                            خطة الدفع الاصليه
                          </span>
                        </p>
                      </li>
                      <li
                        style={{ height: "250px" }}
                        className="fs-18 fw-bold bg-twitter-light wd-fit py-1 py-md-3 px-1 px-md-5 mx-1 mx-md-3 transition pointer rounded"
                      >
                        <h4 className="fs-55 fw-bold my-2 color-sec">8%</h4>
                        <p className="text-secondary">
                          <span className="d-block my-1">مقدم</span>
                          <span className="d-block my-1">8 سنوات</span>
                          <span className="d-block my-1">
                            خطة الدفع الاصليه
                          </span>
                        </p>
                      </li>
                      <li
                        style={{ height: "250px" }}
                        className="fs-18 fw-bold bg-twitter-light wd-fit py-1 py-md-3 px-1 px-md-5 mx-1 mx-md-3 transition pointer rounded"
                      >
                        <h4 className="fs-55 fw-bold my-2 color-sec">5%</h4>
                        <p className="text-secondary">
                          <span className="d-block my-1">مقدم</span>
                          <span className="d-block my-1">8 سنوات</span>
                          <span className="d-block my-1">
                            خطة الدفع الاصليه
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="mall-units my-5">
                    <ul className="d-flex align-content-center justify-content-center">
                      <li className="fs-18 fw-bold border bg-transparent text-secondary wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap">
                        <FontAwesomeIcon icon={faArrowRight} />
                        <span className="mx-2">Commercial</span>
                      </li>
                      <li className="fs-18 fw-bold border bg-transparent  text-secondary wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap">
                        <FontAwesomeIcon icon={faArrowRight} />
                        <span className="mx-2">Rentals</span>
                      </li>
                      <li className="fs-18 fw-bold border bg-transparent  text-secondary wd-fit py-1 py-md-3 px-1 px-md-5 hover-2 mx-1 mx-md-3 transition pointer radius-30x d-flex align-items-center flex-nowrap">
                        <FontAwesomeIcon icon={faArrowRight} />
                        <span className="mx-2">Sales</span>
                      </li>
                    </ul>
                    <MallUnits units={mallDetails?.units} />
                  </div>
                </div>
              ) : (
                <>
                  <div className="my-4">
                    <HomeSectionSkeleton />
                  </div>
                  <div className="my-4">
                    <HomeSectionSkeleton />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default MallDetailsPage;
