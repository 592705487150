import React from "react";
import NotFound from "../../../../components/Utility/NotFound/NotFound";
import UnitCard from "../../../../components/Utility/Cards/UnitCard/UnitCard";

const CompoundUnits = ({ units }) => {
  return (
    <div className="compound-units-content">
      <div className="row units-wrapper">
        {units?.length > 0 ? (
          units?.slice(0, 4)?.map((item) => (
            <div className="col-sm-6" key={item?.id}>
              <UnitCard item={item} />
            </div>
          ))
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};

export default CompoundUnits;
