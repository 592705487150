import React from "react";
import './AgentSidebar.css'
const AgentSidebar = () => {
  return (
    <div className="agent-sidebar">
      <div className="sticky-top">
        <div className="products__filter mb-30">
          <div className="products__filter__group">
            <div className="products__filter__header">
              <h5 className="text-center text-capitalize">Contact Jhon Doe</h5>
            </div>
            <div className="products__filter__body">
              <div className="form-group">
                <label>Full Name</label>
                <input type="text" className="custom-input" />
              </div>
              <div className="form-group">
                <label>Email (Optional)</label>
                <input type="email" className="custom-input" />
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input type="text" className="custom-input" />
              </div>

              <div className="form-group">
                <label>Your message</label>
                <textarea className="custom-input" rows="3"></textarea>
              </div>
            </div>
            <div className="products__filter__footer">
              <div className="form-group mb-0">
                <button className="btn btn-primary text-capitalize btn-block">
                  submit <i className="fa fa-paper-plane ml-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <aside className=" wrapper__list__category">
          <div className="widget widget__archive">
            <div className="widget__title">
              <h5 className="text-dark mb-0 text-center">Categories Property</h5>
            </div>
            <ul className="list-unstyled">
              <li>
                <a href="/" className="text-capitalize">
                  apartement
                  <span className="badge badge-primary">14</span>
                </a>
              </li>
              <li>
                <a href="/" className="text-capitalize">
                  villa
                  <span className="badge badge-primary">4</span>
                </a>
              </li>
              <li>
                <a href="/" className="text-capitalize">
                  family house
                  <span className="badge badge-primary">2</span>
                </a>
              </li>
              <li>
                <a href="/" className="text-capitalize">
                  modern villa
                  <span className="badge badge-primary">8</span>
                </a>
              </li>
              <li>
                <a href="/" className="text-capitalize">
                  town house
                  <span className="badge badge-primary">10</span>
                </a>
              </li>
              <li>
                <a href="/" className="text-capitalize">
                  office
                  <span className="badge badge-primary">12</span>
                </a>
              </li>
            </ul>
          </div>
        </aside>
        <div className="download mb-0">
          <h5 className="text-center text-capitalize">Property Attachments</h5>
          <div className="download__item">
            <a href="/" target="_blank">
              <i className="fa fa-file-pdf-o mr-3" aria-hidden="true"></i>Download
              Document.Pdf
            </a>
          </div>
          <div className="download__item">
            <a href="/" target="_blank">
              <i className="fa fa-file-word-o mr-3" aria-hidden="true"></i>
              Presentation 2016-17.Doc
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentSidebar;
