import React from "react";
import "./SingleDetailsPage.css";
import SlideShow from "../../../components/SingleDetails/SlideShow/SlideShow";
import bigImg from "../../../images/bg.jpg";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import SingleDetailsSidebar from "../../../components/SingleDetails/SingleDetailsSidebar/SingleDetailsSidebar";
import SingleDetailDesc from "../../../components/SingleDetails/Features/SingleDetailDesc/SingleDetailDesc";
import SingleDetailInfo from "../../../components/SingleDetails/Features/SingleDetailInfo/SingleDetailInfo";
import SingleDetailAmenities from "../../../components/SingleDetails/Features/SingleDetailAmenities/SingleDetailAmenities";
import SingleDetailVideo from "../../../components/SingleDetails/Features/SingleDetailVideo/SingleDetailVideo";
import SimiliarProperties from "../../../components/SingleDetails/SimiliarProperties/SimiliarProperties";
import SingleDetailHead from "../../../components/SingleDetails/SingleDetailHead/SingleDetailHead";
import { useParams } from "react-router-dom";
import GetUnitDetailsHook from "../../../CustomHooks/UnitsHook/GetUnitDetailsHook";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import DetailSkeleton from "../../../components/SingleDetails/DetailSkeleton/DetailSkeleton";
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const SingleDetailsPage = () => {
  const { id } = useParams();
  const [t] = useTranslation();
  const [loading, unit_details] = GetUnitDetailsHook(id?.slice(1, id.length));
  return (
    <div className="single-details-page page">
      <CustomHelmet
        title={t("singleDetailsPage.singleDetailsInfo.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader
        breadcrumbTitle={"Single Property"}
        listsActive={"listsActive"}
      />
      <section className="single__Detail m-0 pt-0 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {loading === false ? (
                <>
                  <SlideShow unitDetails={unit_details} />
                  <SingleDetailHead unitDetails={unit_details} />
                </>
              ) : (
                <>
                  <HomeSectionSkeleton />
                </>
              )}
            </div>
            <div className="col-lg-4 mt-2 mb-5 pt-3">
              <SingleDetailsSidebar item={unit_details} />
            </div>
            <div className="col-lg-8">
              {loading === false ? (
                <>
                  <SingleDetailInfo unitDetails={unit_details} />
                  <SingleDetailDesc unitDetails={unit_details} />
                  {unit_details?.property?.length > 0 && (
                    <SingleDetailAmenities unitDetails={unit_details} />
                  )}
                  {unit_details?.video && (
                    <SingleDetailVideo
                      bigImg={bigImg}
                      unitDetails={unit_details}
                    />
                  )}
                </>
              ) : (
                <>
                  <DetailSkeleton />
                  <DetailSkeleton />
                  <DetailSkeleton />
                </>
              )}
            </div>
          </div>
          <div className="row my-5">
            <div className="col-lg-12">
              <SimiliarProperties
                id={unit_details?.category?.id}
                price={unit_details?.price}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SingleDetailsPage;
