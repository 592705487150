import React, { useState, useEffect, useRef } from 'react';  
import './CountUp.css'
const CountUp = ({ countUnit, title }) => {  
    const [displayCount, setDisplayCount] = useState(0);  
    const boxRef = useRef(null);  
    const hasBeenViewed = useRef(false); // To prevent multiple counts  

    useEffect(() => {  
        const observer = new IntersectionObserver((entries) => {  
            entries.forEach(entry => {  
                if (entry.isIntersecting && !hasBeenViewed.current) {  
                    hasBeenViewed.current = true; // Mark as viewed  
                    let targetCount = countUnit; // Fetch the target count  
                    let currentCount = displayCount; // Start from the current displayed count  

                    const countUpInterval = setInterval(() => {  
                        if (currentCount < targetCount) {  
                            currentCount += 1; // Increment the current count  
                            setDisplayCount(currentCount); // Update state  
                        } else {  
                            clearInterval(countUpInterval); // Stop counting when it reaches the target  
                        }  
                    },10); // Adjust speed of the count up  
                }  
            });  
        });  

        if (boxRef.current) {  
            observer.observe(boxRef.current); // Observe this box  
        }  

        return () => {  
            if (boxRef.current) {  
                observer.unobserve(boxRef.current); // Cleanup  
            }  
        };  
    }, [countUnit, displayCount]);  

    return (  
        <div className="analysis-box p-3" ref={boxRef}>  
            <h3 className="fw-bold text-white fs-55">  
                {`+${displayCount}`} {/* Display the animated count */}  
            </h3>  
            <p className="category-name position-relative fw-bold text-white text-capitalize">  
                {title}  
            </p>  
        </div>  
    );  
};  
export default CountUp