import React from "react";
import NotFound from "../../../components/Utility/NotFound/NotFound";
import CompoundCard from "../../Compounds/CompoundCard/CompoundCard";

const DeveloperUnits = ({ compounds }) => {
  return (
    <div className="mall-units-content">
      <div className="row units-wrapper">
        {compounds?.length > 0 ? (
          compounds?.slice(0, 4)?.map((item) => (
            <div className="col-md-6 col-lg-4 my-3" key={item?.id}>
              <CompoundCard item={item} />
            </div>
          ))
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};

export default DeveloperUnits;
