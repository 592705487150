import React from "react";
import "./BtnComponent.css";
import Spinner from "../Loading/Spinner";
const BtnComponent = ({ value, st, icon, type, loading, onClick }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`btn btn-component transition text-capitalize ${st}`}
    >
      {loading === true ? (
        <Spinner />
      ) : (
        <span>
          {value} {icon}
        </span>
      )}
    </button>
  );
};

export default BtnComponent;
