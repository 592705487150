import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ShareComponent from "../../Utility/Share/ShareComponent/ShareComponent";

const ContactButtons = ({ whatsapp, phone, email }) => {
  const [t] = useTranslation();
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };
  return (
    <div className="contacts text-center">
      {whatsapp && (
        <div className="whatsapp-btn-wrapper">
          <a
            href={`https://wa.me/+2${whatsapp}`}
            className="contact-btn transition whatsapp-btn bg-whatsapp"
            rel="noreferrer"
            target="_blank"
          >
            {t("singleDetailsPage.singleDetailsSidebar.whatsapp")}{" "}
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
      )}
      {phone && (
        <div
          className="phone-btn-wrapper"
          onClick={() => (window.location.href = `tel:${phone}`)}
        >
          <a
            href={`tel:+2${phone}`}
            className="contact-btn transition phone-btn bg-twitter"
            rel="noreferrer"
            target="_blank"
          >
            {t("singleDetailsPage.singleDetailsSidebar.phone")}{" "}
            <FontAwesomeIcon icon={faPhone} />
          </a>
        </div>
      )}
      {email && (
        <div className="email-btn-wrapper ">
          <a
            href={`mailto:${email}`}
            className="contact-btn transition email-btn bg-youtube"
            rel="noreferrer"
            target="_blank"
          >
            {t("singleDetailsPage.singleDetailsSidebar.email")}{" "}
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      )}
      <div className="share-btn-wrapper">
        <button
          className="contact-btn transition share-btn bg-twitter"
          onClick={() => handleChangeActive()}
        >
          {t("singleDetailsPage.singleDetailsSidebar.share")}{" "}
          <FontAwesomeIcon icon={faShare} />
        </button>
      </div>
      {active && (
        <ShareComponent
          handleChange={handleChangeActive}
          shareUrl={window.location.href}
        />
      )}
    </div>
  );
};

export default ContactButtons;
