import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
    allMalls:[],
    mallDetails:[],
    error:null,
    loading:true
}
export const GetAllMalls = createAsyncThunk("allMalls",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/malls?per_page=${15}&${queryString}`)
        return res
    }
    catch(error){
        // console.clear()
        return rejectWithValue(error)
    }
}
)

export const GetMallDetails = createAsyncThunk("mallDetails",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/malls/${id}`)
        return res
    }
    catch(error){
        // console.clear()
        return rejectWithValue(error)
    }
}
)

const MallsSlice = createSlice({
    name:"MallsSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllMalls.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllMalls.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allMalls =action?.payload?.data
        })
        .addCase(GetAllMalls.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.response
            state.allMalls =action?.payload?.response
        })
        // Mall details
        .addCase(GetMallDetails.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetMallDetails.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.mallDetails =action?.payload
        })
        .addCase(GetMallDetails.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.mallDetails =action?.payload
        })
    }
})
export const MallsReducer = MallsSlice.reducer
export const MallsActions = MallsSlice.actions