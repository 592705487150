import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserInfo } from "../../Redux/Redux-Toolkit/Slices/User/UserInfoSlice";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
const UserProfileHook = () => {
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([]);
  const res = useSelector((state) => state.rootReducer.userInfoReducer.userInfo);
  const dispatch = useDispatch();
  const [t] = useTranslation()
  let user;
  try{
    if (Cookies.get("user")) {
      user = JSON.parse(Cookies.get("user"));
    }
  }catch(e){}

  const getData =async () => {
    setLoading(true);
    await dispatch(GetUserInfo());
    setLoading(false);
  }
  useEffect(() => {
    const userInfo = Cookies.get("user")
    if(userInfo){
      setData(JSON.parse(userInfo))
    }else{
      getData()
    }
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.data) {
          setData(res.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [user,loading,data,t];
};

export default UserProfileHook;
