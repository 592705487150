import React from "react";
import SelectComponent from "../../Utility/SelectComponent/SelectComponent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import CompoundsFilterHook from "../../../CustomHooks/Compounds/CompoundsFilterHook";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
const CompoundsSidebar = () => {
  const [
    t,
    Reset,
    handleTypeLang,
    categoryList,
    onChangeCategoryId,
    categoryValueResult,
    formatNumber,
    minPrice,
    maxPrice,
    value,
    handleChange,
    open,
    handleCollaps,
    showState,
    lang,
    handleChangeActive,
    active,
    categories_loading,
    categories,
    onChangeFinishing,
    onChangeFurnished,
    onChangePaying,
    paying_list,
    onSearchPrice,
    onChangeSearch,
    search,
  ] = CompoundsFilterHook();
  return (
    <div className="compounds-sidebar txt-start">
      <h5 className="text-capitalize fw-bold">
        {t("listingPage.listingSidebar.title")}
      </h5>
      <div className="products__filter__body my-3">
        <div className="form-group">
          <input
            className="custom-input"
            placeholder="اسم الكمبوند"
            value={search}
            onChange={onChangeSearch}
          />
        </div>
        <div className="form-group">
          <SelectComponent
            options={paying_list}
            placeholder={t("listingPage.listingSidebar.paying")}
            onChange={onChangePaying}
          />
        </div>
        <div className="form-group">
          <SelectComponent
            options={categoryList[0]}
            placeholder={t("listingPage.listingSidebar.category")}
            onChange={onChangeCategoryId}
          />
        </div>
        {/* <div className="form-group">
          <SelectComponent
            options={categoryList[0]}
            placeholder={t("listingPage.listingSidebar.category")}
            onChange={onChangeCategoryId}
          />
        </div>
        <div className="form-group">
          <SelectComponent
            options={categoryList[0]}
            placeholder={t("listingPage.listingSidebar.category")}
            onChange={onChangeCategoryId}
          />
        </div>
        <div className="form-group">
          <SelectComponent
            options={categoryList[0]}
            placeholder={t("listingPage.listingSidebar.category")}
            onChange={onChangeCategoryId}
          />
        </div> */}
        <div className="form-group price-range-slider">
          <Box sx={{ width: "100%" }}>
            <Typography id="track-inverted-slider" gutterBottom>
              {t("listingPage.listingSidebar.priceRange")}
              <span className="parent-span">
                <span className="value-0 d-block">{`${t(
                  "listingPage.listingSidebar.EGP"
                )} ${formatNumber(minPrice)}`}</span>
                <span>--</span>
                <span className="value-1">{`${t(
                  "listingPage.listingSidebar.EGP"
                )} ${formatNumber(maxPrice)}`}</span>
              </span>
            </Typography>
            <Slider
              value={value}
              onChange={handleChange}
              min={0}
              max={20000000}
              step={100000}
            />
          </Box>
        </div>
        <div className="form-group">
          <BtnComponent value={"search"} onClick={onSearchPrice} />
        </div>
        <div className="form-group">
          <BtnComponent value={"Reset"} onClick={Reset} />
        </div>
      </div>
    </div>
  );
};

export default CompoundsSidebar;
