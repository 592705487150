import React  from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination,Autoplay,Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import TeamCard from "../TeamCard/TeamCard"
const TeamCarousel = ({items}) => {
const {t,i18n} = useTranslation()
const langDirection = i18n.dir()
  return (
    <Swiper
        spaceBetween={10}
        // navigation={true}
        style={{
          "--swiper-pagination-bullet-width": "10px",
          "--swiper-pagination-bullet-height": "10px",
          "--swiper-pagination-bottom": "-4px",
          "--swiper-pagination-color": "#f3b01c",
          "--swiper-navigation-size": "35px",
          "--swiper-navigation-width": "30px",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter:true,
          waitForTransition:true,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        breakpoints={{
          "576":{
            slidesPerView: 2,
            spaceBetween: 10,
          },
          "992":{
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
        modules={[Pagination,Autoplay,Navigation]}
        dir={!langDirection ? "rtl" : "ltr"}
        key={langDirection}
        className="mySwiper3 similar-properties-swiper"
      >
         <SwiperSlide >
        <TeamCard/>
        </SwiperSlide>
         <SwiperSlide >
        <TeamCard/>
        </SwiperSlide>
         <SwiperSlide >
        <TeamCard/>
        </SwiperSlide>
         <SwiperSlide >
        <TeamCard/>
        </SwiperSlide>
         <SwiperSlide >
        <TeamCard/>
        </SwiperSlide>
         <SwiperSlide >
        <TeamCard/>
        </SwiperSlide>
    </Swiper>
  )
}

export default TeamCarousel
