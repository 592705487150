import React from "react";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import UserAddUnitHook from "../../../CustomHooks/User/UserAddUnitHook";
import SelectComponent from "../../Utility/SelectComponent/SelectComponent";
import AddUnitOptionsHook from "../../../CustomHooks/User/AddUnitOptionsHook";
import MultiSelectImages from "../../../components/Utility/UploadAssets/MultiSelectImages/MultiSelectImages"
import UploadSingleVideo from "../../../components/Utility/UploadAssets/UploadSingleVideo/UploadSingleVideo"

const UserAddUnitSection = () => {
  const [images,imagesCount,video,videoCount,enTitle,arTitle,enDescription,arDescription,price,
    location,area,serviceCharges,rooms,bathroom,floor,category,type,district,paying,propertiesValue,
    onChangeImages,onChangeVideo,onChangeEnTitle,onChangeArTitle,onChangeEnDescription,
    onChangeArDescription,onChangePrice,onChangeLocation,onChangeArea,
    onChangeServiceCharges,onChangeRooms,onChangeBathrooms,onChangeFloor,onChangeCategory,onChangeType,
    onChangeDistrict,onChangePaying,onChangePropertyId,onChangeFinishing,onChangeFurnished,
    onChangeCategoryId,onChangeMainCategory,onChangeGateId,onChangeDistrictId
    ,mainCategory,finishing,furnished,gateId,districtId,loading,isPress,onSubmit
  ] = UserAddUnitHook()
  const [categoryList,typeList,DistrictsOptions,propertiesLoading,properties,gatesOptions,payingList,mainCategoryList,lang,t]= AddUnitOptionsHook()

  return (
    <div className="user-setting-info-section form-box form-box-1">
      <div className="form-title-wrap mt-3 mb-5">
        <h3 className="title">Add your unit</h3>
      </div>
      <div className="form-content">
        <div className="contact-form-action">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-12">
              <MultiSelectImages />
              </div>
              <div className="col-12">
              <UploadSingleVideo/>
              </div>
              <div className="col-12 col-sm-6">
                <div className="input-box">
                  <label className="label-text">En title</label>
                  <div className="form-group">
                    <input
                    value={enTitle}
                      onChange={onChangeEnTitle}
                      minLength={4}
                      maxLength={40}
                      name="en_title"
                      placeholder="En title"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="input-box">
                  <label className="label-text">Ar title</label>
                  <div className="form-group">
                    <input
                      onChange={onChangeArTitle}
                      value={arTitle}
                      minLength={4}
                      maxLength={40}
                      name="ar_title"
                      placeholder="Ar title"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="input-box">
                  <label className="label-text">En description</label>
                  <div className="form-group">
                    <textarea
                      rows="6"
                      onChange={onChangeEnDescription}
                      value={enDescription}
                      name="en_description"
                      placeholder="En description"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="input-box">
                  <label className="label-text">Ar description</label>
                  <div className="form-group">
                    <textarea
                      rows="6"
                      onChange={onChangeArDescription}
                      value={arDescription}
                      name="en_description"
                      placeholder="Ar description"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                  <label className="label-text">price</label>
                  <div className="form-group">
                    <input
                      onChange={onChangePrice}
                      value={price}
                      placeholder="price"
                      name="price"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                  <label className="label-text">service charges</label>
                  <div className="form-group">
                    <input
                      onChange={onChangeServiceCharges}
                      value={serviceCharges}
                      placeholder="service charges"
                      name="service_charges"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                  <label className="label-text">area</label>
                  <div className="form-group">
                    <input
                      onChange={onChangeArea}
                      value={area}
                      name="area"
                      placeholder="area"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                  <label className="label-text">rooms</label>
                  <div className="form-group">
                    <input
                      onChange={onChangeRooms}
                      value={rooms}
                      name="rooms"
                      placeholder="rooms"
                      className="custom-input"
                      type="number"
                      min={1}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                  <label className="label-text">bathroom</label>
                  <div className="form-group">
                    <input
                      onChange={onChangeBathrooms}
                      value={bathroom}
                      name="bathroom"
                      placeholder="bathroom"
                      className="custom-input"
                      type="number"
                      min={1}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                  <label className="label-text">floor</label>
                  <div className="form-group">
                    <input
                      onChange={onChangeFloor}
                      value={floor}
                      name="floor"
                      placeholder="floor"
                      className="custom-input"
                      type="number"
                      min={1}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                  <label className="label-text">location</label>
                  <div className="form-group">
                    <input
                      onChange={onChangeLocation}
                      value={location}
                      name="location"
                      placeholder="location"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="row selects-wrapper">
                  <div className="col-12 col-sm-6 col-md-3 my-2 select-list-item">
                    <SelectComponent options={mainCategoryList} placeholder={"main category"} onChange={onChangeMainCategory} />
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 my-2 select-list-item">
                    <SelectComponent options={categoryList[0]} placeholder={"category"} onChange={onChangeCategory} />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 my-2 select-list-item">
                    <SelectComponent options={gatesOptions[0]} placeholder={"gate"} onChange={onChangeGateId}/>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 my-2 select-list-item">
                    <SelectComponent options={typeList} placeholder={"type"}  onChange={onChangeType}/>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 my-2 select-list-item">
                    <SelectComponent options={DistrictsOptions[0]} placeholder={"district"} onChange={onChangeDistrict}/>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 my-2 select-list-item">
                    <SelectComponent options={payingList} placeholder={"paying"} onChange={onChangePaying}/>
                  </div>
                </div>
              <div className="checkbox-wrapper my-4">
                <div className="row"> 
                  <div className="col col-lg-5 overflow-hidden border-bottom py-2">
                    <h4 className="fs-20 fw-bold color-primary">{t("listingPage.listingSidebar.facilities")}</h4>
                  {propertiesLoading === false ? (
                    properties?.map((item) => {
                      return (
                        <div className="col-6 my-2"  key={item?.id}>
                        <label
                          className="custom-checkbox text-capitalize text-nowrap"
                        >
                          {lang === "en"
                            ? item?.data_trans
                              ? item?.data_trans[0]?.title_en
                              : null
                            : item?.data_trans
                            ? item?.data_trans[1]?.title_ar
                            : null}
                          <input
                            type="checkbox"
                            value={
                              item?.data_trans
                                ? item?.data_trans[0]?.title_en
                                : null
                            }
                            id={item?.id}
                            onChange={onChangePropertyId}
                          />
                          <span className="checkmark"></span>
                        </label>
                        </div>
                      );
                    })
                  ) : (
                   null
                  )}
                  </div>
                  <div className="col col-lg-4 overflow-hidden border-bottom py-2">
                    <h4 className="fs-20 fw-bold text-capitalize color-primary">{t("listingPage.listingSidebar.finishing")}</h4>
                    <div className="col col-xl-6 my-2">
                        <label
                          className="custom-checkbox text-capitalize text-nowrap"
                        >
                          طوب احمر
                          <input
                            type="checkbox"
                            value="Red Bricks"
                            onChange={onChangeFinishing}
                          />
                          <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="col col-xl-6 my-2">
                        <label
                          className="custom-checkbox text-capitalize text-nowrap"
                        >
                          نص تشطيب
                          <input
                            type="checkbox"
                            value="Finishing text"
                            onChange={onChangeFinishing}
                          />
                          <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="col col-xl-6 my-2">
                        <label
                          className="custom-checkbox text-capitalize text-nowrap"
                        >
                         سوبر لوكس
                          <input
                            type="checkbox"
                            value="Super deluxe"
                            onChange={onChangeFinishing}
                          />
                          <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="col col-xl-6 my-2">
                        <label
                          className="custom-checkbox text-capitalize text-nowrap"
                        >
                          تشطيب شخصى
                          <input
                            type="checkbox"
                            value="Lux"
                            onChange={onChangeFinishing}
                          />
                          <span className="checkmark"></span>
                        </label>
                    </div>
                  </div>
                  <div className="col col-lg-3 overflow-hidden py-2">
                    <h4 className="fs-20 fw-bold text-capitalize color-primary">{t("listingPage.listingSidebar.furnished")}</h4>
                    <div className="col col-xl-6 my-2">
                        <label
                          className="custom-checkbox text-capitalize text-nowrap"
                        >
                         مفروش
                          <input
                            type="checkbox"
                            value="Furnished"
                            onChange={onChangeFurnished}
                          />
                          <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="col col-xl-6 my-2">
                        <label
                          className="custom-checkbox text-capitalize text-nowrap"
                        >
                          غير مفروش
                          <input
                            type="checkbox"
                            value="Unfurnished"
                            onChange={onChangeFurnished}
                          />
                          <span className="checkmark"></span>
                        </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="btn-box" onClick={onSubmit}>
                  <BtnComponent
                    loading={
                      isPress === true && loading === true ? true : false
                    }
                    type={"submit"}
                    value={"save"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserAddUnitSection;
