import React, { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignUpHook from "../../../CustomHooks/Auth/SignUpHook";
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import { Link } from "react-router-dom";
import AuthTitle from "../../../components/Auth/AuthTitle/AuthTitle";
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const SignUpPage = () => {
  const [showPassWordIcon2, setShowPasswordIcon2] = useState(faEyeSlash);
  const [passwordState2, setPasswordState2] = useState("password");
 // third
  const [showPassWordIcon3, setShowPasswordIcon3] = useState(faEyeSlash);
  const [passwordState3, setPasswordState3] = useState("password");
  const handlePasswordState2 = () => {
    passwordState2 === "password"
      ? setPasswordState2("text")
      : setPasswordState2("password");
    showPassWordIcon2 === faEyeSlash
      ? setShowPasswordIcon2(faEye)
      : setShowPasswordIcon2(faEyeSlash);
  };
  const handlePasswordState3 = () => {
    passwordState3 === "password"
      ? setPasswordState3("text")
      : setPasswordState3("password");
    showPassWordIcon3 === faEyeSlash
      ? setShowPasswordIcon3(faEye)
      : setShowPasswordIcon3(faEyeSlash);
  };
  const lang = Cookies.get("i18next")
  const handleShowPassword =()=>{
    return lang==="en"?"show-password":"show-password-ar"
  } 
  const [name,email,phone,password,confirmPass,onChangeName,onChangeEmail,
    onChangePhone,onChangePassword,onChangeConfirmPass,onSubmit,
    loading,isPress,conditions_agree,onChangeConditionAgree
  ] = SignUpHook();
  const [t] = useTranslation()
  return (
    <div className="sing-up-page auth-page page">
       <CustomHelmet
      title={t("authPages.signUpPage.title")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <section className="section">
        <div className="auth-content">
          <div className="auth-page-title">
            <AuthTitle />
          </div>
          <div className="sign-up-card auth-card mx-auto txt-start">
            <div className="card-body">
              <h4 className="card-title my-3 fw-bold">{t("authPages.signUpPage.title")}</h4>
              <form onSubmit={onSubmit}>
                <div className="form-row">
                  <div className="form-group">
                    <label>{t("authPages.signUpPage.name")}</label>
                    <input
                      type="text"
                      value={name}
                      onChange={onChangeName}
                      className="custom-input"
                      placeholder={t("authPages.signUpPage.name")}
                      minLength={3}
                      maxLength={20}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>{t("authPages.signUpPage.email")}</label>
                  <input
                    type="email"
                    value={email}
                    onChange={onChangeEmail}
                    className="custom-input"
                    placeholder={t("authPages.signUpPage.email")}
                    required
                  />
                  <small className="form-text text-muted">
                  {t("authPages.signUpPage.never")}
                  </small>
                </div>
                <div className="form-row row">
                  <div className="form-group col col-12">
                    <label>{t("authPages.signUpPage.phone")}</label>
                    <input
                      className="custom-input"
                      placeholder={t("authPages.signUpPage.phone")}
                      value={phone}
                      onChange={onChangePhone}
                      type="tel"
                      pattern="01[0-2]\d{8,9}"
                      minLength={11}
                      maxLength={11}
                      required
                    />
                  </div>
                </div>
                <div className="form-row row">
                  <div className="form-group form-password col-12">
                    <label>{t("authPages.signUpPage.createPassword")}</label>
                    <span
                    className={handleShowPassword()}
                      onClick={handlePasswordState2}
                    >
                      <FontAwesomeIcon icon={showPassWordIcon2} />
                    </span>
                    <input
                      className="custom-input"
                      type={passwordState2}
                      value={password}
                      onChange={onChangePassword}
                      required
                    />
                  </div>
                  <div className="form-group form-password col-12">
                    <label>{t("authPages.signUpPage.confirmPassword")}</label>
                    <span
                     className={handleShowPassword()}
                      onClick={handlePasswordState3}
                    >
                      <FontAwesomeIcon icon={showPassWordIcon3} />
                    </span>
                    <input
                      className="custom-input"
                      type={passwordState3}
                      value={confirmPass}
                      onChange={onChangeConfirmPass}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="custom-checkbox">
                  {t("authPages.signUpPage.agree")} <a href="/">{t("authPages.signUpPage.terms")}</a>
                    <input
                      value={conditions_agree}
                      onChange={onChangeConditionAgree}
                      type="checkbox"
                      id="checkbox1"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="form-group">
                  <BtnComponent
                  loading={loading===true&&isPress===true?true:false}
                  value={t("authPages.signUpPage.title")}
                  type={"submit"}/>
                </div>
              </form>
              <div className="sign-up-info p-4">
            <p >
            <Link to="/publisher-sign-up">{t("authPages.signUpPage.publisherSignUp")}</Link>
            </p>
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUpPage;
