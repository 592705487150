import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
    allAgencies:[],
    agencyDetails:[],
    error:null,
    loading:true
}
export const GetAllAgencies = createAsyncThunk("allAgencies",async(page,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/agency?page=${page}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const GetAgencyDetails = createAsyncThunk("agencyDetails",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/agency/${id}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

const AgencySlice = createSlice({
    name:"AgencySlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllAgencies.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllAgencies.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allAgencies =action?.payload
        })
        .addCase(GetAllAgencies.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.allAgencies =action?.payload
        })
        // agency details
        .addCase(GetAgencyDetails.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAgencyDetails.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.agencyDetails =action?.payload
        })
        .addCase(GetAgencyDetails.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.agencyDetails =action?.payload
        })
    }
})
export const agencyReducer = AgencySlice.reducer
export const agencyActions = AgencySlice.actions