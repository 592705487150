import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllAgencies } from "../../Redux/Redux-Toolkit/Slices/Agency/AgencySlice";
const GetAllAgenciesHook = () => {
  const [loading,setLoading] = useState(false);
  const [agencies,serAgencies] = useState([]);
  const res = useSelector((state) => state.rootReducer.agencyReducer.allAgencies);
  const dispatch = useDispatch();

  const getData =async (page) => {
    setLoading(true);
    await dispatch(GetAllAgencies(page));
    setLoading(false);
  }
  useEffect(() => {
    getData();
  },[]);
  const onPressPaginate = (page)=>{
    getData(page)
  }
  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.data) {
            serAgencies(res?.data?.data?.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,agencies,onPressPaginate];
};

export default GetAllAgenciesHook;
