import { useState } from "react";
import FormatNumberHook from "../../FormatNumber/FormatNumberHook";
import GetAllPropertiesHook from "../../PropertiesHook/GetAllPropertiesHook";
import GetAllCategoriesHook from "../../Categories/GetAllCategoriesHook";
import FilterHook from "../../FiltrationHooks/FilterHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { changeFilterSidebarActive } from "../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
const ListingSidebarHook = (installment) => {
  // translation
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();

  // start for range slider
  const [value, setValue] = useState([0, 1000]);

  // filter hook
  const dispatch = useDispatch();
  const [
    minSpace,
    maxSpace,
    rooms,
    bathrooms,
    type,
    searchWord,
    property_id,
    category_id,
    minPrice,
    maxPrice,
    onChangeMinSpace,
    onChangeMaxSpace,
    onChangeBathrooms,
    onChangeType,
    onChangeSearchWord,
    onChangeRooms,
    onChangePropertyId,
    onChangeCategoryId,
    onChangeMaxPrice,
    onChangeMinPrice,
    onSearch,
    onSearchSidebar,
    Reset,
    sort,
    onChangeSort,
    onChangeCode,
    code,
    onChangeMainCategory,
    onChangeFinishing,
    onChangeFurnished,
    paying,
    onChangePaying,
    sale_status,
    onChangeSaleStatus,
  ] = FilterHook(installment);
  // end filter hook

  // hook to get the various data
  const [properties_loading, properties] = GetAllPropertiesHook();
  const [categories_loading, categories] = GetAllCategoriesHook();
  const [formatNumber] = FormatNumberHook();

  // End for range slider
  // advanced filter
  const [open, setOpen] = useState(false);
  const [showState, setShowState] = useState("+");
  // handle change price

  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };

  // handle collapse for advanced filter
  const handleCollaps = () => {
    setOpen(!open);
    showState === "+" ? setShowState("-") : setShowState("+");
  };
  // End advanced filter

  const handleTypeLang = () => {
    if (lang === "en") {
      return localStorage.getItem("type") === "sale" ? "sale" : "rent";
    } else {
      return localStorage.getItem("type") === "sale" ? "بيع" : "ايجار";
    }
  };
  const type_list = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "sale", label: t("listingPage.listingSidebar.sale") },
    { value: "rent", label: t("listingPage.listingSidebar.rent") },
  ];
  const paying_list = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "cash", label: t("listingPage.listingSidebar.cash") },
    {
      value: "Installment",
      label: t("listingPage.listingSidebar.installment"),
    },
    {
      value: "cash_and_insatllment",
      label: t("listingPage.listingSidebar.cashAndInstallment"),
    },
  ];
  const sale_status_list = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "developer", label: t("listingPage.listingSidebar.developer") },
    { value: "resale", label: t("listingPage.listingSidebar.resale") },
  ];
  const categoryList = [
    categories_loading === false
      ? categories
        ? categories?.map((item) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : null
              }`,
              label: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : null
              }`,
            };
          })
        : null
      : null,
  ];
  categoryList[0]?.unshift({
    id: "0",
    value: "0",
    label: t("listingPage.listingSidebar.all"),
  });

  const options_3 = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "1500", label: "1500" },
    { value: "1200", label: "1200" },
    { value: "900", label: "900" },
    { value: "600", label: "600" },
  ];
  const options_5 = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
  ];
  const options_6 = [
    { value: "", label: t("listingPage.listingSidebar.all") },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  // get all cities and all categories
  let categoryValueResult = "";
  const getCategoryValue = () => {
    let result1 = categoryList
      ? categoryList[0]?.filter((item) => {
          return item?.id === localStorage.getItem("category_id");
        })
      : null;
    categoryValueResult = result1 ? result1[0]?.value : "";
  };
  getCategoryValue();

  // react share component show
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };
  const handleChangeFilterSidebarActive = () => {
    dispatch(changeFilterSidebarActive());
  };

  // End react share
  return [
    t,
    onSearch,
    Reset,
    searchWord,
    onSearchSidebar,
    onChangeSearchWord,
    code,
    onChangeCode,
    type_list,
    onChangeType,
    handleTypeLang,
    categoryList,
    onChangeCategoryId,
    categoryValueResult,
    options_3,
    onChangeMinSpace,
    onChangeMaxSpace,
    minSpace,
    maxSpace,
    options_5,
    onChangeRooms,
    options_6,
    onChangeBathrooms,
    formatNumber,
    minPrice,
    maxPrice,
    value,
    handleChange,
    open,
    handleCollaps,
    showState,
    properties_loading,
    properties,
    lang,
    onChangePropertyId,
    handleChangeActive,
    active,
    categories_loading,
    categories,
    onChangeFinishing,
    onChangeFurnished,
    handleChangeFilterSidebarActive,
    onChangePaying,
    onChangeSaleStatus,
    paying_list,
    sale_status_list,
  ];
};

export default ListingSidebarHook;
